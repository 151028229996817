.sn01-wrapper {
    height: 100%;
    display: flex;
    flex-direction: column;
}
.sn01-header {
    font-weight: 800;
    font-size: 24px;
    color: #222222;
    margin: 8px 0 20px 0;
}

.sn01-body {
    background: #ffffff;
    width: 100%;
    box-shadow: 8px 8px 8px rgba(153, 153, 153, 0.02);
    border-radius: 3px;
    padding: 22px;
    flex: 1;
}

.sn01-table{
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 24px;
}

.sn01-table .table-left{
    width: 50%;
    margin-right: 12px;
}

.sn01-table .table-right{
    width: 50%;
    margin-left: 12px;
}

.sn01-table .ant-table-container .ant-table-content .ant-table-thead .ant-table-cell{
    color: #222222 !important;
    text-align: center;
    font-size: 16px !important;
    font-weight:600 !important;
    line-height: 24px;
    padding: 14px;
}

.sn01-table .ant-table-container .ant-table-content .ant-table-tbody .ant-table-cell{
    color: #222222 !important;
}

.sn01-body .ant-form-item{
    margin: 0;
}

.sn01-table .ant-table-thead .ant-table-cell{
    border: 1px solid var(--border-line, #DAE2EE) !important;
    border-right: none !important;
}

.sn01-table .ant-table-thead .ant-table-cell:last-child{
    border-right: 1px solid var(--border-line, #DAE2EE) !important;
}

.sn01-table .ant-table-tbody .ant-table-cell{
    border: 1px solid var(--border-line, #DAE2EE) !important;
    border-right: none !important;
    border-top: none !important;
}

.sn01-table .ant-table-tbody .ant-table-cell:last-child{
    border-right: 1px solid var(--border-line, #DAE2EE) !important;
}

.sn01-table .ant-table-tbody .ant-table-cell{
    padding: 10.5px;
}

.sn01-table tr:nth-child(odd){
    background-color: #FFF;
}

.sn01-table tr:nth-child(even){
    background-color: #FAFAFA;
}
