.ic5-modal .ant-modal-body {
  padding: 32px;
}
.ic5-modal-container {
  margin-top: 32px;
  display: flex;
  flex-direction: column;
  gap: 32px;
}

.text-blue {
  color: #605bff;

  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px; /* 157.143% */
}

.text-content {
  color: var(--text-main, #222);

  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px; /* 157.143% */
}
