.pm011-text-bold {
  color: #222222;
  font-weight: 700;
  font-size: 14px;
}

.pm011-collapse-header {
  border-radius: unset;
  background: #e9edf2 !important;
}

.pm011-company {
  background: #f0f0f0 !important;
}
.pm011-construction {
  background: #e9edf2 !important;
}

.pm011-panel .ant-collapse-header {
  padding: 0 !important;
}

.pm011-panel-header-title {
  padding: 18px 42px 18px 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
}

.pm011-panel-header-title-left,
.pm011-panel-header-title-right {
  color: #222222;
  font-weight: 700;
  font-size: 14px;
}

.pm011-panel-header-title-left {
  display: flex;
  align-items: flex-end;
}

.pm011-panel-header-title-left svg {
  transform: rotate(0deg) !important;
}

.pm011-panel-button {
  display: flex;
  align-items: center;
  padding: 4px 8px;
  border-radius: 6px;
  margin-left: 16px;
}

.pm011-collapse-item {
  border-radius: unset !important;
}

.folder-collapse .ant-collapse-item-active .pm011-panel-header-title-left svg {
  transform: rotate(0deg) !important;
}

.pm011-panel-header-item-text {
  color: #222222;
  font-weight: 400;
  font-size: 14px;
  display: block;
  margin-left: 12px;
}
.pm011-panel-button span {
  font-weight: 700;
  font-size: 12px;
}

.pm011-panel-button-icon,
.pm011-panel-header-icon {
  display: flex;
  align-items: center;
}
.pm011-collapse-item,
.pm011-collapse-header {
  border: none !important;
}

.pm011-panel .ant-collapse-expand-icon {
  margin: 14px;
}

.pm011-body .ant-collapse-content {
  border-top: 1px solid #e6eaf0;
}

.pm011-table-body {
  max-height: 516px;
  overflow: auto;
}
.pm011-body .ant-collapse-content-box {
  padding: 0;
}

.pm011-body .ant-collapse > .ant-collapse-item {
  border-bottom: 1px solid #e6eaf0;
}
.pm011-member-item {
  display: flex;
  align-items: center;
  /* padding: 16px 0; */
  padding: 16px;
  border-bottom: 1px solid #e6eaf0;
  padding-left: 24px;
}
.space-between-col {
  justify-content: space-between;
}

.pm011-member-item-checkbox {
  width: 5%;
}

.pm011-member-item-name {
  color: #605bff;
}

.pm014-table-header {
  padding-left: 24px;
  padding-right: 24px;
}

.pm011-body-footer {
  margin-top: 24px;
  font-weight: 400;
  font-size: 14px;
}

.filter-green {
  filter: invert(61%) sepia(56%) saturate(485%) hue-rotate(109deg)
    brightness(99%) contrast(92%);
}
/* filter: invert(62%) sepia(54%) saturate(493%) hue-rotate(109deg)
    brightness(97%) contrast(93%);
} */

.filter-orange {
  filter: invert(64%) sepia(81%) saturate(606%) hue-rotate(319deg)
    brightness(107%) contrast(101%);
}

.total-case-font {
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  /* identical to box height, or 157% */

  /* Text/Main */

  color: #222222;
}

.pm011-member-item:last-child {
  margin-bottom: 0px !important;
  border-bottom: none !important;
}

.pm014-table .ant-collapse:last-child {
  margin-bottom: 0px !important;
  border-bottom: none !important;
}
