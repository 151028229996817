.cm047 {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  margin-top: 32px;
}

.cm047-btn-wrapper {
  background-color: #f3f5f6;
  margin: 0 -24px -72px -24px;
  padding-top: 24px;
}

.cm047-button {
  background-color: #fff;
  padding: 24px;
  display: flex;
  justify-content: flex-end;
}

.cm047-button button:disabled {
  opacity: 0.5;
}

.cm047-btn-submit {
  width: 98px;
  height: 40px;
  border-radius: 6px !important;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.cm047-btn-reject {
  border-radius: 6px !important;
  background-color: #dae2ee !important;
  letter-spacing: -1px;
  width: 76px;
  height: 40px;
  margin-left: 24px;
  font-weight: 700 !important;
  font-size: 14px;
}

.cm047-table {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}

.cm047-table tr th,
.cm047-table tr td {
  border-right: 1px solid #dae2ee !important;
}

.cm047-table tr:last-child td {
  border-bottom: none !important;
}

.cm047-table tr td {
  border-bottom: 1px solid #dae2ee !important;
  background-color: #fff !important;
  color: #222222 !important;
}

.cm047-table .ant-table-container {
  border: 1px solid #dae2ee !important;
  border-radius: 3px;
}

.cm047-table .ant-table-selection-column {
  border-right: none !important;
}

.cm048 {
  min-height: 49.5vh;
  height: auto;
}

.cm048 .cm047-btn-wrapper {
  margin-top: 24px;
}

.cm048-item-header {
  background: #f0f0f0;
  padding: 15px 50px;
  font-weight: 600;
  font-size: 16px;
  color: #222222;
  border-bottom: 1px solid #dae2ee;
}

.cm047-table .cm048-table-item .ant-table-container {
  border: none !important;
  border-radius: 0px !important;
}

.cm048-table-item {
  border-radius: 3px;
  border: 1px solid #dae2ee !important;
  margin-bottom: 4px;
}
.cm048-table-item tr th:last-child,
.cm048-table-item tr td:last-child {
  border-right: none !important;
}

.cm048-table-item tr th {
  background-color: #fafafa !important;
}

.cm048-table-item
  .ant-table-tbody
  > tr.ant-table-row-selected:nth-child(odd)
  > td {
  background-color: #fff !important;
}

.cm053-icon {
  margin-left: 12px;
  margin-top: -4px;
  cursor: pointer;
}

.cm053-icon:hover {
  opacity: 0.8;
}

.cm047-table .ant-table-body {
  overflow-y: auto !important;
}

.cm047-table .ant-table-column-sorters {
  justify-content: center;
}

.cm047-table .ant-table-column-title {
  flex: none;
}

button.cm047-btn-submit:disabled,
button.cm047-btn-reject:disabled {
  opacity: 0.5;
}
