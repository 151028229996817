.ci001-title {
  color: #222222;
  font-size: 24px;
  font-weight: 800;
  margin-top: 8px;
}

.ci001-body .ant-tabs-tab {
  border-radius: 3px 3px 0px 0px !important;
  background-color: #fff !important;
}

.ci001-body .ant-tabs-tab.ant-tabs-tab-active {
  background-color: #605bff !important;
}

.ci001-body .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #fff !important;
}

.ci001-body
  .ant-tabs-card.ant-tabs-top
  > .ant-tabs-nav
  .ant-tabs-tab
  + .ant-tabs-tab {
  margin: unset;
}

.ci001-body .ant-tabs-top > .ant-tabs-nav::before {
  border-bottom: none;
}

.ci001-basic {
  background-color: #fff;
  box-shadow: 8px 8px 8px rgba(153, 153, 153, 0.02);
  border-radius: 0px 0px 3px 3px;
  padding: 0 24px;
}

.ci001-basic-header {
  height: 88px;
  border-bottom: 1px solid #e9edf2;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: -16px;
}

.ci001-ifo {
  display: flex;
}

.ci001-ifo-left,
.ci001-ifo-right {
  width: 50%;
}

.ci001-item {
  display: flex;
  padding: 4px 0;
}

.ci001-item-label,
.ci001-item-text {
  display: block;
  font-size: 14px;
  font-weight: 400;
  line-height: 40px;
  word-break: break-word;
}

.ci001-item-label {
  width: 25%;
  text-align: right;
  margin-right: 24px;
  color: #666;
}

.ci001-item-text {
  color: #222;
  flex: 1;
}

.ci001-item-text .ant-input-number-input {
  font-weight: 400;
  font-size: 14px;
}

.ci001-contact-header {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 14px 0;
}

.ci001-contact {
  background: #f4f7fa;
  border-radius: 6px;
  padding: 14px 0 28px 0;
}

.ci001-contact-header span {
  display: block;
  width: 12.5%;
  text-align: right;
  margin-right: 24px;
  font-size: 14px;
  font-weight: 700;
  color: #666;
}

.ci001-contact-header-divider {
  width: 80%;
  height: 1px;
  background-color: #e9edf2;
}

.ci001-item-left,
.ci001-item-right {
  width: 50%;
  display: flex;
}

.ci001-other,
.ci001-ifo {
  padding: 28px 0;
}

.ci001-body .ant-form-item {
  margin: 0;
}

.ci001-date-picker,
.ci001-input {
  width: 65%;
  height: 40px;
}

.ci001-item-edit {
  padding: 8px 0;
}

.icon-calender {
  width: 15px;
  opacity: 0.8;
}

.calender-separator {
  font-weight: 700;
  opacity: 0.8;
}

.ci001-item-text .dropdown-filter {
  width: 65% !important;
}

.ci001-select {
  width: 65% !important;
}

.ci001-select .ant-select-selector {
  height: 40px !important;
}

.ci001-select .ant-select-selection-item {
  line-height: 40px !important;
}

.ci001-wrapper-select {
  position: relative;
}

.item-other-input {
  position: absolute;
  left: calc(65% + 24px);
  width: 100%;
  top: 0;
  display: flex;
}

.ci001-item-form-other {
  /* position: absolute;
  left: calc(60% + 24px); */
  width: 100%;
  /* top: 0; */
}

.item-span-text-other {
  display: flex;
  width: 20px;
  align-items: center;
}

.ci001-input-45 {
  width: 45%;
}

.ci001-input-242 {
  width: 242px;
}

.ci001-item-checkbox {
  display: flex;
  align-items: center;
}
.ci001-item-checkbox .ci001-item-label {
  width: 12.5%;
}

.ci001-checkbox-group {
  width: 90%;
}

.ci001-checkbox-col {
  width: 20%;
  display: flex;
  align-items: center;
}

.ci001-form-submit {
  margin-top: 24px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  background-color: #fff;
  height: 88px;
  margin-bottom: -80px;
  padding-right: 22px;
}

.ci001-warning {
  display: flex;
  padding-top: 25px;
}

.ci001-warning-left {
  width: 15%;
  margin-left: 24px;
}

.ci001-warning-right span {
  font-weight: 400;
  font-size: 14px;
  color: #666;
  margin-left: 10px;
}

.ci001-payment-other {
  display: flex;
  margin-top: 32px;
  padding-bottom: 200px;
}

.ci001-payment-other-left,
.ci001-payment-other-right {
  width: 50%;
  display: flex;
  align-items: center;
}

.ci001-payment-other-icon {
  width: 25%;
  margin-right: 24px;
  text-align: right;
}

.ci001-payment-other-content {
  flex: 1;
}

.ci001-payment-other-content span {
  display: block;
  font-size: 14px;
  font-weight: 400;
}

.ci001-payment-other-content-top {
  color: #666666;
  margin-bottom: 8px;
}

.ci001-payment-other-content-bottom {
  color: #222222;
}

.ci001-black {
  color: #222222;
}

.ci001-gray {
  color: #666666;
}

.ci001-item-text-custom {
  position: relative;
}

.ci001-text-custom {
  display: block;
  position: absolute;
  left: calc(65% + 24px);
  top: 0;
}

.ci001-item-right .ci001-text-custom {
  left: calc(57% + 24px);
}

.ci001-payment-other-content-bottom-custom {
  position: relative;
}

.ci001-payment-other-content-bottom-custom-input,
.ci001-payment-other-content-bottom-custom-select {
  position: absolute;
  left: calc(57% + 12px);
  bottom: 0;
}

.ci001-select-custom {
  min-width: 116px;
}

.ci001-ifo-right .ci001-item-label,
.ci001-item-right .ci001-item-label {
  width: 15%;
}

.ci001-payment-other-icon-money {
  width: 15%;
  margin-right: 24px;
  text-align: right;
}

.ci001-ifo-right .ci001-input,
.ci001-item-right .ci001-input,
.ci001-ifo-right .ci001-date-picker {
  width: 57%;
}

.ci001-select-custom {
  width: 57% !important;
}

.ci001-basic .ant-form-item-explain-error {
  font-size: 12px;
  text-align: left;
  padding-left: 4px;
}

.date-error-text .ant-form-item-explain-error {
  padding-left: 32px;
}

.btn-reject {
  width: 76px;
  height: 40px;
  border-color: #ff0909 !important;
  color: #ff0909 !important;
}

.btn-approve {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  background-color: #fff;
  margin-top: 24px;
  margin-bottom: -16px;
  padding: 24px 0;
  padding-right: 26px;
}

.btn-withdraw {
  color: #605bff;
  border-color: #605bff;
  border-radius: 6px;
  background-color: #e9edf2 !important;
}

.modal-approve {
  width: 36% !important;
  margin-left: 300px;
}

.modal-approve-header {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
}

.modal-approve-header h3 {
  margin: 0;
  font-weight: 600;
  font-size: 16px;
  color: #222222;
  margin-left: 14px;
}

.modal-approve-btn {
  margin-top: 32px;
  display: flex;
  justify-content: flex-end;
}

.modal-approve-btn .button-purple {
  margin-right: 16px;
}

.modal-approve-body-title {
  font-weight: 400;
  font-size: 13px;
  color: #222222;
  margin-left: 38px;
}

.modal-reject-input {
  display: flex;
  align-items: center;
  margin-top: 24px;
}

.modal-reject-input span {
  width: 82px;
}

.modal-reject-input-text {
  min-height: 40px;
}

.ci001-body button:disabled {
  opacity: 0.5;
}

@media (max-width: 1300px) {
  .ci001-date-picker,
  .ci001-input {
    width: 90%;
  }

  #cr003Basic_constructionCode,
  #cr003Basic_conditionPayment,
  .cr003-date-picker,
  .ci001-item-right .ant-input-number-group-wrapper,
  .ci001-ifo-right .ant-input-number-group-wrapper {
    width: 90% !important;
  }

  .ci001-ifo-right .ci001-input,
  .ci001-item-right .ci001-input,
  .ci001-ifo-right .ci001-date-picker {
    width: 90%;
  }

  .ci001-checkbox-col {
    width: 25%;
  }

  .ci001-select {
    width: 90% !important;
  }
}
.ci-upload-text{
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  text-align: center;
} 
.text-gray{
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  text-align: left;
  color: #22222266;
}
.label-upload {
  width: 13%;
  text-align: right;
  padding-right: 8px;
  height: 40px;
  line-height: 40px;
}
.content-upload {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}