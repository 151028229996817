.cm0034__container {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 900px;
    margin-top: 22px;
    background: #ffffff;
    box-shadow: 4px 8px 24px 2px rgba(133, 133, 133, 0.08);
    border-radius: 3px;
    padding-top: 30px;
    padding-left: 24px;
    padding-right: 24px;
    padding-bottom: 20px;
  }

  .cm0034-table {
    min-height: 54vh;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    margin-top: 25px;
  }
  
  .cm0034-table tr th,
  .cm0034-table tr td {
    border-right: 1px solid #dae2ee !important;
  }
  
  .cm0034-table tr:last-child td {
    border-bottom: none !important;
  }
  
  .cm0034-table tr td {
    border-bottom: 1px solid #dae2ee !important;
    color: #222222 !important;
    /* background-color: #fafafa !important; */
  }
  
  .cm0034-table .ant-table-container {
    border: 1px solid #dae2ee !important;
    border-radius: 3px;
  }
  
  