.ord--001__header {
  display: flex;
  flex-direction: row;
  column-gap: 122px;

  justify-content: space-between;
  align-items: center;
}

.ord--001__component-title {
  font-size: 24px;
  font-weight: 800;
  line-height: 32px;
  margin-top: 8px;

  color: #222;
  width: 180px;
}

.ord--001__component-action {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  width: 100%;
}

.ord--001__component-filter {
  width: 641px;
  height: 40px;

  margin-top: -15px;
}

.ord--001__component-filter .ant-input {
  height: 30px;
  background: #e7eaee;
}

.ord--001__component-filter .ant-input::placeholder {
  color: rgba(34, 34, 34, 0.4);
  font-size: 14px;
  font-weight: 400;
}

.ord--001__component-filter .ant-input-affix-wrapper {
  border-radius: 3px;
  background: #e7eaee;
  border: 1px solid var(--border-line, #dae2ee);
}

.ord--001__component-button {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  margin-top: -15px;
  column-gap: 16px;
}

.ord--001__component-button--active {
  display: flex;
  height: 40px;
  padding: 8px 24px;
  align-items: center;
  gap: 8px;

  border-radius: 3px;
  border: 1px solid var(--primary-color-logo-brand, #605bff);
  background: var(--border-line, #dae2ee);
  box-shadow: 4px 8px 24px 2px rgba(133, 133, 133, 0.08);

  font-size: 14px;
  font-weight: 700;
  line-height: 22px;
  color: #605bff;
  cursor: pointer;
}

.ord--001__component-button--delete {
  display: flex;
  flex-direction: row;

  height: 40px;
  padding: 8px 24px;
  align-items: center;
  gap: 8px;

  border-radius: 3px;
  border: 1px solid var(--border-warning, rgba(255, 9, 9, 0.5));
  background: var(--border-line, #dae2ee);

  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  color: #ff0909;

  cursor: pointer;
}

.ord--001__component-button--delete:hover {
  color: #ff0909;
  border: 1px solid var(--border-warning, rgba(255, 9, 9, 0.5));
  background: var(--border-line, #dae2ee);
}

.ord--001__component-button--delete[disabled] {
  color: #ff0909;
  border: 1px solid var(--border-warning, rgba(255, 9, 9, 0.5));
  background: var(--border-line, #dae2ee);
}

.ord--001__component-button--delete[disabled]:hover {
  color: #ff0909;
  border: 1px solid var(--border-warning, rgba(255, 9, 9, 0.5));
  background: var(--border-line, #dae2ee);
}
