.am018-form .ant-form-item-label{
    min-width: 122px;
}

.am018-form .ant-select .ant-select-selector{
    height: 40px;
    display: flex;
    align-items: center;
}

.am018-form .ant-select .ant-select-selector .ant-select-selection-item{
    height: 40px;
    display: flex;
    align-items: center;
}

.am018-form .ant-select .ant-select-selector .ant-select-selection-item div{
    display: flex;
    align-items: center;
}