/* Common */
.button-style {
  cursor: pointer;
}
.disable-style {
  cursor: not-allowed;
}
.mr-8 {
  margin-right: 8px;
}

.invisible {
  visibility: hidden;
}

.mb-30 {
  margin-bottom: 30px;
}
.min-h-22 {
  min-height: 22px;
}
/* Menu Wrapper */
#components-layout-demo-responsive .logo {
  height: 32px;
  margin: 16px;
  background: rgba(255, 255, 255, 0.2);
}

.ant-menu .ant-menu-sub {
  background-color: #0d0a35 !important;
}

.ant-menu {
  background-color: #0d0a35 !important;
}

.site-layout-sub-header-background {
  background: #fff;
}

.site-layout-background {
  background: #fafafb;
}
.site-content {
  height: 100vh;
  overflow: auto;
  background: #f3f5f6;
  padding-top: 32px;
  padding-right: 25px;
  padding-left: 25px;
  padding-bottom: 16px;
}
.site-content-p01 {
  height: 100vh;
  overflow: auto;
  background: #f3f5f6;
  padding-top: 32px;
  padding-right: 25px;
  padding-left: 25px;
}

/* Width */
.w-100-percent {
  width: 100% !important;
}

/* Height */
.h-100-percent {
  height: 100% !important;
}

/* Margin */
.mb-75-px {
  margin-bottom: 75px;
}
.mb-48-px {
  margin-bottom: 48px;
}
.mb-16-px {
  margin-bottom: 16px;
}

.mb-29-px {
  margin-bottom: 29px;
}

.mt-18-px {
  margin-top: 18px;
}

/* Center item */
.center-item {
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Checkbox */

.ant-checkbox-inner {
  border-radius: 3px !important;
}

.ant-checkbox-inner:hover {
  border: none !important;
}

/* Button */
.cursor-pointer {
  cursor: pointer;
}

.ant-btn {
  border-radius: 3px !important;
  font-style: normal !important;
  font-weight: 700 !important;
  font-size: 14px !important;
  line-height: 22px !important;
}

/* Button purple - background purple */
.button-purple .ant-btn:focus,
.button-purple .ant-btn:hover {
  border-color: #605bff !important;
}

.button-purple {
  border-radius: 3px !important;
  background-color: #605bff !important;
  border-color: #605bff !important;
  color: #ffffff !important;
}

.button-brown-3 .ant-btn:focus,
.button-brown-3 .ant-btn:hover {
  border-color: 1px solid #605bff !important;
}

.button-brown-3 {
  border-radius: 3px !important;
  background-color: #dae2ee !important;
  border-color: 1px solid #605bff !important;
  color: #ffffff !important;
}

.button-export-style {
  font-size: 14px !important;
  font-weight: 700 !important;
  line-height: 22px !important;
  letter-spacing: 0em !important;
  text-align: right !important;
}

.button-brown-2 .ant-btn:focus,
.button-brown-2 .ant-btn:hover {
  border-color: #666666 !important;
}

.button-brown-2 {
  border-radius: 3px !important;
  background-color: #666666 !important;
  border-color: #666666 !important;
  color: #ffffff !important;
}

.button-brown-ccus {
  border-radius: 3px !important;
  background-color: #D7D7DC !important;
  border-color: #D7D7DC !important;
  color: #ffffff !important;
}

.button-edit-project {
  border-radius: 3px !important;
  background-color: #605bff !important;
  border-color: #605bff !important;
  color: #ffffff !important;
  margin-left: 20px !important;
}

/* Button brown */
.button-brown .ant-btn:focus,
.button-brown .ant-btn:hover {
  border-color: #b07c60 !important;
}

.button-brown {
  border-radius: 3px !important;
  background-color: #b07c60 !important;
  border-color: #b07c60 !important;
  color: #ffffff !important;
}

/* Button purple - background white */
.button-purple-white .ant-btn:focus,
.button-purple-white .ant-btn:hover {
  border-color: #605bff !important;
}

.button-purple-white {
  border-radius: 3px !important;
  background-color: #ffffff !important;
  border-color: #ffffff !important;
  border: 1px solid #605bff !important;
  color: #605bff !important;
}

.button-purple-gray .ant-btn:focus,
.button-purple-gray .ant-btn:hover {
  border-color: #605bff !important;
}

.button-purple-gray {
  border-radius: 3px !important;
  background-color: #dae2ee !important;
  border-color: #ffffff !important;
  border: 1px solid #605bff !important;
  color: #605bff !important;
}

/* Button red */
.button-warning .ant-btn:focus,
.button-warning .ant-btn:hover {
  border-color: #eb4d4d !important;
}

.button-warning {
  border-radius: 3px !important;
  background-color: #ffffff !important;
  border-color: #ffffff !important;
  border: 1px solid #eb4d4d !important;
  color: #eb4d4d !important;
}

.button-warning-gray .ant-btn:focus,
.button-warning-gray .ant-btn:hover {
  border-color: #eb4d4d !important;
}

.button-warning-gray {
  border-radius: 3px !important;
  background-color: #dae2ee !important;
  border-color: #ffffff !important;
  border: 1px solid #eb4d4d !important;
  color: #eb4d4d !important;
}

/* Button 343434 */
.button-343434 .ant-btn:focus,
.button-343434 .ant-btn:hover {
  border-color: #343434 !important;
}

.button-343434 {
  border-radius: 3px !important;
  background-color: #343434 !important;
  border-color: #343434 !important;
  border: 1px solid #343434 !important;
  color: #ffffff !important;
}

/* Button black */
.button-black .ant-btn:focus,
.button-black .ant-btn:hover {
  border-color: #e6eaf0 !important;
}

.button-black {
  border-radius: 3px !important;
  background-color: #fafafb !important;
  border-color: #ffffff !important;
  border: 1px solid #e6eaf0 !important;
  color: #343434 !important;
}

.button-round-purple .ant-btn:focus,
.button-round-purple .ant-btn:hover {
  border-color: #605bff !important;
}

.button-round-purple {
  background-color: #605bff !important;
  border-color: #605bff !important;
  color: #ffffff !important;
  height: 60px !important;
  width: 60px !important;
  padding: 0px !important;
}

.button-round-gray .ant-btn:focus,
.button-round-gray .ant-btn:hover {
  border-color: #e6eaf0 !important;
}

.button-round-gray {
  background-color: #e6eaf0 !important;
  border-color: #e6eaf0 !important;
  height: 40px !important;
  width: 40px !important;
  padding: 0px !important;
}

.button-round-purple-small .ant-btn:focus,
.button-round-purple-small .ant-btn:hover {
  border-color: #605bff !important;
}

.button-round-purple-small {
  background-color: #605bff !important;
  border-color: #605bff !important;
  color: #ffffff !important;
  height: 40px !important;
  width: 40px !important;
  padding: 0px !important;
}

.button-round-purple-white .ant-btn:focus,
.button-round-purple-white .ant-btn:hover {
  border-color: #605bff !important;
}

.button-round-purple-white {
  background-color: #ffffff !important;
  border-color: #605bff !important;
  color: #ffffff !important;
  height: 60px !important;
  width: 60px !important;
  padding: 0px !important;
}

.button-round-warning .ant-btn:focus,
.button-round-warning .ant-btn:hover {
  border-color: #eb4d4d !important;
}

.button-round-warning {
  background-color: #ffffff !important;
  border-color: #eb4d4d !important;
  color: #eb4d4d !important;
  height: 60px !important;
  width: 60px !important;
  padding: 0px !important;
}

.button-round-black .ant-btn:focus,
.button-round-black .ant-btn:hover {
  border-color: #e6eaf0 !important;
}

.button-round-black {
  background-color: #ffffff !important;
  border-color: #e6eaf0 !important;
  height: 60px !important;
  width: 60px !important;
  padding: 0px !important;
}

.button-gray-small .ant-btn:focus,
.button-gray-small .ant-btn:hover {
  border-color: #f0f0f0 !important;
}

.button-gray-small {
  border-radius: 6px !important;
  background-color: #f0f0f0 !important;
  border-color: #f0f0f0 !important;
  color: #ffffff !important;
  height: 18.33px !important;
  width: 18.33px !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}

/* Breadcrumb */
.ant-breadcrumb {
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 12px !important;
  line-height: 20px !important;
  /* identical to box height, or 183% */

  /* Text / Subtext */

  color: #666666 !important;
}

.ant-breadcrumb li:last-child {
  color: #605bff !important;
}

.ant-breadcrumb li:last-child a {
  color: #605bff !important;
}

/* Tab */

.ant-tabs-tab-btn {
  font-weight: 400 !important;
  font-size: 14px !important;
  color: #666666 !important;
}

.ant-tabs-tab-active .ant-tabs-tab-btn {
  font-weight: 700 !important;
  font-size: 14px !important;
  color: #605bff !important;
}

/* Filter */
.filter {
  background: #ffffff;
  border: 1px solid #e6eaf0;
  border-radius: 3px;
  width: 40px;
  height: 40px;
}

.filter-active {
  background: #ffffff;
  border: 1px solid #605bff;
  border-radius: 3px;
  width: 40px;
  height: 40px;
}

/* Status Filter */

.status-filter-container {
  width: 180px !important;
  background: #ffffff;
  border: 1px solid #e6eaf0;
  border-radius: 3px;
  display: flex;
  align-items: center;
  height: 40px;
  padding-left: 12px;
  padding-right: 0px;
}

.status-filter-label {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 22px;
  color: #999999;
}

.status-filter-label .selected {
  margin-left: 16px;
  width: 48px;
  margin-right: 19px;
  color: #343434;
}

.status-filter-option {
  width: 180px !important;
}

.ant-select-open .status-filter {
  border: 1px solid #605bff !important;
}

.ant-dropdown-arrow {
  right: 16px !important;
  left: auto !important;
}
.ant-dropdown-menu-item-divider {
  margin-left: 16px !important;
  margin-right: 16px !important;
  margin-top: 2px !important;
  margin-bottom: 2px !important;
}

.ant-dropdown-menu-title-content {
  margin-left: 11px !important;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 22px;
  /* or 183% */

  /* Text/Maintext */

  color: #343434;
}

.ant-dropdown-open .status-filter-up {
  display: unset !important;
}

.ant-dropdown-open .status-filter-down {
  display: none !important;
}

.status-filter-up {
  display: none;
}

.ant-dropdown-menu {
  border-radius: 3px !important;
}

.ant-form-item-label
  > label.ant-form-item-required:not(
    .ant-form-item-required-mark-optional
  ):before {
  display: none !important;
}

/* Menu Sider */
.menu-wrapper .ant-layout-sider-children {
  max-width: 300px !important;
  width: 300px !important;
}

.menu-wrapper .ant-layout-sider {
  flex: none !important;
  max-width: 300px !important;
  width: 300px !important;
}
.menu-wrapper .ant-layout-sider-children {
  background-color: #0d0a35 !important;
}
.menu-wrapper .ant-menu-item,
.menu-wrapper .ant-menu-submenu-title {
  margin-left: 16px !important;
  width: 268px !important;
}

.menu-wrapper .ant-menu-item-selected {
  background: #605bff !important;
}

.menu-wrapper .ant-menu-title-content {
  font-weight: 600 !important;
  font-size: 14px !important;
  color: #ffffff !important;
}

/* Input  */

.ant-input-lg {
  font-size: 14px !important;
}

.ant-input {
  font-size: 14px !important;
}

.has-value .ant-input-affix-wrapper {
  border: 1px solid #666666 !important;
}

.has-value .ant-input-affix-wrapper-focused,
.has-value .ant-input-affix-wrapper:focus {
  border-color: #605bff !important;
  border: 1px solid #605bff !important;
}

.menu-wrapper .ant-menu-item-disabled .ant-menu-title-content {
  color: #666666 !important; 
}
/* Table */
.ant-table-column-sorter {
  color: #343434 !important;
}

.ant-table-column-sorter-up.active {
  color: #999999 !important;
}
.ant-table-column-sorter-down.active {
  color: #999999 !important;
}

/* .ant-table-column-sorters{
  display: inline;
  justify-content: center !important;
}
 */

/* Pagination */
.ant-pagination-item {
  border: 1px solid #e6eaf0 !important;
  border-radius: 3px !important;
}

.ant-pagination-item a {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  /* identical to box height, or 183% */

  /* Text/Maintext */

  color: #343434;
}

.ant-pagination-item-active {
  /* Text / Primary */

  background: #605bff !important;
  border-radius: 3px;
}

.ant-pagination-item-active:hover {
  /* Text / Primary */

  background: #605bff !important;
  border-radius: 3px;
}
.ant-pagination-item-active a {
  font-weight: 600;
  font-size: 12px;
  font-style: normal;

  color: #ffffff !important;
}

.ant-pagination-item-active:focus-visible a,
.ant-pagination-item-active:hover a {
  color: #ffffff !important;
}

.ant-table-tbody > tr > td {
  font-weight: 400 !important;
  font-size: 14px !important;
  color: #222222 !important;
}

.data-empty {
  display: flex;
  align-items: center;
  justify-content: center;
}

.text-overflow {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.breadcrumb {
  cursor: pointer;
}

.header-row {
  margin-bottom: 32px;
}

/* header page font */
.header-row-title {
  font-size: 24px;
  font-weight: 800;
  line-height: 32px;
  letter-spacing: 0em;
  color: #222222;
}

/* Table */
.ant-table-column-sorter {
  color: #343434 !important;
}

.ant-table-column-sorter-up.active {
  color: #999999 !important;
}
.ant-table-column-sorter-down.active {
  color: #999999 !important;
}

.ant-table-tbody > tr.ant-table-row-selected > td {
  background: #ffffff !important;
}
.ant-table-tbody > tr.ant-table-row-selected:nth-child(odd) > td {
  background: #fafafa !important;
}
.ant-table-tbody > tr.ant-table-row-selected:nth-child(odd) > td:hover {
  background: #fafafa !important;
}
.ant-table-tbody > tr > td {
  font-weight: 400 !important;
  font-size: 14px !important;
  color: #222222 !important;
}
tr:nth-child(odd) {
  background-color: #fafafa;
}
tr:nth-child(2n) td.ant-table-column-sort {
  background: #fff;
}

.ant-table-thead > tr > th {
  font-weight: 700 !important;
  font-size: 14px !important;
  color: #666666 !important;
  background: #f0f0f0 !important;
}
.ant-table-thead
  > tr
  > th:not(:last-child):not(.ant-table-selection-column):not(
    .ant-table-row-expand-icon-cell
  ):not([colspan]):before {
  background-color: transparent !important;
}
/* checkbox */
.ant-checkbox-indeterminate .ant-checkbox-inner::after {
  background-color: white !important;
}

.ant-checkbox-checked .ant-checkbox-inner::after {
  width: 4.3px !important;
  height: 7.5px !important;
  margin-left: 1px !important;
  margin-bottom: 1px !important;
  scale: 0.95 !important;
}

/* Modal */
.ant-modal-title {
  font-weight: 700 !important;
  font-size: 18px !important;
  color: #222222 !important;
  line-height: 26px !important;
}
.ant-modal-footer {
  padding: 24px !important;
}
.ant-modal-header {
  padding: 24px !important;
}
.ant-upload.ant-upload-select-picture-card {
  border-radius: 50px !important;
}

.upload-no-height .ant-upload {
  height: auto !important;
}

.ant-upload {
  height: 50px;
}

.ant-modal-content {
  border-radius: 3px !important;
}

/* Upload */
.upload-component-image .ant-upload.ant-upload-select-picture-card {
  background-image: url("./assets/icons/upload-thumbnail.svg");
  background-size: contain;
  background-repeat: no-repeat;
  border: none !important;
}

.upload-component .ant-upload.ant-upload-select-picture-card div {
  display: none !important;
}

.upload-component .ant-upload.ant-upload-drag {
  border: 1px solid #e9edf2 !important;
  background-color: #ffffff !important;
}
.upload-component .ant-upload-select-picture-card {
  width: 83px !important;
  height: 83px !important;
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.03);
  border-radius: 10px;
  background-color: #f5f5f5;
}

::-webkit-scrollbar {
  width: 8px;
  height: 12px;
  background-color: #f5f5f5;
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.03);
  background-color: rgb(182, 181, 181);
}

/* Dropdown */
.ant-dropdown-menu-item {
  margin: 0px !important;
  padding: 0px !important;
  border-bottom: 1px solid #e9edf2 !important;
}

.ant-dropdown-menu {
  margin-bottom: 0px !important;
  padding-bottom: 0px !important;
  border-radius: 3px !important;
}

.collapse-icon {
  transition: all 0.3s, visibility 0s;
}

.collapse-icon-active {
  transform: rotate(180deg);
}

.circle-image {
  background-size: cover;
  border-radius: 50% 50% 50% 50%;
  width: 84px;
  height: 84px;
}

.image-cropper {
  width: 100px;
  height: 100px;
  position: relative;
  overflow: hidden;
  border-radius: 50%;
}
#noscrollbar ::-webkit-scrollbar {
  display: none;
}

.text-bold-14 {
  font-weight: 700;
  font-size: 14px;
  color: #222;
}

.text-nowrap {
  white-space: nowrap;
}

.disable-button-style {
  cursor: not-allowed !important;
}

.tooltip-text {
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.sticky {
  position: -webkit-sticky;
  position: sticky;
  bottom: 0;
  background: yellow;
}

.ant-popover-buttons {
  display: none !important;
}

.ant-popover-inner-content {
  padding: 6px 8px !important;
  border-radius: 5px;
}
.ant-popover-message {
  padding: 4px 12px !important;
}

.ant-message {
  font-size: 18px !important;
}

/* config scroll bar */
/* width */
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.ant-spin-nested-loading > div > .ant-spin {
  max-height: none !important;
}

.hide-scroll-bar ::-webkit-scrollbar {
  display: none !important;
}

.no-overflow-x {
  overflow-x: hidden !important;
}

/* .input-ccus062-container.lastName .ant-form-item-explain {
  position: relative;
} */

.input-ccus062-container.lastName .ant-form-item-explain .ant-form-item-explain-error {
  position: absolute;
  width: 170px;
  z-index: 1;
}