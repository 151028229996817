.pm014-header-tab {
  display: flex;
  align-items: center;
}

.pm014-header-tab-item {
  border-radius: 3px 3px 0px 0px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  padding: 0 16px;
  border: 1px solid #e9edf2;
  color: #666666;
  font-weight: 400;
  font-size: 14px;
  cursor: pointer;
}
.pm014-header-tab-item-active {
  background-color: #605bff;
  border: none;
  color: #fff;
  font-weight: 700;
  font-size: 14px;
}

.pm014-header-search {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 32px;
}

.pm014-table {
  margin-top: 20px;
  border-top: 1px solid #e9edf2;
  border-radius: 3px;
}

.pm014-table-checkbox {
  width: 5%;
}

.pm014-table-3 {
  width: 25%;
}

.pm014-table-4 {
  width: 25%;
}

.pm014-table-header {
  display: flex;
  height: 40px;
  align-items: center;
  background-color: #f5f5f5;
}

.pm014-table-collapse-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 64px;
  background-color: #f4f3ff;
  border-top: 1px solid #e9edf2;
  border-bottom: 1px solid #e9edf2;
}

.pm014-table-collapse-header-left,
.pm014-table-collapse-header-right {
  display: flex;
  align-items: center;
}

.pm014-table-collapse-header-left label {
  cursor: pointer;
  border: none;
  background-color: transparent;
  margin-left: 40px;
}

.pm014-table-collapse-header-title {
  font-weight: 400;
  font-size: 14px;
  color: #222222;
  margin: 0 16px;
}

.pm014-table-collapse-header-count {
  border: 1px solid rgba(96, 91, 255, 0.5);
  border-radius: 3px;
  padding: 5px 16px;
  font-weight: 400;
  font-size: 14px;
  color: #222222;
}
.pm014-table-collapse-header-right {
  margin-right: 21px;
}

.pm014-table-collapse-header-right-text {
  font-weight: 700;
  font-size: 14px;
  color: #222222;
}

.pm014-table-item {
  display: flex;
  align-items: center;
  padding: 18px 0;
  border-bottom: 1px solid #e9edf2;
}
.pm014-table-item:last-child {
  border-bottom: none;
}

.pm014-table-item:nth-child(2n) {
  background-color: #fafafa;
}

.pm014-table-collapse,
.pm014-table-collapse-header {
  transition: all 0.3s, visibility 0s;
}

.pm014-table-collapse-body {
  display: none;
  transition: all 0.3s, visibility 0s;
}

.pm014-table-collapse input:checked ~ .pm014-table-collapse-body {
  height: 100%;
  display: block;
}

.pm014-table-collapse-header img {
  transition: all 0.3s, visibility 0s;
}

.pm014-table-collapse input:checked ~ .pm014-table-collapse-header img {
  transform: rotate(-180deg);
}

.pm014-table-header-item {
  display: flex;
  align-items: center;
}

.pm014-table-sorter {
  display: flex;
  flex-direction: column;
  margin-top: -4px;
  cursor: pointer;
}

.pm014-table-sorter button {
  background-color: transparent;
  border: none;
  margin: -9px 0;
  cursor: pointer;
}

.btn-sorter-active {
  opacity: 0.4;
}

.pm014-table-body {
  max-height: 50vh;
  min-height: 50vh;
  overflow: auto;
}

.pm014-body {
  height: 58vh;
  padding-bottom: 0;
}

.pm014-table .ant-collapse {
  margin: 2px 0 !important;
}

.pm014-table .ant-collapse > .ant-collapse-item {
  border-bottom: none !important;
}
.pm014-table .ant-collapse:last-child {
  border-bottom: 1px solid #e9edf2 !important;
}

.pm014-body .pagination-component-list {
  margin-bottom: -24px;
}

.pm014-table .ant-checkbox-group {
  width: 100%;
}

.pm014-table-footer {
  font-weight: 400;
  font-size: 14px;
  color: #222;
}

.pm014__modal-invite {
  background: #ffffff;
  box-shadow: 0px 8px 8px rgba(102, 102, 102, 0.05);
  border-radius: 3px;
}

.pm014__button-confirm {
  width: 76px;
  height: 40px;
  background: #605bff;
  color: #fff;
  box-shadow: 4px 8px 24px 2px rgba(133, 133, 133, 0.08);
  border-radius: 3px;
  letter-spacing: -1px;

  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 22px;
  color: #ffffff;
}

.pm014__button-confirm:hover {
  background: #605bff;
  color: #fff;
}

.pm014__button-cancel {
  width: 118px;
  height: 40px;
  background: #ffffff;
  border: 1px solid #e9edf2;
  border-radius: 3px;

  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;

  color: #666666;
}

.pm014__button-cancel:hover {
  background: #ffffff;
  color: #666666;
  border: 1px solid #e9edf2;
}

.pm014__button-cancel:active {
  background: #ffffff;
  color: #666666;
  border: 1px solid #e9edf2;
}

.pm014__nodata {
  margin-top: 114px;
}

.pm014-footer h4 {
  position: absolute;
  left: 320px;
  bottom: 25px;

  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #605bff;
}

/* .pm014-table .ant-checkbox-checked .ant-checkbox-inner {
  background-color: #eee !important;
  border-color: #eee !important;
}

.pm014-table-checkbox .ant-checkbox-checked .ant-checkbox-inner {
  background-color: #605bff !important;
  border-color: #605bff !important;
} */

.pm014-disable-button {
  background: rgba(34, 34, 34, 0.4) !important;
  box-shadow: 4px 8px 24px 2px rgba(133, 133, 133, 0.08) !important;
  border-radius: 3px !important;
  color: #fff !important;
  letter-spacing: -1px;
}
.pm014-button-cancel {
  gap: 10px;
  width: 118px;
  height: 40px;
  background: #ffffff;
  border: 1px solid #e9edf2;
  border-radius: 3px;
  color: #666;

  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
}

.pm014-input-edit {
  /* display: flex;
  flex-direction: row;
  align-items: center;
  padding: 8px 12px;
  gap: 12px; */
  width: 150px;
  height: 38px;
}
