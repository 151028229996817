.cm004-title {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  display: flex;
  align-items: center;
  color: #222222;
}

.cm004-description {
  display: flex;
  flex-direction: column;

  margin-top: 16px;
  height: 260px;
  border: 1px solid #dae2ee;
  border-radius: 3px;
}

.cm004-description .cm004-item {
  display: flex;
  flex-direction: row;
}

.cm004-description .cm004-item .title {
  display: flex;
  flex-direction: row;
  align-items: center;

  width: 216px;
  height: 52px;
  padding-left: 50px;

  border-width: 0px 1px 1px 0px;
  border-style: solid;
  border-color: #dae2ee;
  background-color: #f0f0f0;

  /*Text*/

  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 22px;
  color: #666666;
}

.cm004-description .cm004-item .content {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  width: 100%;
  height: 52px;
  padding-left: 50px;

  border-width: 0px 1px 1px 0px;
  border-style: solid;
  border-color: #dae2ee;
  background-color: #fff;

  /*Text*/

  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: #222;
}

.no-border-right {
  border-right: 0px !important;
}

.row-hight-light {
  background-color: #fafafa !important;
}

.cm001-content-tab .cm004-tab {
  width: 400px;
  margin-top: 20px;
}

.w500 {
  width: 500px !important;
}

.w80 {
  width: 80px !important;
}

.cm001-element .element-budget .content .cm004-footer {
  display: flex;
  flex-direction: row;
}

.cm001-element .element-budget .content .cm004-footer .item {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 289px;
  height: 52px;
  background: #605bff;

  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 22px;
  color: #fff;
}

.bg-header {
  background-color: #dae2ee !important;
  color: #222 !important;
  font-weight: 700 !important;
}

.input-add-on-threshold .ant-input-number-group-addon {
  background-color: #fff !important;
  border: none !important;
  padding: 0px !important;
}

.input-add-on-threshold .ant-input-number-input {
  padding-right: 0px !important;
  margin: 0px !important;
}

.threshold-container div.ant-popover-inner-content {
  padding: 6px 8px !important;
}

.cm004-main-description {
  border: 1px solid #dae2ee;
}

.cm004-description-item {
  height: 52px;
  display: flex;
}

.cm004-description-label {
  height: 52px;
  width: 14.1%;
  min-width: 216px;
  border-right: 1px solid #dae2ee;
}

.cm004-description-label {
  height: 52px;
  width: 14.1%;
  min-width: 216px;
  border-right: 1px solid #dae2ee;
}

.cm004-description-content {
  height: 52px;
  width: 85.9%;
}
