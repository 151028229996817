.input-select .ant-input-affix-wrapper {
    border: 1px solid #dae2ee;
}

.input-select .ant-input-affix-wrapper:focus,
.input-select .ant-input-affix-wrapper-focused {
    box-shadow: none;
}

.input-select .ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
    border-color: #dae2ee;
}

.input-select-list {
    background-color: #fff;
    box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08),
        0 9px 28px 8px rgba(0, 0, 0, 0.05);
    padding: 3px 0;
    position: absolute;
    width: 100%;
    top: calc(100% + 4px);
    transform: scaleY(0);
    transform-origin: top;
    transition: all 0.3s, visibility 0s;
    opacity: 0;
    z-index: 99;
    border-radius: 3px;
    max-height: 200px;
    overflow-y: auto;
    scroll-behavior: smooth;
}

.input-select-list.active {
    transform: scaleY(1);
    opacity: 1;
}

.input-select {
    position: relative;
}

.input-select-item {
    padding: 8px 16px;
    border-bottom: 1px solid #dae2ee;
}

.input-select-item.active {
    background-color: #f3f0ff;
}
.input-select-item.active:hover {
    background-color: #f3f0ff;
}

.input-select-item:last-child {
    border-bottom: none;
}

.input-select-item.footer {
    padding: 0;
}

.input-select-item:hover {
    background-color: #f5f5f5;
    cursor: pointer;
}
