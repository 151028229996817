.aop--001__header-unit {
    display: flex;
    flex-direction: row;

    justify-content: space-between;
    align-items: center;
}

.aop--001__header-title {
    font-size: 24px;
    font-weight: 800;
    line-height: 32px;
    color: #222;
}

.aop--001__header-action {
    display: flex;
    flex-direction: row;
    height: 40px;
    padding: 8px 24px;
    align-items: center;
    gap: 8px;
    border-radius: 3px;
    background: #605bff;
    box-shadow: 4px 8px 24px 2px rgba(133, 133, 133, 0.08);
}

.aop--001__header-action:hover {
    background: #605bff;
}

.aop--001__header-action--text {
    font-size: 14px;
    font-weight: 700;
    line-height: 22px;
    color: #fff;
}
