.ic1-wrapper {
  height: 100%;
  display: flex;
  flex-direction: column;
}
.ic1-header-info {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 40px;
}

.ic1-header {
  font-weight: 800;
  font-size: 24px;
  color: #222222;
  margin: 8px 0 20px 0;
}

.ic1-body {
  background: #ffffff;
  width: 100%;
  box-shadow: 8px 8px 8px rgba(153, 153, 153, 0.02);
  border-radius: 3px;
  padding: 24px;
  height: 80vh;
  display: flex;
  flex-direction: column;
  gap: 40px;
}
.ic1-header-btn {
  display: flex;
  align-items: center;
}
.edit-button-ic1 {
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 1px;
}
.content-wrapper-ic1 {
  display: flex;
  flex-direction: column;
}
.item-info {
  display: flex;
  height: 42px;
  align-items: center;
}
.label-text {
  width: 100px;
  text-align: right;
  margin-right: 24px;

  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: #666666;
}
.info-text {
  text-align: left;
  margin-right: 24px;

  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: #222222;
}

/* IC2 */

.ic2-body {
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 100%;
  height: fit-content;
}

.ic2-content {
  background: #ffffff;
  box-shadow: 8px 8px 8px rgba(153, 153, 153, 0.02);
  border-radius: 3px;
  padding: 24px 53px 24px 53px;
  height: 70vh;
  display: flex;
  flex-direction: column;
}

.ic2-btn-wrapper {
  height: 88px;
  display: flex;
  justify-content: flex-end;
  background: #ffffff;
  border-radius: 3px;
  box-shadow: 8px 8px 8px rgba(153, 153, 153, 0.02);
  align-items: center;
  padding: 24px;
}

.content-form {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.item-info-ic2 {
  display: flex;
}
