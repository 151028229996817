.cm3453-title {
  color: #222222;
  font-weight: 800;
  font-size: 24px;
  margin: 8px 0 32px 0;
}
.cm034-cm053-select {
  min-width: 320px;
}

.cm03854-input-search {
  box-shadow: 8px 8px 8px rgba(153, 153, 153, 0.02);
  border-radius: 3px;
  min-width: 970px;
  background-color: #fff;
  height: 40px;
}
@media (max-width: 1650px) {
  .cm03854-input-search {
    min-width: 800px; /* Adjust the maximum width as needed for smaller screens */
  }
}

@media (max-width: 1450px) {
  .cm03854-input-search {
    min-width: 600px; /* Adjust the maximum width as needed for smaller screens */
  }
}
@media (max-width: 1300px) {
  .cm03854-input-search {
    min-width: 400px; /* Adjust the maximum width as needed for smaller screens */
  }
}
.cm03854-input-search input {
  background-color: #fff;
}

.cm-no-data {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 32px;
}

.cm03854-input-search-2 {
  box-shadow: 8px 8px 8px rgba(153, 153, 153, 0.02);
  border-radius: 6px;
  background-color: #fff;
}

.cm03854-input-search-2 input {
  background-color: #fff;
}

.cm-wrapper-header {
  position: relative;
}

.cm-btn-disable .ant-checkbox-disabled .ant-checkbox-inner {
  background-color: #999999;
}

.cm-btn-disable
  .ant-checkbox-disabled.ant-checkbox-checked
  .ant-checkbox-inner::after {
  border-color: #fff;
}

.cm034-cm053-search-form {
  position: absolute;
  left: 50%;
  top: -50%;
  transform: translateX(-50%);
}
