.cm035 {
  margin-top: 24px;
}

.cm035-item {
  border: 1px solid #dae2ee;
  border-radius: 3px;
  margin-bottom: 4px;
}

.cm035-item-header {
  padding: 15px 50px;
  background: #dae2ee;
  color: #222222;
  font-weight: 600;
  font-size: 16px;
}

.cm035-item-header-2 {
  padding: 15px 50px;
  background: #f0f0f0;
  color: #222222;
  font-weight: 600;
  font-size: 16px;
}

.cm035-table {
  width: 100%;
}

.cm035-table tr th,
.cm035-table tr td {
  border: 1px solid #dae2ee;
  text-align: center;
  padding: 15px;
}

.cm035-table tr th:first-child,
.cm035-table tr td:first-child {
  border-left: none;
}

.cm035-table tr th:last-child,
.cm035-table tr td:last-child {
  border-right: none;
}

.cm035-table tr:last-child td {
  border-bottom: none;
}

.cm035-table tr th {
  font-weight: 700;
  font-size: 14px;
  color: #666666;
}

.cm035-table tr td {
  color: #222222;
  font-weight: 400;
  font-size: 14px;
}

.cm035-table tbody tr td,
.cm042-table tbody tr td {
  border-top: none;
}

.cm035-table-body,
.cm042-table-body {
  max-height: 105px;
  overflow-y: scroll;
  width: 100%;
  scroll-behavior: smooth;
}

th.th-scroll {
  padding: 0 4px !important;
  border-left: none !important ;
}

.th-not-border-right {
  border-right: none !important;
}

.td-w10 {
  width: 10%;
}

.td-w4 {
  width: 22.5%;
}
.table-sort {
  display: flex;
  flex-direction: column;
  width: 10px;
  cursor: pointer;
  margin-left: 8px;
}
.icon-down {
  transform: rotate(180deg);
  margin-top: -1px;
}

.icon-sorted {
  opacity: 0.5;
}

.table-sort-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}

.table-cursor {
  cursor: pointer;
}

.cm035-table-body-color tr td {
  background-color: #fff !important;
}

.cm035-table-body-color tr:nth-child(2n) td {
  background-color: #fafafa !important;
}
