.card-container {
  background-color: #fff;
  padding: 24px;
  border-radius: 8px;
  padding-left: 24px !important;
  padding-right: 24px !important;
}
.card-header {
  display: flex;
  justify-content: space-between;
}
.card-header-left {
  display: flex;
}
.card-header-right {
  align-items: center;
  justify-content: flex-end;
  display: flex;
  float: left;
  width: 70%;
}
.card-content-left h3 {
  margin-bottom: 16px;
}

.card-content {
  display: flex;
}
.content-title {
  display: flex;
  margin-top: 8px;
}
.card-content-left {
  width: 82%;
  margin-top: 32px;
}
.title-left {
  width: 92px;
  color: #666;
}
.pm002-text {
  color: #222;
  width: 260px;
}
.card-content-right .ant-avatar-group {
  transform: rotate(90deg);
}
.card-content-right {
  margin-top: 57px;
}
.header-select {
  border: 1px solid #e9edf2;
  box-shadow: 8px 8px 8px rgba(153, 153, 153, 0.02);
  border-radius: 6px;
  margin-right: 8px;
}
.card-header-info {
  margin-left: 8px;
}

.button-add-avatar svg {
  color: white;
}

.avatar-group-img .ant-avatar {
  transform: rotate(-90deg);
}

.card-header-contruction-name {
  font-weight: 600;
  font-size: 16px;
  margin-bottom: 4px;
}
.card-header-project-name {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: 400;
  font-size: 13px;
}
.avatar-badge-button .ant-badge-count {
  margin-top: 8px;
  font-size: 9px;
  text-align: center;
  height: 21px;
  width: 18px;
  padding-left: 5px;
}
.header-info-card {
  background-color: #fff;
  /* color: white; */
}

.info-card-default-child {
  background-color: #605bff;
  color: #fff;
}
.info-card-default-child .header-button-icon {
  background: linear-gradient(270deg, #2ec5c1 0%, #119cd3 100%);
}
.info-card-default .header-button-icon {
  background: linear-gradient(270deg, #2ec5c1 0%, #119cd3 100%);
}
.header-info-card:hover {
  background-color: #e8e8ff;
  color: #000;
}

.info-card-default,
.info-card-default:hover {
  background-color: #605bff;
  color: #fff;
}

.header-info-card:hover .header-button-icon {
  background-color: #605bff;
}

.header-info-card:hover .filter-white {
  filter: brightness(0) invert(1);
}
.info-card-default .filter-white {
  filter: brightness(0) invert(1);
}
.info-card-default-child .filter-white {
  filter: brightness(0) invert(1);
}

.text-card {
  max-width: 100px;
}
.text-card-location {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  width: 100px;
}
/* modal title  */
.modal-title {
  font-style: 18px;
  font-weight: 700;
}

.pm002-subcontractor {
  font-weight: 400;
  font-size: 12px;
  padding: 3px 10px;
  border-radius: 30px;
  background-color: #605bff;
  color: #fff;
}

.pm002-subcontractor span {
  width: 150px;
  text-overflow: ellipsis;
  display: block;
  overflow: hidden;
  white-space: nowrap;
}

.pm002-subcontractor-detail {
  width: max-content;
}

.pm002-subcontractor-detail span {
  width: 100%;
}

.border-color-select.ant-select-disabled .ant-select-selector:hover {
  border: 1px solid #d9d9d9 !important;
}

.search-project {
  width: 1306px;
}
@media screen and (max-width: 1780px) {
  .search-project {
    width: 70%;
  }
}
