.sider-profile {
  height: 72px;
  background: #19173f;
  box-shadow: inset 0px 1px 0px rgba(255, 255, 255, 0.06);
  width: 300px;
  position: fixed;
  bottom: 0;
  padding: 16px;
  display: flex;
}

.profile-infor {
  width: 200px;
  margin-left: 8px;
  display: block;
}

.profile-name {
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 22px;
  /* identical to box height, or 169% */

  /* Text/White */

  color: #ffffff;
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.profile-email {
  width: 100%;

  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 18px;
  /* identical to box height, or 138% */

  /* Text/Sub Text */

  color: #666666;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.side__menu {
  background-color: "#131125" !important;
  height: 100vh !important;
  width: 300 !important;
  overflow: auto !important;
  height: 100vh;
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  overflow-x: hidden !important;
}
