.pm009 .ant-drawer-header-title {
  flex-direction: row-reverse;
  align-items: start !important;
}
.pm009 .ant-drawer-close {
  margin: 0px !important;
}

.pm009-header {
  display: flex;
  align-items: flex-start;
}

.pm009-header img {
  cursor: pointer;
  margin-right: 16px;
}

.pm009-header img:hover {
  opacity: 0.8;
}

.pm009 .ant-drawer-title {
  width: 90%;
}

.pm009-header-title {
  width: 90%;
}

.pm009-header-title .pm009-header-title-h2 {
  color: #222222;
  font-weight: 700;
  font-size: 18px;
}

.pm009-header-title span {
  color: #666666;
  font-weight: 400;
  font-size: 14px;
}

.pm009 .ant-drawer-footer {
  background: #f5f5f5;
  min-height: 54px;
}
.pm009 .ant-drawer-body {
  padding: 0;
}

.pm009-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.pm009-footer h4 {
  color: #222222;
  font-weight: 600;
  font-size: 14px;
}

.pm009-footer img {
  cursor: pointer;
}
.pm009-footer img:hover {
  opacity: 0.8;
}

.pm009-item {
  padding: 21px 0;
  text-align: center;
  border-bottom: 1px solid #e6eaf0;
  cursor: pointer;
}
.pm009-item:hover {
  background-color: rgba(0, 0, 0, 0.01);
}

.pm009-item-text {
  color: #666666;
  font-weight: 400;
  font-size: 14px;
}

.pm009-item-detail {
  padding: 16px;
  border-bottom: 1px solid #dae2ee;
  display: flex;
  justify-content: space-between;
  cursor: pointer;
}

.pm009-item-detail-active {
  background-color: #e7e6ff;
}
.pm009-item-left {
  max-width: 90%;
}

.pm009-item-left {
  display: block;
  color: #222222;
  font-weight: 500;
  font-size: 14px;
  padding: 4px 0;
}

.pm009-item-weight-400 {
  font-weight: 400;
}

.pm009-item-close {
  opacity: 0.4;
}

.pm009-item-close img {
  cursor: pointer;
}

.notification-no-data {
  line-height: 18vh;
  font-weight: 700;
  font-size: 14px;
  color: rgba(34, 34, 34, 0.4);
  text-align: center;
}

.pm009 .ant-drawer-body .ant-spin-nested-loading,
.pm009 .ant-drawer-body .ant-spin-container,
.pm009 .ant-drawer-body .pm009-body-detail,
.pm009 .ant-drawer-body .infinite-scroll-component__outerdiv {
  height: 100%;
}

.pm009 .ant-drawer-header {
  padding: 32px 16px 16px 16px !important;
}
