.sm03-modal{

}

.sm03-modal .modal-title{
    color: #222222;
    font-weight: 700;
    font-size: 18px;
}

.sm03-modal .sm03-content .ant-select-selector{
    height: 40px;
    line-height: 40px;
    display: flex;
    align-items: center;
}

.sm03-modal .sm03-content .list-guest{
    display: flex;
    align-items: center;
    margin-left: 92px;
    flex-wrap: wrap;
}

.sm03-modal .sm03-content .list-guest .guest-item{
    padding: 0px 8px;
    padding-bottom: 2px;
    gap: 4px;
    min-width: 75px;
    height: 32px;       
    margin-bottom: 16px;
    background: #FFFFFF;
    border: 1px solid #605BFF;
    box-shadow: 4px 8px 24px 2px rgba(133, 133, 133, 0.08);
    border-radius: 30px;
    margin-left: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #222222;
}

.sm03-modal .sm03-content .list-guest .guest-item:first-child{
    margin-left: 0px;
}

.sm03-modal .sm03-content .list-guest .guest-item .close-icon{
    width: 12.5px;
    height: 12.5px;
    color:#666666;
    cursor: pointer;
    margin-top: 3px;
}

.sm03-modal .sm03-content .list-guest .guest-item .guest-text{
    color:  #222;
    font-size: 12px;
    font-weight: 400;
}

.sm03-modal .sm03-content .checkbox span{
    color: #222222;
}

.sm03-modal .sm03-content .ant-select-selection-item{
    display: flex;
}

.sm03-modal .sm03-content .sm03-date-picker{
    width: 920px !important;
}

.sm03-modal .sm03-cancel-btn{
    background-color: #FFFFFF;
    border: 1px solid #DAE2EE;
    border-radius: 3px;
    color: #666666;
}

.sm03-modal .ant-form-item-label{
    line-height: 40px;
}

.sm03-modal .sm03-textarea .ant-form-item-label{
    line-height: 98px;
}

.sm03-modal .sm03-textarea.ant-form-item-has-error .ant-input{
    border: 1px solid #ff4d4f;
}

.sm03-modal .ant-input:focus,.sm03-modal .ant-input-focused {
    box-shadow: none;
}

.sm003-popup-timmer .ant-picker-panel{
    width: 150px !important;
}
