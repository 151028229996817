.label-detail-group {
  font-weight: 400;
  font-size: 14px;
  color: #666666;
  text-align: right;
}

.info-detail-group {
  font-weight: 400;
  font-size: 14px;

  color: #222222;
}
