.aop--001__content {
  display: flex;
  flex-direction: row;

  height: auto;
  width: 100%;
  background: #fff;
  border-radius: 3px;
  box-shadow: 8px 8px 8px 0px rgba(153, 153, 153, 0.02);
}

.aop--001__sidebar {
  display: flex;
  flex-direction: column;

  width: 242px;
  height: auto;
  background-color: #fcfcfc;
  border-radius: 3px 0px 0px 3px;
  border-right: 1px solid #dae2ee;
}

.aop--001__sidebar--filter {
  display: flex;
  flex-direction: column;
  row-gap: 8px;

  justify-content: center;

  margin: 16px;
}

.aop--001__sidebar--filter-text {
  font-size: 14px;
  font-weight: 700;
  line-height: 22px;
  color: #222;
}

.aop--001__sidebar--filter-search {
  display: flex;
  padding: 9px 12px 9px 16px;
  border-radius: 3px;
  background: #dae2ee;
  border: 1px solid #dae2ee;
}

.aop--001__sidebar--filter-search .ant-input {
  background: #dae2ee;
}

.aop--001__sidebar--filter-search::placeholder {
  font-size: 14px;
  font-weight: 400;
  color: #666;
}

.aop--001__sidebar--list {
  display: flex;
  flex-direction: column;
  row-gap: 16px;
}

.aop--001__sidebar--list-title {
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;
}

.aop--001__sidebar--list-text {
  font-size: 14px;
  font-weight: 700;
  line-height: 22px;
  color: #222;
}

.aop--001__sidebar--list-member {
  display: flex;
  flex-direction: column;
}

.aop--001__sidebar--list-member-unit {
  display: flex;
  flex-direction: row;

  height: 64px;
  width: 100%;
  border-top: 1px solid #dae2ee;
}

.aop--001__sidebar--list-member-unit:last-child {
  border-bottom: 1px solid #dae2ee;
}

.aop--001__sidebar-lmb-text {
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  color: #666;
}

.aop--001__sidebar-lmb-unit-wrapper {
  display: flex;
  flex-direction: row;
  column-gap: 16px;

  justify-content: flex-start;
  align-items: center;

  margin: 0px 16px;
  width: 100%;
}

.aop--001__sidebar-pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 30px 16px 20px 16px;
}

.aop--001__sidebar-pagination .ant-pagination {
  line-height: 2;
}

.aop--001__unit {
  display: flex;
  flex-direction: column;

  row-gap: 16px;
  margin: 24px;
  width: 100%;
}

.aop--001__unit-title {
  display: flex;
  flex-direction: row;

  column-gap: 8px;
  justify-content: flex-start;
  align-items: center;
}

.aop--001__unit-title--text {
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  color: #222;
}

.aop--001__unit-title--info {
  cursor: pointer;
  margin-top: -4px;
}

.aop--001__unit-tab {
  display: flex;
  flex-direction: column;
}

.aop--001__unit-tab-switch {
  display: flex;
  flex-direction: row;
}

.aop--001__unit-tab-sw1 {
  display: flex;

  padding: 9px 16px;
  align-items: center;
  gap: 10px;
  border-radius: 3px 3px 0px 0px;
  background-color: #605bff;

  font-size: 14px;
  font-weight: 700;
  line-height: 22px;
  color: #fff;

  cursor: pointer;
}

.aop--001__unit-tab-sw2 {
  display: flex;

  padding: 9px 16px;
  align-items: center;
  gap: 10px;

  border-radius: 3px 3px 0px 0px;
  border: 1px solid #dae2ee;
  background-color: #fff;

  cursor: pointer;
  color: #666;
}

.aop--001__sidebar--filter-heading {
  width: 100%;
  padding: 10px;
  border: 1px solid #dae2ee;
  border-radius: 3px;

  color: #222;
  font-weight: 600;
}
