.am019-range {
  border-radius: 6px;
  width: 250px;
  height: 40px;
  border: 1px solid #605bff;
}

.am019-range input {
  font-weight: 400 !important;
  font-size: 14px !important;
  color: #605bff;
}

.am019-range .icon-calender {
  width: 18px;
}

.am019-range .ant-picker-clear {
  padding: 12px 0 12px 4px;
}

.am019-range .ant-picker-separator svg {
  color: #605bff;
}

.cm019-select {
  box-shadow: 8px 8px 8px rgba(153, 153, 153, 0.02);
  font-weight: 400;
  font-size: 14px;
  min-width: 375px;
  max-width: 375px;
}

.cm019-select .ant-select-selector {
  border-radius: 3px !important;
  border: 1px solid #dae2ee !important;
}

.cm0019-body .am028-filter {
  border-radius: 6px;
  margin-bottom: 32px;
}

.cm019-btn-tick {
  width: 90px;
  height: 40px;
  padding: 9px 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 3px !important;
}

.cm019-btn-close {
  width: 90px;
  height: 40px;
  padding: 9px 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 3px !important;
  margin-left: 16px;
}

.cm019-btn-download {
  background: #dae2ee !important;
  border: 1px solid #605bff !important;
  border-radius: 3px;
  color: #605bff;
  padding: 9px 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 106px;
  margin-left: 16px;
}

.td-child {
  position: relative;
}

.icon-collapse-table {
  position: absolute;
  top: 50%;
  right: 12px;
  transform: translateY(-50%);
  cursor: pointer;
}

.icon-collapse-table img {
  width: 24px;
}

.cm019-table {
  border-left: none;
}

.cm019-table table tr.cm019-table-child > td {
  padding: 0;
}

.cm019-table-child table tr td,
.cm019-table-child table tr th {
  border-left: none !important;
  border-top: none !important;
  border-right: 1px solid #dae2ee !important;
}

.cm019-table-child table tr td:first-child,
.cm019-table-child table tr th:first-child {
  padding-left: 50px;
}

.cm019-table-child table tr td {
  font-size: 14px !important;
  font-weight: 400 !important;
  color: #222 !important;
}
.cm019-table-child td {
  background-color: #fff !important;
}

.cm019-row-level-1 td {
  background-color: #fafafa !important;
}

.cm019-table-child table tr.cm019-row-level-1 td {
  font-weight: 700 !important;
}

.cm019-table-child table tr td.cm019-td-level-1 {
  font-weight: 700 !important;
  font-size: 14px !important;
  color: #605bff !important;
}

.cm019-table-child table tr td.cm019-td-level-2 {
  padding-left: 80px !important;
  background-color: #fff !important;
}

.cm019-table-child table {
  overflow: unset !important;
}
.cm019-table > table,
/* .cm019-table-body > table, */
.cm019-table-footer > table {
  table-layout: fixed;
  width: 100% !important;
}

.cm019-table-body {
  /* max-height: 40vh;
    overflow-y: scroll; */
}

.th-scroll {
  padding: 0 !important;
  width: 12px;
}

.cm0019-filter-btn button:disabled {
  opacity: 0.5;
}

.cm0020-range {
  min-width: 240px;
}

table tr td.none-bl,
table tr th.none-bl {
  border-left: none !important;
}

.cm0020-table {
  table-layout: fixed;
  width: 100% !important;
}

.cm0020-table tr td {
  white-space: nowrap;
}
.cm0020-table tr td.wrap-text {
  white-space: unset;
}

.cm019-table ::-webkit-scrollbar {
  width: 10px !important;
  height: 10px !important;
}

.cm019-table-child .ant-table-thead {
  z-index: unset !important;
}
