.cr003-item-text-list-style {
  list-style: outside;
  display: list-item;
  margin-left: 24px;
}

.cr-color div,
.cr-color span {
  color: #222222;
}

.cr-color .ant-form-item-explain-error {
  color: #ff4d4f;
  padding-left: 18px;
}

.cr003-margin {
  min-height: 22px;
}

.cr003-icon-input {
  font-weight: 400;
  font-size: 15px;
  color: #666;
}

.cr003-date-picker {
  width: 57% !important;
}

.cr001 {
  font-weight: 400;
  font-size: 15px;
  color: #222222;
}

.pb-24{
  padding-bottom: 24px;
}

.cr001-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #e9edf2;
  height: 88px;
}

.cr001-header-label {
  font-size: 16px;
  font-weight: 600;
  color: #666;
}

.cr001-header-btn {
  display: flex;
  align-items: center;
}

.cr001-header-btn-history {
  border-color: #8d85ff;
  color: #8d85ff;
}

.cr001-collapse {
  margin-top: 24px;
}

.cr001-contract-amount-content-label,
.cr001-contract-amount-content-item {
  display: flex;
  align-items: center;
  padding: 15px 0;
  border-bottom: 1px solid #e9edf2;
  min-height: 52px;
}

.cr001-contract-amount-content-label-left,
.cr001-contract-amount-content-item-left {
  width: 45%;
  padding-left: 50px;
}

.cr001-contract-amount-content {
  border-left: 1px solid #e9edf2;
}

.cr001-contract-amount-content-label {
  background-color: #fafafa;
}

.cr001-contract-amount-panel .ant-collapse-content-box {
  padding: 0 0 6px 0px;
}

.cr001-construction-cost-item {
  display: flex;
  border-bottom: 1px solid #e9edf2;
}

.cr001-construction-cost-item-child {
  padding: 15px 0;
  border-right: 1px solid #e9edf2;
  text-align: center;
}

.cr001-construction-cost-panel .ant-collapse-content-box {
  padding: 0 0 6px 0;
}

.bg-header-row {
  background-color: #fafafa;
}

.bg-title-row {
  background-color: #f0f0f0;
}

.padding-tb-15 {
  padding: 15px 0;
}

.cr001-cost-registration-title {
  padding-left: 54px;
}

.cr001-cr-item {
  display: flex;
  min-height: 52px;
}

.cr001-cr-item-child {
  text-align: center;
}

.cr-7 {
  min-width: 14.2857%;
}

.cr-6 {
  width: 16.6666%;
}

.cr-3 {
  width: 28.5714%;
}
.cr-3-all {
  width: 33.3333%;
}
.cr-2 {
  width: 50%;
}

.cr-4 {
  width: 25%;
}

.cr-4-other {
  width: 21.428575%;
}

.border-bottom {
  border-bottom: 1px solid #e9edf2;
}

.border-right {
  border-right: 1px solid #e9edf2;
}

.cr001-collapse .ant-collapse-header-text {
  padding-left: 34px;
}

.cr001-cost-registration-title {
  display: flex;
  align-items: center;
}

.cr001-cost-registration-title span {
  margin-left: 6px;
}

.cr001-drawer .ant-drawer-header-title {
  flex-direction: row-reverse;
}

.cr001-drawer .ant-drawer-header {
  border-bottom: none !important; 
}

.cr001-drawer-header-label {
  color: #222222;
  font-weight: 700;
  font-size: 18px;
}

.cr001-drawer .ant-drawer-content-wrapper {
  width: 399px !important;
}

.cr001-drawer .ant-drawer-body {
  padding: 0;
}

.cr001-drawer-header-title {
  display: flex;
  align-items: center;
  margin-top: 6px;
}

.cr001-drawer-header-title-text {
  font-size: 15px;
  color: #666666;
  margin-left: 8px;
}

.cr001-drawer-item {
  display: flex;
  justify-content: space-between;
  padding: 23px 16px;
  border-top: 1px solid #e9edf2;
  border-bottom: 1px solid #e9edf2;
  margin-bottom: 4px;
  background-color: rgb(248, 248, 248);
}

.cr001-drawer-item-right-text:hover {
  text-decoration: underline;
  cursor: pointer;
}
.cr001-drawer-item-show-version:hover {
  opacity: 0.6;
  cursor: pointer;
}

.cr001-drawer-item:first-child {
  border-top: none;
}

.cr001-drawer-item-left {
  display: flex;
  align-items: center;
}

.cr001-drawer-item-text {
  color: #222;
  font-size: 15px;
  font-weight: 700;
  margin-right: 4px;
}

.cr001-drawer-item-left img {
  width: 20%;
}

.cr001-drawer-item-right-text {
  color: #605bff;
  font-size: 15px;
  font-weight: 400;
}

.cr001-drawer-item-right-time {
  color: #666666;
  font-size: 15px;
  font-weight: 400;
  margin-left: 16px;
}

.cr001-history-modal {
  padding-left: 300px;
  overflow: hidden;
}

.cr001-history-modal .ant-modal-header {
  min-height: 74px;
  display: flex;
  align-items: center;
}

.cr001-history-modal .ant-modal-close-x {
  height: 74px;
  line-height: 74px;
}

.cr001-history-modal .ant-modal-title {
  color: #222;
  font-weight: 700;
  font-size: 18px;
}

.cr001-modal-detail-history span,
.cr001-modal-history-note-text {
  font-size: 15px;
  font-weight: 400;
}

.cr001-modal-detail-history-label {
  margin-right: 20px;
}

.cr001-modal-detail-history-note {
  display: flex;
  margin-top: 16px;
  margin-bottom: 14px;
}

.cr001-modal-history-note-text {
  display: block;
  width: 90px;
  min-width: 90px;
}
.cr001-modal-detail-history-note p {
  font-weight: 400;
  font-size: 15px;
  color: #000000;
}

.cr001-history-modal .ant-modal-footer {
  min-height: 88px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.input-width-200 {
  width: 200px;
  min-height: 36px;
}

.cr001-contract-amount-panel .ant-collapse-header-text {
  flex: unset !important;
  margin-right: 40px;
}

.cr001-contract-amount-panel .ant-collapse-header {
  justify-content: flex-end;
}

.cr001-choose-date {
  min-height: 38px;
}

.cr001-choose-date .ant-picker-range-separator {
  padding: 0 2px;
}

.margin-6 {
  margin: 0 6px;
}
.pd-left-24 {
  width: 12px;
}

.input-cr001 {
  max-width: 80%;
  min-height: 38px;
  min-width: 150px;
}

.cr001-cr-item-bonus {
  display: flex;
  align-items: flex-start;
  padding-left: 12px;
}

.cr001-cr-item-bonus img {
  margin-right: -8px;
  cursor: pointer;
}

.cr001-choose-building {
  display: flex;
  align-items: flex-start;
}

.cr001-choose-building-icon-bonus {
  width: 20%;
  cursor: pointer;
}

.cr001-choose-building-content {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  cursor: pointer;
  padding-right: 6px;
}

.cr001-body {
  overflow-y: auto;
}

.cr001-choose-building-content span {
  margin-right: 8px;
  color: #605bff;
  display: block;
  width: 125px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.cr001-subcontract-company-name {
  display: block;
  width: 160px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  text-align: center;
  margin: auto;
}

.cr001-choose-building-content span:hover {
  color: #4943ff;
  text-decoration: underline;
}

.cr001-choose-div {
  width: 20%;
}

.input-width-40 {
  width: 40%;
}

.cr001-external-header,
.cr001-external-item {
  display: flex;
  padding: 16px 0;
  padding-left: 16px;
}

.cr001-external-header {
  background-color: #f0f0f0;
  color: #666666;
  font-weight: 700;
}
.cr001-external-header-left {
  padding-left: 96px;
}

.cr001-external-header-left,
.cr001-external-item-left {
  width: 55%;
}
.cr001-external .ant-radio-group {
  width: 100%;
}

.cr001-external-item:nth-child(2n) {
  background-color: #fafafa;
}

.cr001-external-item-left {
  padding-left: 40px;
}

.cr001-external-item .ant-radio {
  margin-right: 40px;
}

.cr001-external-body {
  display: flex;
}

.cr001-external-body-left {
  width: 55%;
}

.cr001-header-btn-edit {
  margin: 0 16px;
  border-radius: 6px;
  border-color: #605bff;
  color: #605bff;
}

.cr001-header-btn-ok {
  min-width: 98px;
  border-radius: 6px !important;
  display: flex;
  align-items: center;
  justify-content: center;
}

.cr001-header-btn-ok img {
  margin-right: 8px;
}

.button-basic {
  color: #666666;
  width: 76px;
}

.cr001 .ant-form-item {
  margin: -7px 0;
}

.cr003-item-percent,
.cr003-item-labor-cost-percent {
  display: flex;
  justify-content: center;
}
.cr003-item-labor-cost-percent {
  justify-content: center;
}

.cr001 .cr003-item-labor-cost-percent .ant-form-item {
  margin-right: -13px;
}

.cr003-item-labor-cost-percent .input-cr001 {
  width: 85%;
}

.cr001-contract-amount-panel .cr001-icon-rotate svg {
  transform: rotate(0) !important;
}

.cr001-contract-amount-panel.ant-collapse-item-active .cr001-icon-rotate svg {
  transform: rotate(0) !important;
}

.cr001 .ant-input-number-input {
  font-weight: 400;
  font-size: 14px;
  text-align: right;
}

.cr001-other-total {
  margin-top: 4px;
  border: 1px solid #DAE2EE;
}

.cr001-margin-icon {
  margin-left: -2px;
}

.cr001-cr-item-add {
  padding: 16px 12px;
  cursor: pointer;
  border-bottom: 1px solid #e9edf2;
  display: flex;
  align-items: center;
}

.cr001-cr-item-add span {
  font-weight: 700;
  font-size: 14px;
  color: #605bff;
  margin-left: 10px;
}

.content-header {
  display: flex;
  justify-content: space-between;
}

.content-header-version,
.content-header-version-item {
  display: flex;
  align-items: center;
}

.content-header-version-item-title {
  padding: 0 12px;
  text-align: right;
}

.content-header-version-item-title h3 {
  margin-bottom: 0;
  font-weight: 700;
  font-size: 15px;
  line-height: 22px;
  color: #666666;
}

.content-header-version-item-title span {
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #666666;
  display: flex;
}

.content-header-version-item-title span img {
  margin-left: 4px;
}

h3.color-violet,
span.color-violet {
  color: #605bff;
}

h3.color-red,
span.color-red {
  color: #ff0909;
}

.document-reject {
  position: relative;
  cursor: pointer;
}

.note-reject {
  position: absolute;
  min-width: 200px;
  min-height: 50px;
  background-color: #fff;
  box-shadow: 0px 5px 30px 2px rgba(0, 0, 0, 0.1);
  filter: drop-shadow(2px 4px 20px rgba(0, 0, 0, 0.1));
  border-radius: 6px;
  top: calc(100% + 10px);
  right: calc(100% - 38px);
  padding: 12px;
  text-align: left;
  display: none;
}
.note-reject::after {
  position: absolute;
  content: "";
  width: 0px;
  height: 0px;
  border-top: 15px solid transparent;
  border-right: 15px solid transparent;
  border-bottom: 15px solid #fff;
  border-left: 15px solid transparent;
  bottom: calc(100% - 4px);
  right: 10px;
}

.document-reject:hover .note-reject {
  display: block;
}

.cr-text-header-collapse .ant-collapse-header-text {
  font-weight: 600;
  font-size: 16px;
}

.cr001-scroll {
  min-width: 100%;
  width: fit-content;
}

.cr001-hidden-child {
  overflow-x: auto;
  overflow-y: unset;
}

.cr001-subcontractor-child {
  min-width: 190%;
}

.range-text-error .ant-form-item-explain-error {
  padding-left: 6px;
}
.date-text-error .ant-form-item-explain-error {
  padding-left: 38px;
}

.bg-header-row {
  color: #222222;
}

.w-p-5 {
  width: 5%;
}

.w-p-10 {
  width: 10%;
}
.w-p-6 {
  width: 6%;
}

.w-p-20 {
  width: 24%;
}

.w-p-9 {
  width: 8.75%;
}

.color-text div {
  color: #222222;
}
div.color-gray,
.color-gray span {
  color: #666666;
}
.fw-700 {
  font-weight: 700;
}

.cr-construction-name {
  padding: 0 12px;
}

.cr-construction-name .input-cr001 {
  max-width: 100%;
}
.cr-construction-name .ant-form-item-explain-error {
  padding-left: 0;
}

.cr001-percent-text {
  margin-left: 8px;
}

.cr-text-long-tooltip {
  width: 80%;
  margin: auto;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: block;
}

.icon-disable:hover .cr001-choose-building-content,
.icon-disable:hover .cr001-choose-building-content span {
  cursor: not-allowed !important;
  text-decoration: none;
}

.icon-disable {
  cursor: not-allowed;
}

.text-cr-bold {
  font-size: 16px;
  font-weight: 700;
  color: #222222;
}

@media (max-width: 1300px) {
  .cr003-date-picker {
    width: 90% !important;
  }
}

.cr003-select-member {
  text-align: left;
}

.cr003-select-member .ant-select-selection-item div {
  font-weight: 400 !important;
  font-size: 14px;
  color: #222 !important;
}

.spin {
  max-height: 60vh !important;
}

span.color-purple {
  color: #4943ff;
}

.border-error {
  border: 1px solid red;
}

.file-text{
  font-size: 14;
  font-weight: 700;
  color: #605BFF !important;
}