.ord--001__wrapper {
    display: flex;
    flex-direction: column;
    row-gap: 32px;

    width: 100%;
    height: auto;
}

.ord--001__form {
    display: flex;
    flex-direction: column;
    row-gap: 32px;
}
