.db002-table {
    max-width: 100%;
    overflow: auto;
    position: relative;
    max-height: 60vh;
    border: 1px solid #dae2ee;
    border-radius: 3px;
    scroll-behavior: smooth;
}

.db002-table table {
    width: max-content;
    min-width: 100%;
    border-radius: 3px;
}

.db002-table table tr th {
    text-align: center;
    padding: 15px;
}

.db002-table table tfoot tr td {
    border-left: none !important;
}

.db002-table table tr th:first-child,
.db002-table table tr td:first-child {
    border-left: none;
}

.db002-icon {
    cursor: pointer;
}

.db002-table table tr.bg-ff td {
    border: none;
    background-color: #FFFFFF;
    color: #222222;
    font-weight: 400;
    font-size: 14px;
}

.db002-table table tr.bg-fa td {
    border: none;
    background-color: #FAFAFA;
    color: #222222;
    font-weight: 400;
    font-size: 14px;
}

.db002-table table tr.bg-f0 td {
    border: none;
    background-color: #F0F0F0;
    color: #222222;
    font-weight: 700;
    font-size: 14px;
}

.db002-table table tr th {
    background-color: #E7E6FF;
    font-weight: 700;
    font-size: 14px;
    color: #666666;
}

.db002-table table thead tr.row-title  {
    border:none
}

.db002-table table tr td {
    background-color: #fff;
    font-weight: 400;
    font-size: 14px;
    color: #222222;
}

.db002-table table tr td.text-red {
    font-size: 14px;
    color: #FF0909;
}

.db002-table table tr td.text-blue {
    font-size: 14px;
    color: #0050AE;
}

.db002-table table tr.bg-fa td {
    background-color: #fafafa;
}

.db002-table table tr.table-foot td {
    border: none;
    background-color: #605bff;
    color: #ffffff;
    font-weight: 700;
    font-size: 14px;
}

.db002-table table thead {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 99;
}

.db002-table table tr th,
.db002-table table tr td {
    text-align: center;
    padding: 15px;
    min-width: 150px;
}

.db002-table table {
    border-collapse: separate; /* Don't collapse */
    border-spacing: 0;
}

.db002-table table tfoot {
    position: -webkit-sticky;
    position: sticky;
    bottom: 0;
    z-index: 99;
}
.db002-table table tr.tr-border-first th:first-child {
    border-left: 1px solid #dae2ee;
}

.db002-table table .cm0024-td-long-text {
    display: block;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 200px;
}

.db002-table table tr th.border-left-none {
    border-left: none !important;
}