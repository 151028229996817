.main-container {
  box-shadow: 8px 8px 8px rgba(153, 153, 153, 0.02) !important;
  margin-top: 32px !important;
  background-color: #ffffff !important;
  min-height: 86vh !important;
  display: flex;
  flex-direction: row;
}
.company-item-container p {
  margin: 0px;
}
.company-item-container {
  border-radius: 3px;
  width: 100%;
  min-height: 64px;
  display: flex;
  align-items: center;
  padding: 0px 15px 0px 15px;
  background: #fcfcfc;
  box-shadow: inset 0px -1px 0px #dae2ee;
  cursor: pointer;
  padding-left: 16px;
  padding-right: 16px;
  word-break: break-word;
}
thead tr td.ant-table-cell-fix-right-last {
  background-color: #f0f0f0 !important;
}
.company-pagination-container {
  box-shadow: inset 0px -1px 0px #dae2ee;
  padding: 28px 16px;
}
.company-item-text {
  font-weight: 400;
  font-size: 14px;
  color: #666666;
}

.company-item-avt {
  min-width: 32px;
  height: 32px;
  /* Primary Color/Active */
  font-weight: 600;
  font-size: 16px;
  background: #e7e6ff;
  border-radius: 999px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.company-item-avt-text {
  font-weight: 600;
  font-size: 16px;
  color: #666666;
}
.company-item-avt-text-2 {
  font-weight: 600;
  font-size: 50px;
  color: #666666;
}
.chosen .company-item-avt-text {
  font-weight: 600;
  font-size: 16px;
  color: #ffffff;
}

.chosen .company-item-avt {
  color: #ffffff;
  background: linear-gradient(270deg, #2ec5c1 0%, #119cd3 100%);
}

.chosen .company-item-text {
  font-weight: 700;
  font-size: 14px;
  color: #ffffff;
}

.company-item-text-chosen {
  font-weight: 700;
  font-size: 14px;
  color: #ffffff;
}

.chosen {
  background: #605bff !important;
  box-shadow: inset 2px 0px 12px #3833d6 !important;
}

.company-container {
  height: 100%;
  border-right: 1px solid #dae2ee;
  position: relative;
  box-shadow: -2px 0px 12px rgba(83, 83, 83, 0.05) !important;
  background: #fcfcfc !important;
  padding-top: 24px;
}

.collapsed-button-open {
  cursor: pointer;
  background: #e7eaee;
  border-radius: 3px 0px 0px 3px;
  transform: rotate(-180deg);
  height: 24px;
  width: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.collapsed-button-close {
  cursor: pointer;
  background: #e7eaee;
  border-radius: 0px 3px 3px 0px;
  transform: rotate(-180deg);
  height: 24px;
  width: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.searchbar-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.pl-16 {
  padding-left: 16px;
}

.company-filter {
  font-weight: 700;
  font-size: 18px;
  color: #222222;
}

.pt-24 {
  padding-top: 24px;
}
.pb-16 {
  padding-bottom: 16px;
}

.filter-label {
  margin-bottom: 8px;
  font-weight: 400;
  font-size: 14px;
  color: #666666;
}

.open-side .ant-layout-sider {
  flex: none !important;
  max-width: 242px !important;
  width: 242px !important;
  background: #ffffff !important;
}
/* Menu Sider */
.open-side .ant-layout-sider-children {
  max-width: 242px !important;
  width: 242px !important;
  background: #ffffff !important;
}

.open-side .ant-layout-sider-children {
  background-color: #ffffff !important;
}

.open-side .ant-layout-sider-dark {
  background-color: #ffffff !important;
}

.close-side .ant-layout-sider {
  flex: none !important;
  max-width: 64px !important;
  min-width: 64px !important;
  width: 64px !important;
  background: #ffffff !important;
}
/* Menu Sider */
.close-side .ant-layout-sider-children {
  max-width: 64px !important;
  width: 64px !important;
  min-width: 64px !important;

  background: #ffffff !important;
  margin: 0px !important;
}

.close-side .ant-layout-sider-children {
  background-color: #ffffff !important;
}

.close-side .ant-layout-sider-dark {
  background-color: #ffffff !important;
}

.total-money-style {
  font-weight: 600;
  font-size: 14px;
  color: #605bff;
}

.total-money {
  font-weight: 600;
  font-size: 14px;
  color: #605bff;
  margin-top: 24px;
  margin-left: 24px;
  margin-bottom: 0px;
}

.image-no-data-table {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
}
.no-border-table .ant-table-container,
.no-border-table .ant-table,
.no-border-table .ant-table-thead > tr > th,
.no-border-table .ant-table-tbody > tr > td {
  border-bottom: none !important;
}

.no-border-table .ant-table-summary {
  border-bottom: none !important;
}
.am-main-table
  .ant-table-expanded-row
  .ant-table-expanded-row-level-1
  .ant-table-expanded-row-fixed {
  background: #ffffff !important;
}

.am-main-table .ant-table-expanded-row-fixed {
  background: #ffffff !important;
}

.am-main-table .ant-table-content {
  overflow: hidden !important;
}

.am-main-table .ant-table-cell {
  text-align: left !important;
}
.am-main-table .ant-table-column-sorter-full {
  margin-left: 16px !important ;
}
.am-main-table tr td.ant-table-column-sort {
  background: transparent !important;
}

.am-main-table .ant-table-cell-row-hover {
  background: transparent !important;
}

.am-main-table .ant-table-column-sort .ant-table-row-expand-icon-cell {
  background-color: red !important;
}

.am-main-table .ant-table-row-expand-icon-cell {
  background-color: transparent;
}

.am-main-table .ant-table-expanded-row-fixed {
  padding: 0px !important;
}
.am-main-table .ant-table .ant-table-rtl {
  padding: 0px !important;
  margin: 0px !important;
}

.am-main-table tr td.ant-table-column-sort:hover {
  background: transparent !important;
}

.am-main-table-constractor-member .ant-table-tbody > tr {
  background: #f2f6fc !important;
}
.am-main-table-constractor-member .ant-table-tbody > tr:nth-child(even) {
  background: #f2f6fc !important;
}

.am-main-table-constractor-member .ant-table table {
  border-spacing: 0px 0px !important;
}
.am-main-table-constractor-member .ant-table-tbody > tr > td {
  border-bottom: 1px solid #dae2ee !important;
}

.am-main-table-constractor-member .ant-table-tbody > tr:last-child > td {
  border-bottom: none !important;
}

.am-main-table-construction .ant-table-tbody > tr {
  background: #fafafa !important;
}

.am-main-table-construction tr:nth-child(odd) {
  background-color: #ffffff !important;
}

.am-main-table-construction tr:nth-child(even) {
  background-color: #fafafa !important;
}

.am-main-table
  .ant-table-tbody
  > tr
  > td
  > .ant-table-wrapper:only-child
  .ant-table,
.ant-table-tbody
  > tr
  > td
  > .ant-table-expanded-row-fixed
  > .ant-table-wrapper:only-child
  .ant-table {
  margin: 0px !important;
}

.am-main-table .ant-table-thead > tr > th {
  background: #f5f5f5 !important;
}
.am-main-table-construction
  .ant-table.ant-table-bordered
  > .ant-table-container
  > .ant-table-content
  > table,
.ant-table.ant-table-bordered
  > .ant-table-container
  > .ant-table-header
  > table {
  border-right: 1px solid #f0f0f0 !important;
}

.bold-text-number {
  font-weight: 700;
  font-size: 14px;

  color: #222222;
}

.header-no-padding-top th.ant-table-cell {
  padding-top: 0px !important;
}

.row-member-background .ant-table-row {
  background-color: #dae5f5 !important;
}

.export-button {
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 1px;
}

.reset-filter-btn {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  /* identical to box height, or 157% */

  /* Text/Sub Text */

  color: #666666;
}

.scroll-ant-table-direction div.ant-table-body {
  direction: ltr !important;
}

.scroll-ant-table-direction div.ant-table-body table {
  direction: rtl !important;
}

.scroll-ant-table-direction tr.ant-table-expanded-row > td {
  padding: 0px !important;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.no-scrollbar ::-webkit-scrollbar {
  display: none;
}

.no-table-cell-padding .ant-table-expanded-row td.ant-table-cell {
  /* padding: 0px !important; */
}

tr.ant-table-expanded-row > td,
tr.ant-table-expanded-row:hover > td {
  padding: 0px !important;
}
.double-check-border .ant-table-cell .border-left-table {
  border-right: 1px solid #dae2ee !important;
}

.attendance-table-border .ant-table-tbody > tr > td:first-child {
  border-left: 1px solid #dae2ee !important;
  border-right: 1px solid #dae2ee !important;
}

.double-check-table-border .ant-table-tbody > tr > td:first-child {
  border-left: none !important;
  border-right: 1px solid #dae2ee !important;
}
