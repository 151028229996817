.cm003-filter {
  width: 100%;
  display: flex;
  flex-direction: row;
  column-gap: 91px;
  flex-wrap: wrap; 
  align-items: center;
  align-content: center;
  height: auto;
  padding: 16px 0px;
  background: #fff;
  border-bottom: 1px solid #dae2ee;
}

.cm003-filter .construction {
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  align-content: center;
  column-gap: 24px;
}

.cm003-filter .construction .label {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  text-align: right;
  color: #222222;
  min-width: 70px;
}

.cm003-filter .construction .item {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  /* padding: 9px 12px 9px 16px; */
  gap: 16px;
  width: 375px;
  height: 40px;
  background: #ffffff;
  border: 1px solid #dae2ee;
  box-shadow: 8px 8px 8px rgba(153, 153, 153, 0.02);
  border-radius: 3px;
}

.cm003-filter .construction .item .ant-select-selector {
  border: none !important;
}

.cm003-filter .construction .ant-form-item {
  margin-bottom: 0px !important;
}

.cm003-filter .payment-date {
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
  align-content: center;
  column-gap: 24px;
  justify-content: end;
}

.cm003-filter .payment-date .label {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  text-align: right;
  color: #222222;
  min-width: 70px;
}

.cm003-filter .payment-date .item {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  width: 375px;
  height: 40px;
  background: #ffffff;
  border: 1px solid #dae2ee;
  box-shadow: 8px 8px 8px rgba(153, 153, 153, 0.02);
  border-radius: 3px;
}

.cm003-filter .payment-date .item .ant-select-selector {
  border: none !important;
}

.cm003-filter .payment-date .ant-form-item {
  margin-bottom: 0px !important;
}

.cm003-filter .action {
  flex: 1;
  display: flex;
  flex-direction: row;
  align-content: flex-end;
  column-gap: 16px;
  justify-content: flex-end;
  padding: 8px 0px;
}

.cm003-filter .action .approve {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  /* padding: 8px 24px; */
  gap: 8px;
  width: 90px;
  height: 40px;
  background: #dae2ee;
  border: 1px solid #605bff;
  box-shadow: 4px 8px 24px 2px rgba(133, 133, 133, 0.08);
  border-radius: 3px;

  cursor: pointer;

  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 22px;
  color: #605bff;
}

.cm003-filter .action .clear {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 10px;
  width: 90px;
  height: 40px;
  background: #ffffff;
  border: 1px solid #dae2ee;
  border-radius: 3px;
  cursor: pointer;

  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: #666666;
}

.cm003-content {
  display: flex;
  flex-direction: column;
}

.cm003-content .cm003-btn-export {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  height: 88px;
  width: 100%;
}

.cm003-content .cm003-btn-export .btn {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 8px;

  width: 90px;
  height: 40px;
  right: 24px;
  top: 254px;
  background: #605bff;
  border-radius: 3px;

  cursor: pointer;
}

.cm003-content .cm003-btn-export .btn span {
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 22px;
  text-align: right;
  color: #ffffff;
}

.cm003-content .cm003-table {
  display: flex;
  flex-direction: column;

  border-width: 1px 1px 1px 1px;
  border-style: solid;
  border-color: #dae2ee;
  border-top-right-radius: 3px;
  border-top-left-radius: 3px;
}

.cm003-content .cm003-table .title {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;

  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #222222;

  width: 100%;
  height: 52px;
  padding-left: 50px;

  background: #f0f0f0;
  /* box-shadow: inset 0px -1px 0px #dae2ee; */
}

.cm003-content .cm003-table .description {
  display: flex;
  flex-direction: column;
  row-gap: 8px;
}

.cm003-content .cm003-table .description .header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.cm003-content .cm003-table .description .header .item {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 52px;
  background: #fafafa;
  border-right: 1px solid #dae2ee;
  border-top: 1px solid #dae2ee;

  /*Text*/

  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: #222222;
}

.cm003-content .cm003-table .description .header .border-right {
  border-right: none !important;
}

.cm003-content .cm003-table .description .content {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.cm003-content .cm003-table .description .content .item {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 52px;
  background: #fff;
  border-right: 1px solid #dae2ee;
  border-top: 1px solid #dae2ee;
  border-bottom: 1px solid #dae2ee;

  /*Text*/

  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: #222222;
}

.border-right-none {
  border-right: none !important;
}

.w260 {
  width: 260px !important;
}

.w300 {
  width: 100% !important;
}

.cm003-content .cm003-table .description .tb-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.cm003-content .cm003-table .description .tb-header .item {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 52px;
  background: #fafafa;
  border-right: 1px solid #dae2ee;
  border-top: 1px solid #dae2ee;

  /*Text*/

  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: #666666;
}

.tb-content .ant-collapse-content-box {
  padding: 0px !important;
}

.tb-content .ant-collapse-header-text {
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 22px;
  /* identical to box height, or 157% */

  /* Text/Main */

  color: #222222;
}

.green {
  color: #0fa44a !important;
}

.fz-12 {
  font-size: 12px !important;
}

.cm003-content .cm003-table .tb-content {
  display: flex;
  flex-direction: column;
}

.cm003-content .cm003-table .tb-content .tb-footer {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.cm003-content .cm003-table .description .tb-footer .item {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 52px;
  background: #605bff;

  /*Text*/

  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 22px;
  color: #fff;
}

.tb-content ::-webkit-scrollbar {
  width: 12px !important;
}

.tb-content .ant-collapse {
  border: none !important;
}

.cm003-note {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding-bottom: 8px;

  /*Text*/

  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: #666666;
}

/* .active-border {
    border: 1px solid #605bff;
} */

.fw700 {
  font-weight: 700 !important;
}

.tb-content .ant-collapse-item-active {
  border: 1px solid #605bff !important;
}
