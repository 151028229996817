.am014-data{
    display: flex;
    flex-direction: column;
    padding-left: 22px;
}

.am014-data .row{
    display: flex;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    margin-bottom: 16px;
}

.am014-data .row:last-child{
    margin-bottom: 0px;
}

.am014-data .row .title{
    display: flex;
    justify-content: right;
    color:#666666;
    margin-right: 24px;
    min-width: 70px;
}

.am014-data .row .description{
    color: #222222;
    overflow: hidden;
}

.am014-data .row .description_first-child{
    color: #FF5555
}
