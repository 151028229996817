.am016-data {
  display: flex;
  flex-direction: column;
  margin-bottom: 96px;
  padding-left: 22px;
}

.am016-data .row {
  display: flex;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  margin-bottom: 16px;
}

.am016-data .row:last-child {
  margin-bottom: 0;
}

.am016-data .row .title {
  display: flex;
  justify-content: right;
  color: #666666;
  margin-right: 24px;
  min-width: 70px;
}

.am016-data .row .description {
  color: #222222;
  width: 90%;
  word-wrap: break-word;
}

.am016-data .row .description_approve {
  color: #33cc99;
}

.am016-data .row .description_waiting_approve {
  color: #ff9861;
}

.group-detail-modal .ant-modal-content .header-modal-containter {
  border-bottom: 1px solid #dae2ee !important;
}
