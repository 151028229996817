.title-cm {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #222222;
}

.description-title {
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 22px;
  /* identical to box height, or 157% */

  /* Text/Sub Text */

  color: #666666;
}

.description-content {
  /* PC/Text/Primary */

  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  /* identical to box height, or 157% */

  /* Text/Main */

  color: #222222;
}

.cm007-table .ant-table-row {
  background: #dae2ee !important;
}
.cell-cm007-style {
  font-style: normal !important;
  font-weight: 700 !important;
  font-size: 14px !important;
  line-height: 22px !important;

  color: #222222 !important;
}
.white-space-nowrap {
  white-space: nowrap;
}

.collapsed-table-header .ant-table-thead > tr > th {
  background: #fafafa !important;
}

.collapsed-table-header .ant-table-row {
  background: #ffffff !important;
}

.collapsed-table-row-style {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: #222222;
}

.font-cell-editing {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: rgba(34, 34, 34, 0.4);
}

.add-row-style {
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  /* identical to box height, or 157% */

  /* Primary Color/Logo Brand */
  text-align: left !important;
  color: #605bff;
}

.cm-main-table .ant-table-thead > tr > th {
  text-align: center !important;
}

.cm-main-table .ant-table-tbody > tr > td {
  text-align: center !important;
}

.cm-main-table tr.ant-table-expanded-row > td {
  padding: 0px !important;
}

.row-no-padding td.ant-table-cell {
  padding-top: 1px !important;
  padding-bottom: 1px !important;
  padding-right: 14px !important;
  padding-left: 14px !important;
}
.table-footer .ant-table-footer .ant-table-thead > tr > th {
  background: #605bff !important;
}
.table-footer .ant-table-footer {
  padding: 0px !important;
}
.table-footer-inside .ant-table-footer .ant-table-thead > tr > th {
  background: #fafafa !important;
}
.table-footer-inside .ant-table-footer {
  padding: 0px !important;
}
.summary-line td.ant-table-cell {
  display: none !important;
}

.summary-font {
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  /* identical to box height, or 157% */
  /* Text/Main */
  color: #222222;
}

.cm007-table .ant-table-container table > thead > tr th {
  border-right: 1px solid #dae2ee !important;
}

.cm007-table-summary .ant-table-container table > thead > tr th {
  border-right: 1px solid transparent !important;
}

.parent-table-summary {
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 22px;
  /* identical to box height, or 157% */

  /* Text/White */
  text-align: center;
  color: #ffffff;
}

.text-center {
  text-align: center;
  align-content: center;
}

.children-table-summary {
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 22px;
  color: #222222;
}

.cm007-datepicker-font input {
  color: #605bff !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 14px !important;
  line-height: 22px !important;
}

.cm007-datepicker-font svg {
  color: #605bff !important;
}

.td-threshold {
  display: flex;
  justify-content: center;
}

.border-rignt-none {
  border-right: none;
}

.approve-color {
  filter: invert(39%) sepia(27%) saturate(4459%) hue-rotate(123deg)
    brightness(96%) contrast(88%);
}

.not-approve-color {
  filter: invert(19%) sepia(28%) saturate(5149%) hue-rotate(229deg)
    brightness(101%) contrast(103%);
}

.cm-main-table .ant-table-cell {
  text-align: left !important;
}
.cm-main-table .ant-table-column-sorter-full {
  margin-left: 16px !important ;
}
.cm-main-table tr td.ant-table-column-sort {
  background: transparent !important;
}

.cm-main-table .ant-table-cell-row-hover {
  background: transparent !important;
}

.cm-main-table .ant-table-column-sort .ant-table-row-expand-icon-cell {
  background-color: red !important;
}

.cm-main-table .ant-table-row-expand-icon-cell {
  background-color: transparent;
}

.cm-main-table .ant-table-expanded-row-fixed {
  padding: 0px !important;
}
.cm-main-table .ant-table .ant-table-rtl {
  padding: 0px !important;
  margin: 0px !important;
}

.cm-main-table tr td.ant-table-column-sort:hover {
  background: transparent !important;
}

.cm-main-table-constractor-member .ant-table-tbody > tr {
  background: #f2f6fc !important;
}
.cm-main-table-constractor-member .ant-table-tbody > tr:nth-child(even) {
  background: #f2f6fc !important;
}

.cm-main-table-constractor-member .ant-table table {
  border-spacing: 0px 0px !important;
}
.cm-main-table-constractor-member .ant-table-tbody > tr > td {
  border-bottom: 1px solid #dae2ee !important;
}

.cm-main-table-construction .ant-table-tbody > tr {
  background: #fafafa !important;
}

.cm-main-table-construction tr:nth-child(odd) {
  background-color: #ffffff !important;
}

.cm-main-table-construction tr:nth-child(even) {
  background-color: #fafafa !important;
}

.cm-main-table
  .ant-table-tbody
  > tr
  > td
  > .ant-table-wrapper:only-child
  .ant-table,
.ant-table-tbody
  > tr
  > td
  > .ant-table-expanded-row-fixed
  > .ant-table-wrapper:only-child
  .ant-table {
  margin: 0px !important;
}

.cm-main-table .ant-table-thead > tr > th {
  background: #f5f5f5 !important;
}
.cm-main-table-construction
  .ant-table.ant-table-bordered
  > .ant-table-container
  > .ant-table-content
  > table,
.ant-table.ant-table-bordered
  > .ant-table-container
  > .ant-table-header
  > table {
  border-right: 1px solid #f0f0f0 !important;
}

.cm-save {
  margin-right: 8px;
  letter-spacing: -1.5px;
  width: 60px;
  height: 30px;
}

.cm-cancel {
  width: 102px;
  height: 30px;
}
