.open-side-ccus06 .ant-layout-sider-children {
    max-width: 264px !important;
    width: 264px !important;
    background: #ffffff !important;
    border-right: 1px solid #DAE2EE !important;
  }
  
  .open-side-ccus06 .ant-layout-sider-children {
    background-color: #ffffff !important;
  }
  
  .open-side-ccus06 .ant-layout-sider-dark {
    background-color: #ffffff !important;
  }
  .open-side-ccus06 .ant-layout-sider-children {
    max-width: 264px !important;
    width: 264px !important;
  }
  
  .open-side-ccus06 .ant-layout-sider {
    flex: none !important;
    max-width: 264px !important;
    width: 264px !important;
  }
  .container-tab-ccus06{
    width: 100%;
    background: #fff;
    padding: 0px 24px 0 24px;
    margin-top: 17px;
}
.ccus-pr-24{
    padding-right: 24px;
}
.btn-filter-ccus06 {
    width: 119px;
}
.text-title-filter-ccus06 {
  font-weight: 700;
  font-size: 18px;
   color: #222222;
  margin-top: 14px;
}
.content-table-ccus06 {
  margin: 24px 0 16px 24px;
  background-color: #ffffff;
}
.header-infor-ccus062{
  width: 100%;
  height: 116px;
  display: flex;
  justify-content: space-between;
  background-color: #F4F7FA;
  margin-bottom: 14px;
  padding: 20px 24px 10px 24px;
}
.left-header-ccus062{
  display: flex;
  gap:20px
}
.title-left-content{
  font-size: 14px;
  font-weight: 700;
  line-height: 22px;
}
.text-content{
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
}

.btn-container-ccus062{
  display: flex;
  align-items: center;
  height: 100%;
}
.btn-submit-ccus062{
    width: 104px;
    height: 40px;
    border-radius: 3px !important;
}
.table-ccus061-container{
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.record-state-table{
  display: flex;
  gap:8px;
  align-items: center;
}
.text-state-success{
  font-size: 14px;
  font-weight: 700;
  line-height: 22px;
  color: #0D9E2DCC;
}
.text-state-error{
  font-size: 14px;
  font-weight: 700;
  line-height: 22px;
  color: #FF0909;
}
.input-ccus062-container{
  margin: 0;
  min-height: 40px;
 
}
.table-ccus062 .ant-table-tbody > tr > td {
  vertical-align: top; 
  padding: 4px 8px; 
}
.input-ccus062-container .ant-form-item {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: start;
  white-space: normal;
  word-break: break-word;
  overflow-wrap: break-word;
  height: auto;
}

.input-ccus062-container .ant-form-item-explain {
  white-space: normal; 
  word-break: break-word; 
}

.table-ccus062 .ant-table .ant-table-tbody .ant-table-selection-column {
  padding-top: 20px !important; 
}

.input-ccus062 {
  height: 40px;
  border-radius: 3px !important;
  border-color: #DAE2EE !important;
  
}

.title-ccus062{
  display: flex;
  gap:8px;
  align-items: center;
}

.container-popup-ccus061 {
  display: flex;
  flex: 1;
  padding-top: 24px;
}

.title-popup-ccus061 {
  text-align: left;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  color: #222222;
  padding-bottom: 12px;
  padding-top: 12px;
}

.text-popup-ccus061 {
  text-align: left;
  font-size: 14px;
  font-weight: 400;
  line-height: 30px;
  color: #666666;
}

.contain-content-popup-ccus061 {
  display: flex;
  background-color: #F4F7FA;
  border-radius: 6px;
  box-sizing: border-box;
  padding: 8px;
}

.info_row {
  display: flex;
}

.row-flex-1 {
  flex: 1;
}

.info-row-title {
  text-align: right;
  font-size: 14px;
  font-weight: 400;
  line-height: 30px;
  color: #666666;
}

.info-row-value {
  padding-left: 16px;
  font-size: 14px;
  font-weight: 400;
  line-height: 30px;
  color: #222222;
}

.info-column {
  flex: 1;
  padding-right: 8px;
}

.empty-data-container{
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

/* Modal confirm */

.confirm__modal-ccus .ant-modal-confirm-btns {
  display: flex;
  flex-direction: row-reverse;
}

.confirm__modal-ccus .ant-btn-primary {
  min-width: 76px !important;
  height: 40px !important;
  background: #605bff;
  color: #fff;
  box-shadow: 4px 8px 24px 2px rgba(133, 133, 133, 0.08);
  border-radius: 5px;
  border: none;
  outline: none;
}

.confirm__modal-ccus .ant-btn-default {
  min-width: 118px !important;
  height: 40px !important;
  color: #666666;
  box-shadow: 0px 0px 20px rgba(22, 70, 157, 0.1);
  border-radius: 3px;
  border: 1px solid var(--border-line, #dae2ee);
  background: #fff;
  margin-left: 16px;
}

.confirm__modal-ccus .ant-modal-body {
  padding: 24px !important;
}
.confirm__modal-ccus .ant-modal-content {
  border-radius: 8px !important;
}
.confirm__modal-ccus .ant-modal-confirm-btns {
  margin-top: 0px !important;
}

.title-modal-confirm-ccus-container{
  display: flex;
  gap:12px;
  margin-bottom: 16px;
}

.title-modal-confirm-ccus{
  font-size: 18px;
  font-weight: 700;
  line-height: 26px;
  text-align: center;
  color: #222222;
}
.text-content-modal-confirm-ccus{
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  text-align: left;
}


/* Modal Error */

.error__modal-ccus .ant-modal-confirm-btns {
  display: flex;
  flex-direction: row-reverse;
}

.error__modal-ccus .ant-btn-primary {
  min-width: 76px !important;
  height: 40px !important;
  background: #605bff;
  color: #fff;
  box-shadow: 4px 8px 24px 2px rgba(133, 133, 133, 0.08);
  border-radius: 5px;
  border: none;
  outline: none;
}

.error__modal-ccus .ant-btn-primary {
  width: 240px !important;
  height: 40px !important;
  color: #666666;
  box-shadow: 0px 0px 20px rgba(22, 70, 157, 0.1);
  border-radius: 3px;
  border: 1px solid var(--border-line, #dae2ee);
  background: #fff;
  text-align: center !important;
  font-weight: 400 !important;
}

.error__modal-ccus .ant-modal-body {
  padding: 24px !important;
}
.error__modal-ccus .ant-modal-content {
  border-radius: 8px !important;
}
.error__modal-ccus .ant-modal-confirm-btns {
  margin-top: 0px !important;
}
.row-infor-tab-ccus062{
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: fit-content;
  width: 100%;
  margin-bottom: 20px;
  margin-top: 24px;
  background-color: #fff !important;
}