.confirm__modal .ant-modal-content {
  border-radius: 20px;
}
.confirm__modal .ant-modal-confirm-body {
  text-align: center;
  margin-bottom: 30px;
  margin-top: 20px;
}

.confirm__modal .ant-modal-confirm-btns {
  margin-bottom: 40px;
  display: flex;
  flex-direction: row-reverse;
  padding-right: 30%;
  padding-left: 20%;
}

.confirm__modal .ant-btn {
  min-width: 58px !important;
  height: 40px !important;
  background: #fff;
  color: #9897ad;
  box-shadow: 0px 0px 20px rgba(22, 70, 157, 0.1);
  border-radius: 5px;
  border: 1px solid #9897ad;
  outline: none;
  margin-left: 16px;
}

.confirm__modal .ant-btn-dangerous {
  min-width: 58px !important;
  height: 40px !important;
  background: #eb4d4d;
  color: #fff;
  box-shadow: 0px 0px 20px rgba(22, 70, 157, 0.1);
  border-radius: 5px;
  border: none;
  outline: none;
}

.confirm__modal-purple-oke .ant-btn-dangerous {
  min-width: 58px !important;
  height: 40px !important;
  background: #605bff !important;
  color: #fff;
  box-shadow: 0px 0px 20px rgba(22, 70, 157, 0.1);
  border-radius: 5px;
  border: none;
  outline: none;
}

.confirm__modal .ant-modal-confirm-title {
  font-weight: 600;
  font-size: 24px;
  color: #343434;
  margin-bottom: 24px !important;
}

.confirm__modal .ant-modal-confirm-content {
  font-weight: 600;
  font-size: 16px;
  color: #666666;
  margin-bottom: 48px !important;
}

.confirm__modal.modal__success .ant-modal-confirm-btns {
  justify-content: center;
  display: flex;
}

.confirm__modal .ant-modal-confirm-btns span {
  letter-spacing: -1px !important;
}

/*AOP*/
.confirm__modal-aop .ant-modal-confirm-btns {
  display: flex;
  flex-direction: row-reverse;
}

.confirm__modal-aop .ant-btn-primary {
  min-width: 76px !important;
  height: 40px !important;
  background: #605bff;
  color: #fff;
  box-shadow: 4px 8px 24px 2px rgba(133, 133, 133, 0.08);
  border-radius: 5px;
  border: none;
  outline: none;
}

.confirm__modal-aop .ant-btn-default {
  min-width: 118px !important;
  height: 40px !important;
  color: #666666;
  box-shadow: 0px 0px 20px rgba(22, 70, 157, 0.1);
  border-radius: 3px;
  border: 1px solid var(--border-line, #dae2ee);
  background: #fff;
  margin-left: 16px;
}

.confirm__modal-aop .ant-modal-body {
  padding: 18px 32px 0px !important;
  height: 190px !important;
}

.confirm__modal-aop .ant-modal-confirm-btns {
  margin-top: 0px !important;
}

/*CR001*/
.confirm__modal-CR001 .ant-modal-confirm-btns {
  display: flex;
  flex-direction: row-reverse;
}

.confirm__modal-CR001 .ant-btn-primary {
  min-width: 76px !important;
  height: 40px !important;
  background: #605bff;
  color: #fff;
  box-shadow: 4px 8px 24px 2px rgba(133, 133, 133, 0.08);
  border-radius: 5px;
  border: none;
  outline: none;
}

.confirm__modal-CR001 .ant-btn-default {
  min-width: 118px !important;
  height: 40px !important;
  color: #666666;
  box-shadow: 0px 0px 20px rgba(22, 70, 157, 0.1);
  border-radius: 3px;
  border: 1px solid var(--border-line, #dae2ee);
  background: #fff;
  margin-left: 16px;
}

.confirm__modal-CR001 .ant-modal-body {
  padding: 0px !important;
}

.confirm__modal-CR001 .ant-modal-confirm-btns {
  margin-top: 0px !important;
  padding-right: 24px !important;
  margin-bottom: 26px !important;
}
.confirm__modal-CR001 .ant-modal-confirm-body .ant-modal-confirm-content {
  margin: 0px !important;
}

.confirm__modal-CR001 .text {
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
  color: #666666;
}
.ccus__confirm__modal .ant-modal-content {
  border-radius: 20px;
}
.ccus__confirm__modal .ant-modal-confirm-body {
  text-align: center;
  margin-bottom: 30px;
  margin-top: 10px;
}

.ccus__confirm__modal .ant-modal-confirm-btns {
  margin-bottom: 10px;
  display: flex;
  flex-direction: row-reverse;
  padding-right: 30%;
  padding-left: 20%;
}

.ccus__confirm__modal .ant-btn {
  min-width: 58px !important;
  height: 40px !important;
  background: #fff;
  color: #9897ad;
  box-shadow: 0px 0px 20px rgba(22, 70, 157, 0.1);
  border-radius: 5px;
  border: 1px solid #9897ad;
  outline: none;
  margin-left: 16px;
}

.ccus__confirm__modal-purple-oke .ant-btn-dangerous {
  min-width: 58px !important;
  height: 40px !important;
  background: #605bff !important;
  color: #fff;
  box-shadow: 0px 0px 20px rgba(22, 70, 157, 0.1);
  border-radius: 5px;
  border: none;
  outline: none;
}

.ccus__confirm__modal .ant-modal-confirm-title {
  font-weight: 700;
  font-size: 18px;
  color: #222222;
  margin-bottom: 24px !important;
}
