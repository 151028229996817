.crt001 {
  background-color: #fff;
  width: 100%;
  box-shadow: 8px 8px 8px rgba(153, 153, 153, 0.02);
  border-radius: 3px;
  padding: 24px;
}

.crt001-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.crt001-header-left {
  display: flex;
  align-items: center;
}

.crt001-header-left span {
  color: #222222;
  font-weight: 500;
  font-size: 14px;
  margin-left: 12px;
}

.crt001-header-right {
  display: flex;
  align-items: center;
}

.crt001-header-right-item {
  display: flex;
  margin-right: 26px;
}

.crt001-header-right-item-label {
  color: #666666;
}

.margin-right-16 {
  margin-right: 16px;
}

.seperate {
  width: 1px;
  background-color: #999999;
  height: 18px;
  border-radius: 2px;
  margin-right: 32px;
}

.crt001-header-right-item-text {
  color: #222222;
  font-weight: 700;
  font-size: 14px;
  margin-left: 8px;
}

.crt001-collapse-item {
  width: 96% !important;
  margin-left: 50px;
  position: relative;
}

.crt001-collapse-item::before {
  content: "";
  position: absolute;
  width: 22px;
  height: 1px;
  background-color: #dedede;
  top: 50%;
  right: calc(100% + 10px);
}
.crt001-collapse-item::after {
  content: "";
  position: absolute;
  width: 1px;
  height: 150%;
  background-color: #dedede;
  top: -58px;
  right: calc(100% + 32px);
}

.crt001-collapse-item:first-child:after {
  height: calc(100%);
  top: -26px;
}

.crt-item-other {
  margin-left: 51px;
  margin-top: 25px;
  position: relative;
}

.crt-item-other::before {
  content: "";
  position: absolute;
  width: 22px;
  height: 1px;
  background-color: #dedede;
  top: 13px;
  right: calc(100% + 10px);
}

.crt-item-other::after {
  content: "";
  position: absolute;
  width: 1px;
  height: 73px;
  background-color: #dedede;
  top: -58px;
  right: calc(100% + 32px);
}

.crt-item-other-not-child::after {
  height: 41px;
  top: -27px;
}

.crt-item-other-label,
.crt-item-other-number {
  font-weight: 700;
  font-size: 14px;
  color: #605bff;
  margin-left: 8px;
}

.crt001-basic-info {
  background-color: #f8f8f8;
  padding: 32px;
  border-radius: 6px;
}

.crt001-basic-info-title {
  font-weight: 700;
  font-size: 18px;
  color: #222222;
}

.crt-collapse-padding .ant-collapse-header-text {
  padding-left: 10px;
}

.crt-basic-child {
  background-color: #fff;
  border: 1px solid #dedede;
  border-top: none;
  padding: 16px;
  padding-top: 1px;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
}

.crt-bg-main .ant-collapse-header {
  background-color: #e3e3e3;
}

.crt-bg-main .ant-collapse-content-box {
  background-color: #ededed;
}

.crt-bt-child .ant-collapse-header {
  background-color: #ededed;
}

.crt001-basic-list {
  display: flex;
  margin: 0;
}

.crt001-basic-item {
  padding-right: 44px;
  color: #666666;
  font-weight: 400;
  font-size: 14px;
}

.crt001-basic-item span {
  font-weight: 700;
  font-size: 14px;
  color: #222222;
}

.crt-8 {
  width: 12.5%;
}

.crt-8-9 {
  width: 10.9375%;
}

.crt-7 {
  width: 14.2857%;
}

.crt-5 {
  width: 20%;
}
.crt-6 {
  width: 16.6666%;
}
.not-border-right {
  border-right: none;
}

.crt-40 {
  width: 40%;
}

.crt-text-first {
  text-align: left;
  padding-left: 42px;
}

.crt001 .cr001-cost-registration-title {
  padding-left: 42px;
}

.crt001 .ant-collapse-header-text {
  padding-left: 26px;
  margin-right: 20px;
}

.crt001-title-amount {
  color: #222222;
  font-weight: 700;
  font-size: 18px;
  margin-top: 40px;
  margin-bottom: -12px;
}
.crt001 .folder-collapse {
  background-color: #f0f0f0;
}

.folder-collapse-child {
  border: none;
  border-radius: unset !important;
}

.folder-collapse-child.ant-collapse > .ant-collapse-item {
  border-bottom: 1px solid #e9edf2;
}

.folder-collapse-child .ant-collapse-content {
  border-top: 1px solid #e9edf2;
}

.crt-header-panel {
  display: flex;
  align-items: center;
  color: #222222;
  font-weight: 400;
  font-size: 14px;
}

.crt-header-panel img {
  margin-right: 14px;
}

.crt001 .cr001-other-total {
  margin-top: 0;
}
