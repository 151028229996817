.ord006-container {
  width: 100%;
  display: flex;
  background-color: #f4f7fa;
  height: 260px;
  padding-top: 24px;
  padding-left: 48px;
}

.ord006-label {
  width: 108px;
  text-align: "right";
  margin-right: 24px;

  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: right;
  color: #666666;
}

.ord006-content {
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
  color: #222222;
}

.ord006-content-container {
  display: flex;
  margin-bottom: 16;
  width: 100%;
}

.ord006-cell-style {
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
}

.grey-cell {
  color: #222222;
}

.red-cell {
  color: #ff0909;
}

.green-cell {
  color: #0fa44a;
}

/* table for ord */

.ord006-table {
  max-width: 100%;
  overflow: auto;
  position: relative;
  max-height: 60vh;
  border: 1px solid #dae2ee;
  border-radius: 3px;
  scroll-behavior: smooth;
}

.ord006-table table {
  width: max-content;
  min-width: 100%;
  border-radius: 3px;
}

.ord006-table table tr th,
.ord006-table table tr td {
  text-align: center;
  border-bottom: 1px solid #dae2ee;
  border-left: 1px solid #dae2ee !important;
  padding: 15px;
}

.ord006-table table tfoot tr td {
  border-left: none !important;
}

.ord006-table table tr th:first-child,
.ord006-table table tr td:first-child {
  border-left: none;
}

.ord006-table table tr.row-level-0 td {
  background-color: #fafafa;
}

.ord006-table table tr.row-level-0 td:last-child {
  border-right: 1px solid #dae2ee;
}

.ord006-table table tr.row-level-0 td:first-child {
  border-left: 1px solid #dae2ee;
}

.ord006-table table tbody tr.row-project th {
  background: #fafafa;
}

.ord006-table table tr th {
  background-color: #f0f0f0;
  font-weight: 700;
  font-size: 14px;
  color: #666666;
}

.ord006-table table tr td {
  background-color: #fff;
  font-weight: 400;
  font-size: 14px;
  color: #222222;
}

.ord006-table table tr.bg-fa td {
  background-color: #fafafa;
}

.ord006-table table tr td.border-right-none {
  border-right-color: #fafafa !important;
}

.ord006-table table tr.table-foot td {
  border: none;
  background-color: #e6ecf2;
  color: #222222;

  font-size: 14px;
  font-weight: 700;
  line-height: 22px;
  letter-spacing: 0em;
}

.ord006-table table thead {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 99;
}

.ord006-table table {
  border-collapse: separate; /* Don't collapse */
  border-spacing: 0;
}

.ord006-table table tfoot {
  position: -webkit-sticky;
  position: sticky;
  bottom: 0;
  z-index: 99;
}

.ord006-table table tr.tr-border-first th:first-child {
  border-left: 1px solid #dae2ee;
}

.ord006-table table .cm0024-td-long-text {
  display: block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 200px;
}

.ord006-table table tr th.border-left-none {
  border-left: none !important;
}
