.sw02-wrapper,
#sw02 {
    height: 100%;
    display: flex;
    flex-direction: column;
}
.sw02-header {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
}
.sw02-header-title {
    font-weight: 800;
    font-size: 24px;
    color: #222222;
    margin: 8px 0 32px 0;
}

.sw02-header-btn {
    padding: 0 26px;
    border: 1px solid #605bff;
    display: flex;
    align-items: center;
    background-color: #605bff !important;
}
.sw02-header-btn img {
    width: 22px;
    margin-right: 8px;
}

.sw02-header-btn span {
    font-weight: 400;
    font-size: 14px;
    color: #fff;
}

.sw02-body {
    flex: 1;
    background-color: #fff;
    margin-bottom: 12px;
    box-shadow: 8px 8px 8px rgba(153, 153, 153, 0.02);
    border-radius: 3px;
    overflow: hidden;
    display: flex;
}

.sw02-body-left {
    width: 242px;
    background: #fcfcfc;
    height: 100%;
    box-shadow: -2px 0px 12px rgba(83, 83, 83, 0.05);
    border-right: 1px solid #dae2ee;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.sw02-body-left-list {
    margin-bottom: 24px;
    overflow: auto;
    box-shadow: 8px 8px 8px rgba(153, 153, 153, 0.02);
}

.sw02-body-left-list::-webkit-scrollbar {
    width: 8px;
}

.sw02-body-left-item {
    padding: 21px 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #dae2ee;
}

.sw02-body-left-item span {
    font-weight: 400;
    font-size: 14px;
    color: #222222;
}
.sw02-body-left-item img {
    width: 22px;
    cursor: pointer;
}

.icon-active {
    display: none;
}

.sw02-body-left-item:hover {
    background-color: #edeeef;
    cursor: pointer;
}

.sw02-body-left-item.active {
    background-color: #605bff;
    border-radius: 3px;
}

.sw02-body-left-item.active .icon-active {
    display: block;
}

.sw02-body-left-item.active .icon-default {
    display: none;
}

.sw02-body-left-item.active span {
    color: #ffffff;
    font-weight: 700;
}

.sw02-body-left-item img:hover {
    opacity: 0.6;
}

.sw02-body-left-add {
    background: #605bff;
    border-radius: 3px;
    padding: 12px 16px;
    margin: 0 16px 24px 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #ffffff;
    font-weight: 700;
    font-size: 14px;
    cursor: pointer;
    border: none;
}

.sw02-body-left-add:hover {
    opacity: 0.8;
}

.sw02-body-right {
    padding: 27px;
    flex: 1;
}

.sw02-body-right-header {
    display: flex;
    margin-bottom: 24px;
    align-items: center;
}

.sw02-body-right-header .label {
    color: #666666;
    font-weight: 400;
    font-size: 14px;
    margin-right: 24px;
}

.sw02-body-right-header .content {
    color: #222222;
    font-weight: 700;
    font-size: 14px;
}
.sw02-table {
    border: 1px solid #dae2ee;
    border-radius: 3px;
}
.sw02-table table {
    table-layout: fixed;
    width: 100%;
    border: 3px;
}

.sw02-table table tr th,
.sw02-table table tr td {
    padding: 15px;
    border-left: 1px solid #dae2ee;
    border-bottom: 1px solid #dae2ee;
}

.sw02-table table tr th:first-child,
.sw02-table table tr td:first-child {
    border-left: none;
}

.sw02-table table tr:last-child td {
    border-bottom: none;
}
.sw02-table table tr th {
    background: #f0f0f0;
    color: #222222;
    font-weight: 700;
    font-size: 16px;
}

.sw02-table table tr td {
    background-color: #fff;
    color: #666666;
    font-weight: 700;
    font-size: 14px;
    text-align: center;
}

.sw02-body-right .ant-form-item {
    margin: 0;
}

.sw02-table table tr:nth-child(2n - 1) td {
    background-color: #fafafa;
}

.sw02-table-footer {
    text-align: left;
    padding-left: 26px;
    color: #605bff;
    font-weight: 700;
    font-size: 14px;
}

.sw02-right-add {
    display: flex;
    align-items: center;
    cursor: pointer;
    width: fit-content;
}

.sw02-right-add:hover {
    opacity: 0.8;
}

.sw02-table-footer {
    padding: 16px 29px;
    background-color: #fafafa;
    border-top: 1px solid #dae2ee;
}
.sw02-table-footer span {
    margin-left: 18px;
    margin-bottom: -2px;
}

.sw02-icon-minus:hover {
    cursor: pointer;
    opacity: 0.8;
}

.sw02-table table tr td.edit {
    padding: 6px 24px;
}

.sw002-select .ant-select-selection-item {
    color: #222222;
    font-weight: 400;
    font-size: 14px;
    text-align: left;
}

.sw002-select .ant-select-selector {
    border: 1px solid #dae2ee !important;
    border-radius: 3px !important;
}

.sw002-input {
    border: 1px solid #dae2ee;
    border-radius: 3px;
    width: 375px;
}

.sw02-footer {
    background-color: #fff;
    padding: 24px;
    display: flex;
    justify-content: flex-end;
    margin-bottom: -16px;
}

.sw02-btn-submit {
    width: 76px;
    font-weight: 700;
    font-size: 14px;
}

.sw02-btn-cancel {
    width: 118px;
    color: #666666;
    font-weight: 400;
    font-size: 14px;
    margin-left: 16px;
}

.sw02-table-scroll {
    max-height: 50vh;
    overflow: auto;
    scroll-behavior: smooth;
}

.sw02-table-scroll.no-edit {
    max-height: 65vh;
}

.sw02-member-option {
    border-bottom: 1px solid #dae2ee;
    padding: 8px 16px;
}

.sw02-body .ant-form-item-explain-error {
    font-weight: 400;
}
.sw01-body-right-empty {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
}
