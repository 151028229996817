.db007-outer {
  position: relative;
}

.db007-table {
  max-width: 100%;
  overflow: auto;
  position: relative;
  max-height: 60vh;
  border-radius: 3px;
  scroll-behavior: smooth;
  border: 1px solid var(--border-line, #dae2ee);
}

.db007-table table {
  width: max-content;
  min-width: 100%;
  border-radius: 3px;
}

.db007-table table tr th,
.db007-table table tr td {
  padding: 15px;
}

.db007-table table tr td,
.db007-table table tr th {
  border-right: 1px solid var(--border-line, #dae2ee);
}

.db007-table table tr td:first-child,
.db007-table table tr th:first-child {
  position: sticky;
  left: 0;
  top: 0;
}

.db007-table table tr {
  border-bottom: 1px solid var(--border-line, #dae2ee);
}

.db007-table table tr td:last-child {
  border-right: none;
}

.db007-table table tr:last-child {
  border-bottom: none;
}

.db007-table table tr th {
  background-color: #f0f0f0;
  font-weight: 700;
  font-size: 14px;
  color: #666666;
}

.db007-table table tr td {
  background-color: #fff;
  font-weight: 400;
  font-size: 14px;
  color: #222222;
}

.db007-table table tr.bg-fa td {
  background-color: #fafafa;
}
