.sm04-data {
  display: flex;
  align-items: flex-start;
  gap: 24px;
  align-self: stretch;
  justify-content: space-between;
}

.sm04-data .row {
  display: flex;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  margin-bottom: 16px;
}

.sm04-data .row:last-child {
  margin-bottom: 0px;
}

.sm04-data .row .title {
  display: flex;
  justify-content: right;
  color: #666666;
  margin-right: 24px;
  min-width: 70px;
}

.sm04-data .row .description {
  color: #222222;
  width: 810px;
  word-wrap: break-word;
  white-space: pre-wrap;
  max-height: 100px;
  overflow-x: hidden;
}

.sm04-data .row:first-child {
  color: #ff5555;
}

.sm04-btn-save {
  border-radius: 3px;
  border: 1px solid var(--border-active, rgba(96, 91, 255, 0.5));
  background: var(--border-line, #dae2ee);
  color: var(--primary-color-logo-brand, #605bff);
}

.sm04-btn-cancel {
  border-radius: 3px;
  border: 1px solid var(--border-line, #dae2ee);
  background: #fff;
  color: var(--icon-inactive, #666);
}
.sm04-circle {
  height: 25px;
  width: 25px;
  border-radius: 50%;
  display: inline-block;
}
.modal-sm04 .ant-modal-body {
  padding: 24px 0 !important;
}

.sm04-comment-container {
  width: 100%;
  padding: 40px 0px;
  display: flex;
  flex-direction: column;
  gap: 32px;
}

.title-comment {
  color: var(--text-main, #222);

  /* PC/Header/H4 */

  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px; /* 150% */
  margin: 0 46px;
}

.content-comment {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.information-content {
  display: flex;
  align-items: center;
}
.information-content .text-name {
  color: var(--text-main, #222);

  /* PC/Header/H4 */

  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px; /* 150% */
}
.information-content .text-time {
  color: var(--text-sub-text, #666);

  /* PC/Table/Cell */

  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px; /* 157.143% */
  width: 793px;
}

.description-comment {
  word-wrap: break-word;
  white-space: pre-wrap;
  width: 809px;
  overflow-x: hidden;
  margin-left: 68px;
  color: #000;

  /* PC/Text/Breadcrumb */

  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 166.667% */
}
.content-comment-wrapper {
  display: flex;
  flex-direction: column;
  gap: 32px;
  margin: 0 46px;
}

.edited-comment {
  color: var(--icon-arrow, #999);

  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}
.new-comment {
  border-top: 1px solid var(--border-line, #dae2ee);
  background: var(--text-white, #fff);
  box-shadow: 0px -3px 5px 0px rgba(0, 0, 0, 0.08);
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 40px 43px 0px 46px;
}
