.pr-20 {
  padding-right: 20px;
}

.om007-form-container {
  margin-top: 24px;
  padding-right: 24px;
  min-height: 200px;
}

.om007-form-label {
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: #222222;
}
