.AM022Form .ant-form-item-label{
    min-width: 122px;
}

.AM022Form .ant-select .ant-select-selector{
    height: 40px;
    display: flex;
    align-items: center;
}

.AM022Form .AM022Date .form-item-label-end-date .ant-form-item-label{
    min-width: 100px;
}

.AM022Form .AM022_DayOff{
    display: flex;
    align-items: center;
    padding-left: 120px;;
}