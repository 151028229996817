.am028-title {
  color: #222222;
  font-weight: 800;
  font-size: 24px;
  margin: 12px 0 28px 0;
}

.am028 .ant-tabs-tab {
  border: 1px solid #dae2ee !important;
}

.am028 .ant-tabs-tab {
  border-radius: 3px 3px 0px 0px !important;
  background-color: #fff !important;
}

.am028 .ant-tabs-tab.ant-tabs-tab-active {
  background-color: #605bff !important;
}

.am028 .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #fff !important;
}

.am028.ant-tabs-top > .ant-tabs-nav::before {
  border-bottom: none;
}

.am028 .ant-tabs-nav {
  margin-bottom: 0;
}

.am028.ant-tabs-card.ant-tabs-top
  > .ant-tabs-nav
  .ant-tabs-tab
  + .ant-tabs-tab {
  margin-left: 0;
}

.am028-body {
  background-color: #fff;
  min-height: 78vh;
  padding: 32px 24px;
}

.am028-menu {
  display: flex;
  list-style-type: none;
  padding: 0;
}

.am028-menu-item {
  padding: 9px 16px;
  border-bottom: 1px solid #e5e5e5;
  color: #666666;
  font-weight: 400;
  font-size: 14px;
  cursor: pointer;
  position: relative;
}
.am028-menu-item:hover {
  color: #605bff;
}

.am028-menu-item-active {
  color: #605bff;
  font-weight: 700;
  font-size: 14px;
}

.am028-menu-item::after {
  content: "";
  position: absolute;
  height: 3px;
  transition: transform linear 0.2s, opacity linear 0.2s;
  top: 100%;
  left: 0;
  transform: scale(0);
  opacity: 0.8;
}

.am028-menu-item-active::after {
  width: 100%;
  background-color: #605bff;
  transform: scale(1);
  opacity: 1;
}

.am028-filter {
  background-color: #f4f7fa;
  display: flex;
  justify-content: space-between;
  padding: 24px;
  border-radius: 3px;
  margin-top: 18px;
}

.am028-filter-btn {
  min-width: 210px;
  display: flex;
  align-items: center;
}

.am028-btn {
  min-width: 90px;
  margin-left: 16px;
}

.am028-filter-chosen {
  display: flex;
  flex-wrap: wrap;
  margin: -8px 0 -8px -60px;
}

.am028-filter .ant-form-item {
  margin: 0;
  width: 100%;
}

.am028-filter-item {
  display: flex;
  align-items: center;
  padding: 8px 0 8px 60px;
}

.am028-filter-item-label {
  margin-right: 24px;
  font-weight: 400;
  font-size: 14px;
  color: #222222;
  white-space: nowrap;
}

.am028-select {
  min-width: 298px;
  font-size: 14px;
  font-weight: 400;
  color: #222222;
  border-radius: 3px;
}
.am028-select.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  border-color: #dae2ee;
  border-radius: 3px;
}

.am028-range {
  max-width: 298px;
  border-radius: 6px;
  border-color: #dae2ee;
  height: 40px;
}
.am028-range input {
  color: #222222;
  font-weight: 400;
}

.am028-range.ant-picker-large .ant-picker-input > input {
  font-size: 14px;
}

.am028-range-2 {
  max-width: 298px;
  border-radius: 6px;
  border-color: #dae2ee;
}
.am028-range-2 input {
  color: #222222;
  font-weight: 400;
}

.am028-range-2 .ant-picker-large .ant-picker-input > input {
  font-size: 14px;
}

.am028-table {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 54vh;
  margin-top: 16px;
}

.am028-table .ant-table-column-sorters {
  justify-content: unset;
}

.am028-table .ant-table-column-title {
  flex: unset;
}

.am028-modal-form-btn {
  display: flex;
  justify-content: flex-end;
}

.am028-modal-title {
  display: flex;
  align-items: center;
}

.am028-modal-title span {
  font-weight: 600;
  font-size: 16px;
  color: #222222;
  margin-left: 14px;
}

.am028-modal-form-item {
  display: flex;
  align-items: flex-start;
  margin: 32px 0;
}

.am028-modal-form-item-label {
  white-space: nowrap;
  display: block;
  margin: 0 24px;
  color: #222222;
  font-weight: 400;
  font-size: 14px;
  line-height: 38px;
}

.am028-modal .ant-form-item {
  margin: 0;
  width: 100%;
}

.am028-modal {
  padding-left: 300px;
}

.am038-title {
  display: flex;
  align-items: center;
}

.am038-title span {
  color: #222222;
  font-weight: 600;
  font-size: 16px;
  margin-left: 14px;
}

.am038-content {
  text-align: center;
  font-weight: 400;
  font-size: 14px;
  color: #222222;
  margin: 32px 0;
}

.am038-btn {
  display: flex;
  justify-content: center;
}

.am028-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.am028-search {
  width: 80%;
}

.am028-search-input {
  color: #222222;
  font-weight: 400;
  font-size: 14px;
  background: #e7eaee;
  border-radius: 3px;
  border: 1px solid #dae2ee;
}

.am028-search-input input {
  background: #e7eaee;
}

.am028-nav {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.am028-btn-purple {
  border-radius: 6px !important;
  width: 60px;
  height: 40px;
  letter-spacing: -1px;
  margin-right: 16px;
}

.am028-btn-gray-red {
  border-radius: 6px !important;
  border: 1px solid rgba(255, 9, 9, 0.5) !important;
  background: #dae2ee !important;
  color: #ff0909 !important;
  letter-spacing: -1px;
}

.column-min-50 {
  min-width: 52px;
}
.column-min-60 {
  min-width: 60px;
}
.column-min-65 {
  min-width: 102px;
}

.am028-table .ant-checkbox-disabled.ant-checkbox-checked .ant-checkbox-inner {
  background-color: #999999 !important;
}

.am028-table
  .ant-checkbox-disabled.ant-checkbox-checked
  .ant-checkbox-inner::after {
  border-color: #fff;
}

.am028-table .ant-table-body {
  scroll-behavior: smooth;
}

.column-min {
  min-width: 60px;
}

.btn-text-gray {
  color: #666666;
  font-weight: 400;
  font-size: 14px;
}

.am028-table .ant-table-tbody > tr > td {
  border-bottom: none;
}

.column-max {
  max-width: 200px;
}

.am028-table .ant-table-wrapper {
  border: 1px solid #eef2f9;
  border-radius: 3px;
}

.am028-table .ant-table-body {
  overflow: auto auto !important;
}

.am028-select-status {
  min-width: 256px;
}
