.legend-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    column-gap: 40px;

    width: 100%;
    height: 30px;
}

.legend-wrapper--pie {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    row-gap: 10px;

    width: 100%;
    height: 30px;
}

.legend-wrapper__item {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    column-gap: 12px;
}

.shape {
    width: 12px;
    height: 12px;
    border-radius: 50%;
}

.text {
    font-size: 14px;
    font-weight: 400px;
    color: #222;
    line-height: 22px;
}