.ccus002-title{
    width: 100%;
    margin-top: 24px;
    padding-left: 20px;
    font-size: 24px;
    font-weight: 700;
}
.ccus002-body{
    width: 100%;
    padding: 20px 24px 0 16px;
    background-color: #fff;
    margin-top: 17px;
}
.header-ccus-container{
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 40px;
}

.filter-ccus-container{
    height: 100%;
    border-right: 1px solid #dae2ee;
    position: relative;
    background: #fff !important;
    padding-top: 16px;
}

.filter-ccus061-container{
    border-right: 1px solid #dae2ee;
    position: relative;
    background: #fff !important;
    padding-top: 16px;
}

.tab-container{
    
    display: flex;
}
.tab-container .ant-tabs-tab-btn {
    font-weight: 400 !important;
    font-size: 14px !important;
    color: #666666 !important;
    width: 88px !important;
    margin: 0 !important;
  }
  
 .tab-container .ant-tabs-tab-active .ant-tabs-tab-btn {
    font-weight: 700 !important;
    font-size: 14px !important;
    color: #605bff !important;
    width: 88px !important;
    margin: 0 !important;
  }
  .row-table-ccus .ant-table-cell {
    background-color: #FFFFFF !important ;
    border-bottom: none !important;
}
.table-ccus .ant-table-tbody > tr > td {
    border-bottom: none !important; 
}

.table-ccus {
    border: 1px solid #EEF2F9 ;
}

.container-tab-ccus02{
    width: 100%;
    background: #fff;
    padding: 0px 24.5px 0 0px;
    margin-top: 17px;
}
.row-infor-tab-ccus02{
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: fit-content;
    width: 100%;
    margin-bottom: 20px;
    background-color: #fff !important;
}
.date-label-ccus02 {
    font-weight: 700;
    font-size: 14px;
    color: #222222;
    min-width: 20%;
    text-align: right;
  }
.header-row-title-ccus02 {
  font-size: 24px;
  font-weight: 800;
  line-height: 32px;
  letter-spacing: 0em;
  color: #222222;
  margin-top: 8px;
}
.ccus02-nav {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 44px;
  }
.content-popover-ccus02{
    width: 243px;
    padding: 8px 12px 
}
.title-filter{
    display: flex;
    justify-content: space-between;
    margin-bottom: 16
}
.text-title-filter {
    font-weight: 700;
    font-size: 18px;
    color: #222222;
    margin-top: 14px;
}

.ccus-pagination  {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 74vh;
}

.text-title-filter-ccus02 {
    font-weight: 700;
    font-size: 18px;
    color: #222222;
    margin-left: 16px;
}
.ccus-mb-27{
    margin-bottom: 27px;
    cursor: pointer;
}
.ccus-mt-8 {
    margin-top: 8px;
}
.bg-fff{
    background-color: #fff;
}
.header-filter-ccus {
    display: flex;
    justify-content: space-between;
}
.ccus-px-16{
    flex: 1;
    padding-left: 16px;
    padding-right: 16px;
}
.ccus-pr-16{
    padding-right: 16px;
}
.pl-15{
    padding-left: 15px;
}
.item-filter-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 8px;
    margin-bottom: 24px;
} 

.item-filter-container-ccus061 {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 8px;
}
.item-filter-child {
    margin: 0;
    padding: 0;
    width: 100%;
}
.btn-filter-container {
    display: flex; 
    justify-content: space-between;
    margin-top: 24px;
}
.btn-filter-width {
    width: 97px;
}
.content-table-ccus {
    margin: 24px 24px 16px 24px;
    background-color: #ffffff;
    height: fit-content;
}
.ccus-table-wrapper{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.table-sort-wrapper-ccus {
    display: flex;
    align-items: center;
}
.text-table-ccus {
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    text-align: left;
    color: #666666;
}
.text-table-ccus-disable {
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    text-align: left;
    color: #999999;
}
.table-ccus tr:nth-child(odd){
    background-color: #FAFAFA;
}

.table-ccus tr:nth-child(even){
    background-color: #FFFFFF;
}

.from-filter-ccus {
    display: flex;
    flex-direction: column;
    height: 100%;
}