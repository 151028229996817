.chart-wrapper {
  display: flex;
  flex-direction: column;
  gap: 24px 0;
  margin-top: 24px;
}

.db001-row {
  display: flex;
  flex-direction: row;

  column-gap: 12px;
  justify-content: space-between;
  align-items: stretch;
  width: 100%;
}

.db001-item,
.db001-item__row2 {
  display: flex;
  flex-direction: column;
  background-color: #fff;
  box-shadow: 4px 8px 24px 2px rgba(133, 133, 133, 0.08);
  border-radius: 8px;
  width: calc(50% - 12px);
}

.db001-item {
  height: 490px;
}

.db001-item__row2 {
  height: 441px;
}

.db001-title {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  color: #343434;
  padding: 24px;

  font-size: 18px;
  font-weight: 600;
  line-height: 32px;
}

.db001-composechart__title {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  column-gap: 16px;
  color: #343434;

  font-size: 18px;
  font-weight: 600;
  line-height: 32px;
  width: max-content;
}

.db001-composechart__eye {
  cursor: pointer;
}

.db001-title img {
  cursor: pointer;
}

.db001-row--case3 {
  height: 500px;
}

.db001-first__chart {
  height: 232px;
  margin-top: 32px;
}

.db001-chart--row3 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 420px;
  width: 100%;
}

.db001-table ::-webkit-scrollbar {
  width: 6px !important;
}
.db001-table {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  min-width: 764px;
  min-height: 420px
}

.bottom-axis-wrapper__item {
  display: flex;
  gap: 2px;
}
.bottom-axis-wrapper__item img {
  cursor: pointer;
}
.bottom-axis-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  column-gap: 4px;
  width: 100%;
  padding-left: 152px;
  padding-right: 20px;
  margin-bottom: 75px;
}

.db001__table tr {
  cursor: pointer;
}

.db001__table .ant-table-tbody > tr > td {
  border-bottom: none !important;
}

.db001-legend-chart {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  column-gap: 12px;
}

.db001-legend-chart__shape {
  width: 21px;
  height: 2px;
  background: var(
    --primary-color-decor-decor,
    linear-gradient(270deg, #ffc53d 0%, #ea6b38 100%)
  );
}

.db001-legend-chart__text {
  color: #222;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
}

.db001-composechart__tooltip--custom {
  color: var(--icon-active, #222);

  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 26px;
  border-radius: 3px;
  background: var(--text-white, #fff);
  box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.18);
  padding: 10px;
}

.db001-composechart__tooltip--content {
  display: block;
}

.db001-composechart__left--label,
.db001-composechart__right--label {
  position: absolute;
  top: 50%;
  color: var(--text-sub-text, #666);

  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.db001-composechart__left--label {
  transform: rotate(-90deg) translateX(50%);
  margin-left: -38px;
  left: 0;
}

.db001-composechart__right--label {
  transform: rotate(-90deg) translate(40px, calc(50%));
  right: -38px;
}

.default-filter-container {
  width: 264px;
  height: 40px;
  padding: 8px, 16px, 8px, 16px;
  border-radius: 6px;
  border: 1px;
  background-color: #dae2ee;

  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
  color: #222222;
  cursor: not-allowed;
}

.db001-table__wrapper {
  overflow-x: auto;
  overflow-y: hidden;
}

@media screen and (max-width: 1682px) {
  .db001-row {
    flex-direction: column;
    gap: 24px 0;
  }

  .db001-item,
  .db001-item__row2 {
    width: 100%;
  }
}
