.OM005-container {
  background-color: #ffffff;
  border: 1px solid #e9edf2;
}

.button-back-om005 {
  font-weight: 400 !important;
  font-size: 14px !important;
  margin-bottom: 28px;
  color: #222222 !important;
  border: "none" !important;
  margin-top: 25px;
  margin-left: 25px;
}

.number-circle {
  width: 22px;
  height: 22px;
  background-color: #999999;
  border-radius: 22px;
  font-weight: 400;
  font-size: 14px;
  color: #ffffff;
  text-align: center;
  margin-right: 8px;
}
.OM005-container .ant-collapse-header {
  border-bottom: 1px solid #e9edf2;
  height: 54px;
  align-items: center !important;
}

.collapse-label {
  font-weight: 400;
  font-size: 14px;
  color: #222222;
}

.OM005-container .ant-collapse-item {
  background-color: #ffffff !important;
}

.OM005-container .ant-collapse-content-box {
  background-color: #fafafa !important;
}

.section2-container {
  padding-top: 32px;
  padding-bottom: 32px;
  padding-left: 24px;
  padding-right: 24px;
  background-color: #fafafa !important;
}

.section2-title {
  text-align: center;
  font-weight: 700;
  font-size: 18px;
  margin-bottom: 51px;
  color: #222222;
}
.section2-file-option {
  border-radius: 3px;
  background-color: #ffffff;
  height: 40px;
  width: 261px;
  border: 1px solid #e9edf2;
  font-weight: 400;
  font-size: 14px;
  color: #222222;
}

.file-chosen {
  background-color: #e7e6ff !important;
}

.mr-24 {
  margin-right: 24px;
}

.mt-51 {
  margin-top: 51px;
}

.mt-32 {
  margin-top: 32px;
}

.mt-16 {
  margin-top: 16px;
}

.mb-16 {
  margin-bottom: 16px;
}

.p-32 {
  padding: 32px;
}

.upload-file-title {
  font-weight: 400;
  font-size: 14px;
  color: #666666;
}

.section1-button {
  font-weight: 700 !important;
  font-size: 14px !important;
  color: #605bff !important;
}

/*OM005 Section1*/
.wrapper__section1 {
  width: 100%;
  min-height: 198px;
  background-color: #fafafa;
}

.wrapper__section1 .section1__radio {
  margin-top: 32px;
  margin-left: 226px;
}

.wrapper__section1 .section1__input {
  display: block;
  margin-top: 16px;
}

.wrapper__section1 .section1__input .section1__input-password {
  margin-left: 130px;
  margin-bottom: 16px;
}

.wrapper__section1 .section1__input .section1__input-repassword {
  margin-left: 115px;
  margin-bottom: 16px;
}

.wrapper__section1
  .section1__input
  .section1__input-password
  .section1__input-span {
  margin-right: 24px;
}
.wrapper__section1 .section1__input-password,
.wrapper__section1 .section1__input-repassword {
  display: flex;
}

.wrapper__section1 .section1__input-span {
  margin-top: 8px;
}

.wrapper__section1
  .section1__input
  .section1__input-repassword
  .section1__input-span {
  margin-right: 25px;
}

.wrapper__section1 .section1__box1 {
  width: 100%;
  height: 198px;
  background-color: #605bff !important;
}

.wrapper__section1 .section1__box2 {
  width: 100%;
  height: 198px;
  background-color: #e7e6ff !important;
}

.wrapper__section3 {
  width: 100%;
}

.om005__footer {
  margin-top: 24px;
  margin-bottom: -24px;
  position: relative;
  display: flex;
  width: 100%;
  height: 88px;
  background-color: #ffffff;
  bottom: 0;
  box-shadow: 8px 8px 8px rgba(153, 153, 153, 0.02);
  border-radius: 0px 0px 3px 3px;
  align-items: center;
}

.om005__footer .ant-form-item {
  margin: 0;
}

.om005__footer .om005__footer-wrapper {
  display: flex;
  position: absolute;
  width: 185px;
  right: 48px;
}

.om005__footer .om005__footer-addition {
  display: relative;
  flex-direction: row;
  align-items: center;
  padding: 8px 24px;
  gap: 8px;

  width: 76px;
  height: 40px;

  right: 0;

  /* Primary Color/Logo Brand */

  background: #605bff;
  color: #fff;
  /* Tablet */

  box-shadow: 4px 8px 24px 2px rgba(133, 133, 133, 0.08);
  border-radius: 3px;

  margin-right: 16px;
}

.om005__footer .om005__footer-addition:hover {
  background: #605bff;
  color: #fff;
}

.om005__footer .om005__footer-cancel {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 12px 24px;
  gap: 10px;

  color: #666666;

  width: 118px;
  height: 40px;

  font-size: 14px;
  font-weight: 400;

  margin-right: 24px;

  background: #ffffff;
  /* Border/Line */

  border: 1px solid #e9edf2;
  border-radius: 3px;
}

.om005__upload-progress {
  box-sizing: border-box;

  position: relative;
  width: 100%;
  height: 98px;
  background: #ffffff;
  /* Border/Line */
  align-items: center;

  border: 1px solid #e9edf2;
  border-radius: 6px;
}

.om005__upload-progress .om005__upload-progress-text {
  font-size: 14px;
  margin-top: 30px;
}

.om005__upload-progress .om005__upload-progess-bar {
  position: absolute;
  width: 100%;
  height: 0px;
}

.om005__modal-button {
  display: flex;
  width: 100%;
  margin-top: 16px;
  margin-bottom: 32px;
}

.om005__modal-button .om005__modal-button-success {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 9px 16px;
  gap: 10px;
  height: 40px;
  border-radius: 3px 3px 0px 0px;
}

.om005__modal-button .om005__modal-button-failure {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 9px 16px;
  gap: 10px;
  height: 40px;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: #666666;
  border-radius: 3px 3px 0px 0px;
}

.om005__button-active {
  background-color: #605bff !important;
  color: #fff !important;
}

.om005__upload-preview {
  box-sizing: border-box;

  position: relative;
  width: 100%;
  height: 62px;
  background: #ffffff;
  /* Border/Line */
  align-items: center;

  border: 1px solid #e9edf2;
  border-radius: 6px;
}

.om005__upload-preview .om005__upload-card {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 4px 10px;
  gap: 16px;

  position: absolute;
  /* width: 168px; */
  height: 30px;
  background: #e9edf2;
}

.om005__upload-preview .om005__upload-card p {
  margin-top: 12px;
  font-size: 14px;
  color: #666666;
  font-weight: 400;
}

.om005__button-cancel {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 8px 24px;
  gap: 8px;

  width: 118px;
  height: 40px;

  /* Border/Line */

  color: #ff0909;

  background: #e9edf2;
  /* Border/Warning */

  border: 1px solid rgba(255, 9, 9, 0.5);
  border-radius: 3px;
}

.OM005-container .ant-form-item {
  margin: 0;
}

.wrapper__section1 .ant-form-item-explain-error {
  font-size: 12px;
}

.om005__table ::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.03);
  border-radius: 10px;
  background-color: #f5f5f5;
}

.om005__table ::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  background-color: #f5f5f5;
}

.om005__table ::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.03);
  background-color: #999;
}

.om005__table table tr.ant-table-row-selected > td {
  background-color: unset !important;
}

.om005__selection-placeholder .ant-select-selection-placeholder {
  color: #343434 !important;
}
