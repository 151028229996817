.radio-label {
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  /* identical to box height, or 157% */

  /* Text/Main */
  margin-left: 16px;
  color: #222222;
}
