.CR005 .header {
  display: flex;
  justify-content: space-between;
  padding: 0px 37.5px 0px 37.5px;
}
.CR005 .footer {
  display: flex;
  justify-content: end;
  padding-right: 38px;
}
.CR005 .body {
  min-height: 224px;
  margin-top: 32px;
  padding-left: 30px;
  max-height: 500px;
  overflow-y: auto;
  overflow-x: hidden;
}

.CR005 .ant-modal-body {
  padding: 32px 0px 18px 0px !important;
}

.CR005 .title {
  font-size: 13px;
  font-weight: 700;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: left;
  color: rgba(96, 91, 255, 1);
  margin-bottom: 6px;
}

.CR005 .content {
  font-size: 12px;
  font-weight: 400;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: left;
  color: rgba(96, 91, 255, 1);
  margin-bottom: 6px;
}

.CR005 .step {
  display: flex;
  margin-bottom: 9.5px;
}
.CR005 .step-end {
  display: flex;
}

.CR005 .step .image {
  margin-right: 16px;
}
.CR005 .step-end .image {
  margin-right: 16px;
  margin-top: 5.5px;
}

.mile-stone {
  background-color: rgba(96, 91, 255, 1);
  height: 8px;
  width: 8px;
  border-radius: 50px;
}

.mile-stone-stick {
  height: 90%;
  width: 1px;
  background-color: rgba(96, 91, 255, 0.5);
  margin-left: 3.5px;
  margin-top: 2px;
}
