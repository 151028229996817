.title-table-ic3 {
  color: #222;

  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
}

.wrapper-table-ic3 {
  height: 344px;
  width: 100%;
  border-bottom: 1px solid #dae2ee;
  margin-bottom: 32px;
}

.ic3-table {
  max-width: 100%;
  overflow: auto;
  position: relative;
  max-height: 62vh;
  border: 1px solid #dae2ee;
  border-radius: 3px;
  scroll-behavior: smooth;
}

.ic3-table table {
  width: max-content;
  min-width: 100%;
  border-radius: 3px;
}

.ic3-table table tr th,
.ic3-table table tr td {
  text-align: center;
  border-bottom: 1px solid #dae2ee;
  border-left: 1px solid #dae2ee !important;
  border-right: 1px solid #dae2ee !important;
  padding: 15px;
}

.ic3-table table tr th {
  background-color: #f0f0f0;

  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px;
  color: #666666;
}

.ic3-table table tr td {
  background-color: #fff;

  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  color: #222222;
}

.ic3-table table tr.bg-fa td {
  background-color: #fafafa;
}

.ic3-table table tr th:first-child,
.ic3-table table tr td:first-child {
  border-left: none !important;
}

.ic3-table table tr.table-foot td {
  border: none !important;
  background-color: #e6ecf2;

  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px;
}

.ic3-table table tr.bg-fa td {
  background-color: #fafafa;
}
.content-text-infor {
  display: flex;
  align-items: flex-start;
  gap: 24px;
}
.text-item {
  color: #222;

  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
}
.txt-666 {
  color: #666;
}
.txt-valid-color {
  color: #0fa44a;
}
.txt-invalid-color {
  color: #ff0909;
}
.ic3-table table tr td.txt-table-valid {
  font-size: 14px;
  color: #0fa44a;
}
.ic3-table table tr td.txt-table-invalid {
  font-size: 14px;
  color: #ff0909;
}
