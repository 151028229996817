.table-action .ant-badge {
  margin: 0 4px;
}
.filter-col .ant-input-affix-wrapper {
  width: 100%;
}

.pm003-header-title {
  font-size: 24px;
  font-weight: bold;
}
.header-info-number {
  margin-top: 24px;
}
.header-button {
  margin-top: 24px;
  margin-bottom: 24px;
}
.header-button-title {
  font-size: 20px;
  font-weight: 800;
  margin-top: 8px;
}

.header-content-number {
  font-size: 24px;
  font-weight: bold;
}
.button-add {
  color: #fff;
  border-radius: 3px !important;
}

.header-button-icon {
  text-align: center;

  background-color: #f7f6fe;
  border: none;
  border-radius: 4px !important;
}
.button-layout-square {
  background-color: #e7e6ff;
  border: none;
  border-radius: 8px !important;
}
.button-vector {
  margin-left: 16px;
  background-color: #f3f3fa;
  border: none;
  border-radius: 8px !important;
  opacity: 0.6;
}

.pm003-table tr:nth-child(odd) {
  background-color: #fafafa;
  border-radius: 3px 3px 0px 0px;
}
.pm003-table tr:nth-child(even) {
  background-color: #fff;
  border-radius: 3px 3px 0px 0px;
}
.pm003-table thead th {
  background-color: #f0f0f0;
  border-radius: 3px 3px 0px 0px;
}

.pm003-table tr td {
  cursor: pointer;
}
.pm003-table tbody tr {
  height: 76px;
}
.pm003-table tbody tr td {
  padding-top: 16px;
  font-size: 14px !important;
  font-weight: 400 !important;
}

.header-info-card {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 86px;
  padding: 0 24px;
  border-radius: 8px;
}
.header-content {
  text-align: right;
}

.avatar-badge-button-table .ant-badge-count {
  margin-top: 8px;
  font-size: 9px;
  text-align: center;
  width: 10px;
  padding-left: 5px;
  border-radius: 50%;
  position: absolute;
  right: 6px;
}
.avatar-badge-button-table {
  position: relative;
}

.pm003-body {
  height: 62vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
