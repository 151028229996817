.modal-header-no-padding .ant-modal-header {
  padding: 0px !important;
  margin: 0px !important;
}

.title-modal-container {
  height: 74px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 24px 0px 24px;
}

.form-row-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;
  width: 100%;
}

.color-view-container {
  height: 24px;
  width: 24px;
  border-radius: 50%;
}
