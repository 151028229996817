.cm0032-title-table{
    display: flex;
    min-height: 52px;
    background-color: #F0F0F0;
    display: flex;
  }
  .cm0032-title-parent2 {
    text-align: center;
    width: 100px;
    padding-top: 15px;
    color: #222222;
  }