.h1-modal .ant-table-title {
  background-color: #dae2ee !important ;
  padding-left: 50px !important;
}

.h1-modal .ant-table-thead {
  background-color: #fdfdfd !important ;
}

.h1-modal th.ant-table-cell {
  background-color: #fdfdfd !important ;
}

.h1-modal .ant-table-thead > tr > th {
  font-weight: 700 !important;
  font-size: 14px !important;
  color: #666666 !important;
  /* background: #ffffff !important; */
}

.h1-modal-white-header .ant-table-thead > tr > th {
  background: #ffffff !important;
}

.h1-row-cell {
  font-size: 14px;
  font-weight: 700;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
  color: #666666;
}

.header-row-h1-modal {
  font-size: 14px;
  font-weight: 700;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
  color: #020202;
}

.header-style-row .ant-table-cell {
  background-color: #f0f0f0 !important ;
  text-align: left !important ;
  padding-left: 48px !important;
}

.normal-style-row .ant-table-cell {
  background-color: #ffffff !important ;
}
