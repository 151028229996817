.t01__wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;

  border: 1px solid var(--border-line, #dae2ee);
  border-radius: 3px;
}

.t01__item--header {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 8px 24px;

  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px; /* 150% */
  color: var(--text-main, #222);
  background: #f0f0f0;
}

.t01__item {
  display: flex;
  flex-direction: row;

  align-items: center;
  justify-content: flex-start;
}

.t01__item--left {
  color: var(--text-main, #222);

  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px;
  padding: 8px 24px;
  width: 665px;
}

.t01__item--right {
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 8px;

  color: var(--text-main, #222);
  /* PC/Table/Header */

  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px; /* 157.143% */
}

.t01__item--right img {
  cursor: pointer;
}

.t01__border-bottom {
  border-bottom: 1px solid var(--border-line, #dae2ee);
}

.t01__fw400 {
  font-weight: 400 !important;
}

.t01__popover {
  display: flex;
  flex-direction: row;
  column-gap: 8px;

  align-items: center;
  justify-content: center;
  padding: 7px 8px;
  border-radius: 3px;
  border: 1px solid var(--border-line, #dae2ee);
}

.t01__popover img {
  cursor: pointer;
}

.t01__popover .ant-input {
  border: none;
  width: 104px;
}
