.aop003 {
  color: #222;
}
.aop003-footer {
  display: flex;
  justify-content: flex-end;
  padding: 24px;
  background-color: #fff;
  position: fixed;
  bottom: 0;
  width: calc(100% - 350px);
}

.aop003-btn-submit {
  height: 40px;
  width: 76px;
  letter-spacing: -1px;
}

.aop003 .ant-form-item-explain-error {
  font-size: 12px;
}

.aop003-btn-cancel {
  width: 118px;
  color: #666;
  margin-left: 16px;
  height: 40px;
}

.aop003-title {
  color: #222;
  font-size: 24px;
  font-style: normal;
  font-weight: 800;
  margin-bottom: 32px;
  margin-top: 8px;
}

.aop003-body {
  background-color: #fff;
  box-shadow: 8px 8px 8px 0px rgba(153, 153, 153, 0.02);
  border-radius: 3px;
  width: 100%;
  min-height: 74vh;
  display: flex;
}

.aop003-body-left {
  border-right: 1px solid #dae2ee;
  min-width: 242px;
  max-width: 242px;
  height: 100%;
  min-height: 74vh;
  padding: 24px 16px;
}

.aop003-item1 {
  margin-bottom: 46px;
}

.aop003-item1 span {
  display: block;
  color: #222;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  margin-bottom: 8px;
}
.aop003-item1 .ant-input {
  border: 1px solid #dae2ee;
  border-radius: 3px;
  height: 40px;
  font-size: 14px;
  color: #222;
}

.aop003-item2-title {
  color: #222;
  font-size: 14px;
  font-weight: 700;
  margin-bottom: 26px;
  display: block;
}

.aop003-item2 .ant-input-affix-wrapper {
  border: 1px solid #dae2ee;
  border-radius: 3px;
  font-size: 14px;
  color: #222;
  height: 40px;
}

.aop003-member-list {
  margin-top: 26px;
  list-style: none;
  padding: 0 6px 0 0;
  margin-right: -6px;
  max-height: 42vh;
  overflow: auto;
}

.op003-member-item {
  display: flex;
  justify-content: space-between;
  border-radius: 30px;
  border: 1px solid #605bff;
  margin: 16px 0;
  padding: 0 8px;
  height: 40px;
  line-height: 40px;
  align-items: center;
}

.op003-member-item:first-child {
  margin-top: 0;
}

.op003-member-item:last-child {
  margin-bottom: 6px;
}

.op003-member-item span {
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  color: #222;
}

.op003-member-item img {
  cursor: pointer;
  padding: 4px 0 4px 4px;
}

.op003-member-item img:hover {
  opacity: 0.6;
}

.aop003-body-right {
  padding: 24px;
  flex: 1;
}

.aop003-body-right-tile {
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  color: #222;
  display: flex;
  align-items: center;
}

.aop003-body-right-tile span {
  margin-right: 8px;
}

.tab-card {
  margin-top: 16px;
}

.tab-card .ant-tabs-tab {
  border: 1px solid #dae2ee !important;
  border-radius: 3px 3px 0 0 !important;
  color: #666 !important;
  background-color: #fff !important;
}

.tab-card .ant-tabs-tab.ant-tabs-tab-active {
  background-color: #605bff !important;
  border: none !important;
}

.tab-card .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #fff !important;
}

.tab-card .ant-tabs-tab {
  margin-left: 0 !important;
}

.tab-card .ant-tabs-nav {
  margin-bottom: 0;
}

.tab-card .ant-tabs-nav::before {
  display: none;
}

.aop-tab-title {
  padding: 15px 50px;
  background-color: #dae2ee;
  display: flex;
  align-items: center;
}

.aop-tab-title span {
  columns: #222;
  font-size: 14px;
  font-style: normal;
  margin-right: 8px;
  font-weight: 700;
}

.aop-tab-item th,
.aop-tab-item td {
  text-align: center !important;
  border-right: 1px solid #dae2ee !important;
  border-bottom: 1px solid #dae2ee !important;
}

.aop-tab-item td {
  padding: 10px 16px !important;
}

.aop-tab-item .ant-table.ant-table-bordered > .ant-table-container {
  border-left: 1px solid #dae2ee;
}

.aop003 .ant-form-item {
  margin: 0;
}

.aop-tab-list {
  max-height: 60vh;
  overflow: auto;
}
.aop003 tbody tr {
  background-color: #fafafa;
}
.aop003 tbody tr:nth-child(odd) {
  background-color: #fff !important;
}

.icon-cursor {
  cursor: pointer;
}

.aop-tab-title img {
  cursor: pointer;
}

.aop-tab-title span {
  color: #222;
}

.aop003 .ant-checkbox-inner:hover {
  border: 1px solid #605bff !important;
}
