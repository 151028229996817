.db003-table .ant-table-thead .ant-table-cell{
    border: 1px solid var(--border-line, #DAE2EE) !important;
    border-right: none !important;
}

.db003-table .ant-table-thead .ant-table-cell:last-child{
    border-right: 1px solid var(--border-line, #DAE2EE) !important;
}

.db003-table .ant-table-tbody .ant-table-cell{
    border: 1px solid var(--border-line, #DAE2EE) !important;
    border-right: none !important;
    border-top: none !important;
}

.db003-table .ant-table-tbody .ant-table-cell:last-child{
    border-right: 1px solid var(--border-line, #DAE2EE) !important;
}

.db003-table tr:nth-child(odd){
    background-color: #FFFFFF;
}

.db003-table tr:nth-child(even){
    background-color: #FAFAFA;
}