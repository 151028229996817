.cr001-mail-contractor {
  padding-left: 300px;
}

.cr001-modal-title {
  display: flex;
  align-items: center;
}

.cr001-modal-title-icon {
  margin-right: 12px;
}

.cr001-modal-title-icon:hover {
  opacity: 0.6;
  cursor: pointer;
}

.cr001-mail-contractor .pagination-total {
  color: #222222;
}

.text-error .ant-form-item-explain-error {
  padding-left: 24px !important;
}
