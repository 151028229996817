.container-tab-ccus01 {
    width: 100%;
    height: 85vh;
    background: #fff;
    margin-top: 17px;
    display: flex; 
    justify-content: center; 
    align-items: center; 
}

.header-title-ccus01 {
    font-size: 20px;
    font-weight: 800;
    line-height: 28px;
    letter-spacing: 0em;
    color: #222222;
    margin-bottom: 24px;
    text-align: center; 
    white-space: pre-line;
}

.infomation_ccus01{
  margin-top: 24px;
  border-radius: 8px;
  padding: 8px;
  background-color: #F8F8F8;
}

.description-ccus01 {
    font-size: 14px;
    font-weight: 400;
    line-height: 28px;
    letter-spacing: 0em;
    color: #666666;
    text-align: center;
}

.container-content-ccus01 {
  align-content: center;
  padding: 36px;
  flex: 2;
}

.line_ccus01 {
  height: 1px;
  background-color: #DAE2EE;
}

.ccus-business-id {
    display: flex;
    justify-content: space-between; 
    width: 100%;
    padding-bottom: 12px;
}

.ccus-business-id p {
    flex: 1; 
    margin: 0; 
}

.ccus-business-password {
    display: flex;
    justify-content: space-between; 
    width: 100%;
    align-items: center;
}

.title-ccus01 {
    font-size: 14px;
    font-weight: 400;
    line-height: 26px;
    letter-spacing: 0em;
    color: #222222;
    text-align: right;
    padding-right: 12px;
  }

  .content-ccus01 {
    font-size: 14px;
    font-weight: 400;
    line-height: 26px;
    letter-spacing: 0em;
    color: #544FC7;
    text-align: left;
    width: 100%;
    padding-left: 12px;
  }

  .input-password-comfirm {
    display: flex;
    width: 100%;
  }

  .container-status-ccus01 {
    width: 100%;
    height: 85vh;
    background: #fff;
    margin-top: 17px;
    display: flex; 
    justify-content: center; 
}

.title-password-comfirm {
    width: 100%;
    height: 26px;
  }

  .title-ccus-status {
    font-size: 14px;
    font-weight: 400;
    line-height: 28px;
    letter-spacing: 0em;
    color: #666666;
    text-align: center;
    white-space: pre-line;
  }

  .title-ccus-status-error {
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0em;
    color: #FF0909;
    text-align: center;
    white-space: pre-line;
  }

  .title-ccus-status-success {
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0em;
    color: #0FA44A;
    text-align: center;
    white-space: pre-line;
  }

  .custom-password-input .ant-input {
    height: 26px;
    max-width: 220px;
    color: #544FC7;
    letter-spacing: 0.1em;
  }
  .contaner-background-ccus01 {
    flex: 3;
    width: auto;
    height: 85vh;
    background-color:#F4F3FF
}

  .img-background-ccus01 {
    width: 100%;
    max-height: 85vh;
}
