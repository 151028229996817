.pm025-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 6px;
}

.pm025-header-filter {
  display: flex;
  align-items: center;
  width: 80%;
}
.pm025-header-title {
  font-weight: 800;
  font-size: 24px;
  color: #030229;
  margin: 0;
}

.pm025-btn-add {
  min-width: 134px;
  margin: 0 16px 0 40px;
}

.pm025-table .ant-table-column-sorters {
  justify-content: unset;
}

.pm025-table .ant-table-column-title {
  flex: unset;
}

.pm025-body {
  height: 75vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.pm025-table .ant-table-body {
  overflow-y: auto !important;
}

.pm025-modal-btn button {
  margin-left: 16px;
}

.pm025-modal-btn {
  display: flex;
  justify-content: flex-end;
  border-top: 1px solid #e9edf2;
  margin: 0 -24px;
  padding: 24px 24px 0 0;
}

.pm025-modal-btn .ant-form-item {
  margin: 0;
}

.pm025-modal-form-item {
  display: flex;
  width: 100%;
  margin-bottom: 16px;
}

.pm025-modal-form-item .ant-form-item {
  margin: 0px !important;
  padding: 0px !important;
}

.pm025-modal-form-item-label {
  width: 106px;
  display: block;

  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: right;
  color: rgba(102, 102, 102, 1);
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: flex-end;
  margin-right: 24px;
}

.pm025-modal-form-item-input {
  flex: 1;
}

.btn-text-color {
  color: #666666;
  font-weight: 400;
  font-size: 14px;
}

.pm025-tab {
  margin-top: 32px;
}

.pm025-tab .ant-tabs-tab {
  border-radius: 3px 3px 0px 0px !important;
  background-color: #fff !important;
}

.pm025-tab .ant-tabs-tab.ant-tabs-tab-active {
  background-color: #605bff !important;
}

.pm025-tab .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #fff !important;
}

.pm025-tab.ant-tabs-top > .ant-tabs-nav::before {
  border-bottom: none;
}

.pm025-tab .ant-tabs-nav {
  margin-bottom: 0;
}

.pm025-tab.ant-tabs-card.ant-tabs-top
  > .ant-tabs-nav
  .ant-tabs-tab
  + .ant-tabs-tab {
  margin-left: 0;
}

.pm025-table-bottom-border .ant-table-tbody > tr > td {
  border-bottom-color: transparent !important;
}
