.header-modal-title-cm006 {
    font-weight: 700;
    font-size: 18px;
    line-height: 26px;
    color: #222222;
    word-wrap: break-word;
    white-space: pre-wrap;
    width: 98%; 
    overflow-wrap: break-word; 
}

.cm006-content .content-description {
    display: flex;
    justify-content: space-between;
    padding-left: 36px;
    padding-right: 284px;
}

.cm006-content .description-row {
    display: flex;
}

.cm006-content .description-row label {
    min-width: 84px;
    display: flex;
    justify-content: end;
    color: #666666;
}

.cm006-content .description-row p {
    margin-left: 24px;
    color: #222222;
}

.cm006-content .content-table-cm006 .table-top .ant-table-title {
    background-color: #f0f0f0;
    border: 1px solid #dae2ee !important;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #222222;
}

.cm006-content
    .content-table-cm006
    .table-top
    .ant-table-container
    .ant-table-content
    .ant-table-thead
    .ant-table-cell {
    background-color: #fafafa !important;
}

.cm006-content .content-table-cm006 .table-bot .ant-table-title {
    background-color: #dae2ee;
    border: 1px solid #dae2ee !important;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #222222;
}

.cm006-content
    .content-table-cm006
    .table-bot
    .ant-table-container
    .ant-table-content
    .ant-table-thead
    .ant-table-cell {
    background-color: #f5f5f5 !important;
    border-bottom: 1px solid #dae2ee;
    border-right: 1px solid #dae2ee;
}
.cm006-content
    .content-table-cm006
    .table-bot
    .ant-table-container
    .ant-table-content
    .ant-table-thead
    .ant-table-cell:first-child {
    border-left: 1px solid #dae2ee;
}

.cm006-content
    .content-table-cm006
    .table-top
    .ant-table-container
    .ant-table-content
    .ant-table-thead
    .ant-table-cell {
    border-right: 1px solid #dae2ee;
    border-bottom: 1px solid #dae2ee;
}

.cm006-content
    .content-table-cm006
    .table-top
    .ant-table-container
    .ant-table-content
    .ant-table-thead
    .ant-table-cell:first-child {
    border-left: 1px solid #dae2ee;
}

.cm006-content .content-table-cm006 .table-bot .ant-table-footer {
    background-color: #605bff;
    height: 52px;
    color: #ffffff;
}

.cm006-content .content-table-cm006 .table-bot {
    margin-bottom: 80px;
}

.cm006-content
    .content-table-cm006
    .table-bot
    .ant-table-summary
    .children-table-summary
    .ant-table-cell {
    border: none;
}

.cm006-content
    .content-table-cm006
    .table-top
    .ant-table-container
    .ant-table-content
    .ant-table-tbody
    .ant-table-row
    .ant-table-cell {
    background-color: #ffffff;
    border-right: 1px solid #dae2ee;
    border-bottom: 1px solid #dae2ee;
    border-top: none;
}
.cm006-content
    .content-table-cm006
    .table-top
    .ant-table-container
    .ant-table-content
    .ant-table-tbody
    .ant-table-row
    .ant-table-cell:first-child {
    border-left: 1px solid #dae2ee;
}

.content-table-cm006
    .table-top
    .ant-table-container
    .ant-table-content
    .ant-table-tbody
    .ant-table-row
    .ant-table-cell
    span {
    color: #222222;
}

.cm006-content
    .content-table-cm006
    .table-bot
    .ant-table-container
    .ant-table-content
    .ant-table-tbody
    .ant-table-row
    .ant-table-cell {
    background-color: #ffffff;
    border-right: 1px solid #dae2ee;
    border-bottom: 1px solid #dae2ee;
    border-top: none;
}

.cm006-content
    .content-table-cm006
    .table-bot
    .ant-table-container
    .ant-table-content
    .ant-table-tbody
    .ant-table-row
    .ant-table-cell:first-child {
    border-left: 1px solid #dae2ee;
}

.cm006-content
    .content-table-cm006
    .table-bot
    .ant-table-container
    .ant-table-content
    .ant-table-tbody
    .ant-table-row
    .ant-table-cell
    span {
    color: #222222;
}

.cm006-content .content-table-cm006 .ant-divider {
    border-top: 1px solid #dae2ee;
}

.button-dsb:disabled {
    opacity: 0.5;
}
