.cm005-table-header div.ant-table-title {
  background: #f0f0f0;
  padding-left: 50px !important;
}

.cm005-title-header {
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */

  /* Text/Main */

  color: #222222;
}

.summary-border-purple .parent-table-summary td.ant-table-cell {
  border-right: #605bff !important;
}

.cm005-datepicker-font input {
  color: #605bff !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 14px !important;
  line-height: 22px !important;
}

.cm005-datepicker-font svg {
  color: #605bff !important;
}

.title-table-cm005 {
  /* PC/Text/Primary */

  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  /* identical to box height, or 157% */

  /* Text/Sub Text */

  color: #666666;
}
