.sm01-nav .calendar-filter-container-custom table th {
  width: 31.14px !important;
  height: 30px !important;
  background-color: #fff;
  color: #666666 !important;
  font-weight: 400 !important;
}

.sm01-nav .calendar-filter-container-custom table td {
  background-color: #fff;
}

.sm01-nav
  .calendar-filter-container-custom
  .ant-picker-calendar
  .ant-picker-panel
  .ant-picker-body {
  padding: 8px 12px 8px 12px !important;
}

.sm01-nav
  .calendar-filter-container-custom
  .ant-picker-cell
  .ant-picker-cell-in-view
  .ant-picker-cell-selected {
  font-size: 14px !important;
  font-weight: 700 !important;
  line-height: 22px !important;
  letter-spacing: 0em !important;
  text-align: center !important;
}

.sm01-wrapper h1 {
  font-weight: 800;
  font-size: 24px;
  line-height: 32px;
  color: #222222;
  margin-top: 8px;
}

.sm01-content {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;

  margin-top: 32px;
}

.sm01-nav {
  display: flex;
  flex-direction: column;
  row-gap: 16px;

  width: 242px;
  height: auto;
  border-radius: 3px;
}

.sm01-calendar {
  display: flex;
  width: calc(100% - 266px);
  background-color: #fff;

  border-radius: 3px;
  box-shadow: 5px 5px 20px rgba(184, 170, 201, 0.12);
}

.sm01-calendar-mininal {
  width: 100%;
}

/* Minial calendar */
.sm01-header {
  display: flex;
  justify-content: space-between;
  align-items: center;

  padding: 8px;
  height: 40px;
}

.sm01-header img {
  cursor: pointer;
}

.text {
  font-size: 14px;
  font-weight: 700;
  line-height: 22px;
  color: #222;
}

.sm01-tags {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  column-gap: 8px;
}

.sm01-tags__item {
  display: flex;
  flex-direction: row;
  column-gap: 4px;

  background-color: #fff;
  border: 1px solid #605bff;
  border-radius: 30px;
  padding: 8px;
  margin-bottom: 8px;
}

.sm01-tags__item img {
  cursor: pointer;
}

.sm01-tags__item-name {
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
}

.sm01-search {
  position: relative;
}

.prefix-icon-wrapper {
  position: absolute;
  z-index: 1;
  width: 3rem;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.sm01-search .ant-select-selection-item {
  display: none;
}

.sm01-search .select-search-sm01 {
  height: 30px;
  background-color: #fff;
  border-radius: 3px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.sm01-search .select-search-sm01 .ant-select {
  width: 100% !important;
}

.sm01-search .select-search-sm01 .ant-select-selector {
  width: 100% !important;
}

.calendar-wrapper {
  display: flex;
  flex-direction: column;
  row-gap: 24px;

  padding: 16px 24px 24px 24px;
  width: 100%;
}

.calendar__header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  height: 40px;
  width: 100%;
}

/* .calendar-content {
    max-height: 768px;
    overflow-y: auto;
} */

.calendar-header-title-month {
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  width: fit-content;
}
.calendar-header-title-day {
  font-size: 18px;
  font-weight: 700;
  line-height: 26px;
  letter-spacing: 0em;
  text-align: left;
}

.calendar-header__filter {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  column-gap: 24px;
}

.calendar-header__filter-label {
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  color: #222;
}

.calendar-header__filter-select {
  box-sizing: border-box;

  /* Auto layout */

  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  padding: 9px 12px 9px 16px;
  gap: 16px;

  width: 375px;
  height: 40px;

  /* Text/White */

  background: #ffffff;
  border: 1px solid #dae2ee;
  box-shadow: 8px 8px 8px rgba(153, 153, 153, 0.02);
  border-radius: 3px;
}

.calendar-header__filter-select .ant-select {
  width: 100%;
}

.calendar-header__filter-select .ant-select-selector {
  border: 1px solid #fff !important;
  padding: 0px !important;
}

.calendar-header__filter-button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 9px 16px;
  gap: 8px;
  background: #605bff;
  border-radius: 6px;

  font-size: 14px;
  font-weight: 700;
  color: #fff;

  cursor: pointer;
}

.calendar-week__table {
  width: 100%;
  border-radius: 3px;
  border-color: #dae2ee;
  border-collapse: collapse;
}

.calendar-week__table thead {
  width: 100%;
}

.calendar-week__table thead th {
  position: sticky;
  top: 0;
  z-index: 1;

  font-size: 14px;
  font-weight: 700;
  color: #222;
  height: 60px;
}

.calendar-week__table tbody tr td {
  text-align: center;
  height: 190px;
}

.calendar-week__table tr:nth-child(odd) {
  background-color: #fff !important;
}

.calendar-week__table td:nth-child(1) {
  color: #222;
  font-size: 14px;
  font-weight: 700;
  width: 110px;
}

.calendar-week__table tr td {
  width: 164px;
}

.calendar-week__table tr th {
  width: 164px;
}

.td--active {
  background-color: #f6f5ff;
}

.th--active {
  border-top: 2px solid #605bff;
  background-color: #f6f5ff !important;
  color: #605bff !important;
}

.unit-wrapper {
  display: flex;
  flex-direction: column;
  row-gap: 10px;
  align-items: flex-start;
  justify-content: center;
  padding: 0px 20px;
}

.unit-wrapper-month {
  display: flex;
  flex-direction: column;
  row-gap: 8px;
  align-items: flex-start;
  justify-content: center;
  /* padding: 0px 20px; */
  z-index: 9999;
}

.unit {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  position: relative;
  column-gap: 7px;
}

.unit__month--active {
  background-color: #e7e6ff;
  padding: 5px 8px;
  border-radius: 3px;
  border-left: 2px solid #605bff;
}

.unit__circle {
  width: 5px;
  height: 5px;
  background-color: #605bff;
  border-radius: 50%;
}

.unit__text {
  font-size: 14px;
  font-weight: 400;
  color: #222;
  cursor: pointer;
}

.unit__text--active {
  font-weight: 700;
  cursor: pointer;
}

.unit__plus {
  font-size: 14px;
  color: #605bff;
}

.unit__desc {
  color: #605bff;
  font-size: 14px;
  font-weight: 700;
  line-height: 22px;
  text-align: center;

  cursor: pointer;
  width: 115px;
}

/* Popup */

.popup-group {
  display: flex;
  flex-direction: column;

  max-height: 400px;
  overflow-y: auto;
}

.popup-group::-webkit-scrollbar {
  width: 6px !important;
}

.popup-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 38px;
}

.popup-header__text {
  font-size: 14px;
  font-weight: 700;
  line-height: 22px;
  color: #222;
  text-align: center;
}

.popup-item {
  padding: 8px 0px 8px 0px;
  border-top: 1px solid #eee;
}

.popup-header__icon {
  cursor: pointer;
  margin-right: 16px;
}

.calendar-content-month .ant-picker-calendar-header {
  display: none !important;
}

.calendar-content-month .ant-picker-content thead {
  text-align: center;
  height: 38px;
}

.calendar-content-month
  .ant-picker-content
  .ant-picker-cell-selected
  .ant-picker-calendar-date-today {
  background-color: #fff;
}

.calendar-content-month
  .ant-picker-cell-in-view.ant-picker-cell-selected
  .ant-picker-cell-inner {
  background-color: #fff !important;
}

.calendar-content-month
  .ant-picker-calendar-full
  .ant-picker-panel
  .ant-picker-body
  th {
  padding: 0px;
}

.calendar-content-month .ant-picker-content tbody {
  text-align: center;
}

.calendar-content-month .ant-picker-content thead tr th {
  background-color: #fff !important;
  border: 1px solid #dae2ee;
  font-size: 14px;
  font-weight: 400;
  color: #666;
}

.calendar-content-month .ant-picker-content tbody tr td {
  background-color: #fff;
}

.calendar-content-month .ant-picker-content .ant-picker-cell {
  border: 1px solid #dae2ee;
}

.calendar-content-month
  .ant-picker-content
  .ant-picker-cell
  .ant-picker-calendar-date
  .ant-picker-calendar-date-value {
  font-weight: 400;
  font-size: 14px;
  color: #222;
}

.calendar-content-month
  .ant-picker-content
  .ant-picker-cell
  .ant-picker-calendar-date
  .ant-picker-calendar-date-content {
  height: 134.8px;
  padding-top: 5px;
}

.calendar-content-month
  .ant-picker-content
  .ant-picker-cell
  .ant-picker-calendar-date {
  border-top: none;
}

.unit__image {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  border-radius: 50%;

  color: #fff;
  font-size: 12px;
  font-weight: 400;
}

.calendar-content-month .ant-picker-calendar-date-content::-webkit-scrollbar {
  width: 6px;
}

.calendar-content-month td.ant-picker-cell:hover {
  background-color: #fff !important;
}
/* .ant-picker-calendar-date */

.event-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 108px;
  width: 100%;
  margin-bottom: 16px;
  border-radius: 16px;
  border: 1px solid #dae2ee;
  padding: 16px;
}

.event-row .event-frame {
  width: 153px !important;
  height: 76px;
  border-radius: 8px;
  font-weight: 800;
  font-size: 20px;
  line-height: 28px;
}

.event-row .event-frame-container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 90%;
}

.event-row .event-frame-title {
  font-size: 18px;
  font-weight: 700;
  line-height: 26px;
  letter-spacing: 0em;
  text-align: left;
  color: #222222;
  width: 85%;
}

.event-row .comment-container {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.event-row-week {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 30px;
}

.pd-16 {
  padding: 16px;
}

.pd-8 {
  padding: 8px;
}

.event-row-week .info {
  height: 30px;

  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0em;
}

.popover-no-padding .ant-popover-inner-content {
  padding: 0px !important;
}

.event-row-month {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: fit-content;
  height: 30px;
}

.event-row-month .info {
  height: 30px;

  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0em;
}

.comment-badge-button .ant-badge-count {
  margin-top: 6px;
  font-size: 9px;
}

.tooltip-text .ant-tooltip-open {
  background-color: red !important;
}

.comment-badge-button .ant-badge-multiple-words {
  padding: 0px 2px 0px 2px !important;
}

.month-cell-render-container {
  padding: 0px 0px 0px 0px;
  height: 100%;
  width: 100%;
  cursor: default;
}
.month-cell-render-container .date {
  text-align: left;
}

.month-cell-render-container .content {
  overflow: auto;
  max-height: 148px;
}

.month-cell-render-container .is-current {
  color: #605bff;
}
