.header-style-row-h2 .ant-table-cell {
  background-color: #fdfdfd !important ;
}

.note-header-h3 {
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: center;
  color: #666666;
}
