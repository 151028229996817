.cm042 {
    margin-top: 24px;
}

.cm042-item {
    border: 1px solid #dae2ee;
    border-radius: 3px;
    margin-bottom: 4px;
}

.cm042-item-header {
    padding: 15px 50px;
    background: #f0f0f0;
    color: #222222;
    font-weight: 600;
    font-size: 16px;
}

.cm042-table {
    width: 100%;
}

.cm042-table tr th,
.cm042-table tr td {
    border: 1px solid #dae2ee;
    text-align: center;
    padding: 15px;
    background-color: #fff;
}

.cm042-table tr th:first-child,
.cm042-table tr td:first-child {
    border-left: none;
}

.cm042-table tr th:last-child,
.cm042-table tr td:last-child {
    border-right: none;
}

.cm042-table tr:last-child td {
    border-bottom: none;
}

.cm042-table tr th {
    font-weight: 700;
    font-size: 14px;
    color: #666666;
    background: #fafafa;
}

.cm042-table tr td {
    color: #222222;
    font-weight: 400;
    font-size: 14px;
}

.no-data-cm0042 .ant-empty.ant-empty-normal {
    margin: 0;
}
