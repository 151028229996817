.approve-view-label {
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
  color: rgba(102, 102, 102, 1);
}

.approve-view-content {
  font-size: 14px;
  font-weight: 700;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
  color: rgba(34, 34, 34, 1);
  word-break: break-all;
}

.approve-form-label {
  font-size: 14px;
  font-weight: 700;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
  color: rgba(102, 102, 102, 1);
}
