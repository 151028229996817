.cm024 {
  background-color: #fff;
  padding: 32px;
}
.cm024-table {
  max-width: 100%;
  overflow: auto;
  position: relative;
  max-height: 60vh;
  border: 1px solid #dae2ee;
  border-radius: 3px;
  scroll-behavior: smooth;
}

.cm024-table table {
  width: max-content;
  min-width: 100%;
  border-radius: 3px;
}

.cm024-table table tr th,
.cm024-table table tr td {
  text-align: center;
  border-bottom: 1px solid #dae2ee;
  border-left: 1px solid #dae2ee !important;
  padding: 15px;
}

.cm024-table table tfoot tr td {
  border-left: none !important;
}

.cm024-table table tr th:first-child,
.cm024-table table tr td:first-child {
  border-left: none;
}

.cm024-icon {
  cursor: pointer;
}

.cm024-table table tr.row-level-0 td {
  border: none;
  background-color: #dae2ee;
  color: #222222;
  font-weight: 700;
  font-size: 14px;
}

.cm024-table table tr.row-level-0 td:last-child {
  border-right: 1px solid #dae2ee;
}

.cm024-table table tr.row-level-0 td:first-child {
  border-left: 1px solid #dae2ee;
}

.cm024-table table tbody tr.row-project th {
  background: #fafafa;
}

.cm024-table table tr th {
  background-color: #f0f0f0;
  font-weight: 700;
  font-size: 14px;
  color: #666666;
}

.cm024-table table tr td {
  background-color: #fff;
  font-weight: 400;
  font-size: 14px;
  color: #222222;
}

.cm024-table table tr.bg-fa td {
  background-color: #fafafa;
}

.cm024-table table tr td.border-right-none {
  border-right-color: #fafafa !important;
}

.icon-view {
  cursor: pointer;
  margin-top: -2px;
}

.icon-view:hover {
  opacity: 0.8;
}

.cm024-icon {
  transform: rotate(0deg);
  transition: all 0.3s, visibility 0s;
}

.cm024-icon-active {
  transform: rotate(180deg);
}

.cm024-table table tr.table-foot td {
  border: none;
  background-color: #605bff;
  color: #ffffff;
  font-weight: 700;
  font-size: 14px;
}

.table-foot {
  border-left: 1px solid #dae2ee;
  border-right: 1px solid #dae2ee;
  border-bottom: 1px solid #dae2ee;
}

.cm024-header-filter {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.cm024-header-filter-choose {
  display: flex;
}

.cm024-btn-download {
  background: #dae2ee !important;
  border: 1px solid #605bff;
  width: 106px;
  height: 40px;
  border-radius: 6px !important;
  color: #605bff;
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin-left: 24px;
}

.cm024-btn-submit {
  margin-left: 16px;
}
.cm024-range {
  width: 250px;
  border: 1px solid #605bff;
}
.cm024-range input {
  color: #605bff !important;
  font-weight: 400;
  font-size: 14px;
}

.cm024-range .ant-picker-separator svg {
  color: #605bff;
}

.cm024-range .ant-picker-clear {
  padding: 12px 0 12px 12px;
}

.cm024-header-title {
  margin-bottom: 24px;
  margin-top: 12px;
}

.cm024-header-label {
  font-weight: 400;
  font-size: 14px;
  color: #666666;
}

.cm024-header-content {
  font-weight: 700;
  font-size: 14px;
  color: #605bff;
  margin-left: 12px;
}

.cm024-modal {
  padding-left: 300px;
}

.cm024-modal-footer {
  display: flex;
  justify-content: flex-end;
  margin-top: 47px;
  padding-top: 24px;
  border-top: 1px solid #dae2ee;
}

.cm024-modal-btn-ok {
  width: 120px;
}

.cm024-modal-btn-cancel {
  width: 118px;
  color: #666;
  margin-left: 16px;
}

.cm024-modal-header {
  margin: 24px 0 32px 0;
  display: flex;
}

.cm024-modal-header-item {
  width: 450px;
  padding-left: 34px;
}

.cm024-modal-label {
  font-weight: 400;
  font-size: 14px;
  color: #666666;
}

.cm024-modal-title {
  color: #222222;
  font-weight: 400;
  font-size: 14px;
  margin-left: 24px;
}

.cm024-table table thead {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 99;
}

.cm024-table table {
  border-collapse: separate; /* Don't collapse */
  border-spacing: 0;
}

.cm024-table table tfoot {
  position: -webkit-sticky;
  position: sticky;
  bottom: 0;
  z-index: 99;
}

.table-sort-wrapper {
  cursor: pointer;
  margin: -15px;
  padding: 15px;
}

.cm024-table table tr.tr-border-first th:first-child {
  border-left: 1px solid #dae2ee;
}

.cm024-header .am028-menu-item-active::after {
  top: calc(100% - 2px);
}

.cm0024-date {
  min-width: 200px;
  max-width: 200px;
}

table tr td.border-left-none {
  border-left: none !important;
}

table.cm0022 {
  width: 100%;
  table-layout: fixed;
}

.cm024-table table .cm0024-td-long-text {
  display: block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 200px;
}

.cm024-table table tr th.border-left-none {
  border-left: none !important;
}

.cm031-icon {
  transition: all 0.3s, visibility 0s;
  cursor: pointer;
}

.cm031-icon-active {
  transform: rotate(-180deg);
}

.cm024-table table tr.row-level-0 {
  cursor: pointer;
}
