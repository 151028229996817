.pm024-form {
  display: flex;
  width: 100%;
}
.pm018-external-header-left {
  width: 38.5%;
  padding-left: 6%;
}

.pm018-external-header-center {
  width: 30.8%;
}
.pm018-external-header-right {
  width: 30%;
}

.pm018-company {
  border: 1px solid #eef2f9;
}

.pm018-btn-bonus-company {
  display: flex;
  align-items: center;
  margin-top: 22px;
}

.pm018-btn-bonus-company span {
  font-weight: 700;
  font-size: 14px;
  line-height: 22px;
  color: #605bff;
}

.pm018-search {
  width: 100%;
  margin-bottom: 28px;
}

.pm018.cr001-history-modal {
  width: 58% !important;
  margin-left: 28%;
}

.pm018-company-name-input {
  width: 80%;
}

.pm018-company-active-input {
  width: 65%;
}

.pm018-action {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 19px 34px 19px 0;
}

.pm018-action-btn {
  display: flex;
  align-items: center;
}

.pm018-action-btn button {
  background-color: transparent;
  border: none;
  cursor: pointer;
}

.pm018-action-btn button:hover {
  opacity: 0.8;
}

.pm018-text-status {
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
}

.pm018-text-status-activate {
  color: #0fa44a;
}

.pm018-text-status-deactivate {
  color: #ff0909;
}

.pm018-modal-pagination {
  margin: 24px -24px 0 -24px;
}

.pm018-item {
  padding-left: 24px;
}

.pm018-item-radio {
  display: flex;
}

.pm018-item .ant-radio-wrapper {
  width: 100%;
}

.pm018-item .ant-radio-wrapper > span:nth-child(2) {
  width: 100%;
  display: block;
}

.pm018-item-radio-name {
  width: 35%;
  padding-right: 22px;
  display: block;
  color: #222222;
}

.pm018-item-radio-email {
  width: 34%;
  color: #222222;
}

.pm018-company-body {
  scroll-behavior: smooth;
  display: flex;
  flex-direction: column;
}

.pm012-input-search-company {
  width: 100% !important;
}

.pm018-modal-form-item {
  margin: 0;
  width: 100%;
}

.pm018 {
  top: 50px;
}

.pm018-modal-form-item {
  width: 50%;
}

.pm018-modal-form-item-name {
  width: 38%;
  margin: 0;
}

.pm018-modal-form-item-name .ant-select.ant-select-in-form-item {
  width: 80%;
}

.pm024-bg-white.cr001-external-item:nth-child(2n) {
  background-color: #fff;
}

.pm018 .ant-modal-close {
  width: 84px;
}
.pm018-company-email-input {
  width: 70%;
}

.pm018-company-email-popup {
  height: 200px;
  max-height: 200px;
}
.pm018-autocomplete {
  width: 50%;
  position: relative;
}

.pm018-autocomplete .pm018-modal-form-item {
  width: 70%;
}

.pm018-autocomplete .pm018-company-email-input {
  width: 100%;
}

.pm018-autocomplete-popup-wrapper {
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  background-color: #fff;
  border-radius: 5px;
  padding: 12px 6px;
  position: absolute;
  width: 70%;
  transform: scale(1, 0);
  transition: all 0.3s, visibility 0s;
  transform-origin: top center;
  opacity: 0;
  z-index: 99;
}

.pm018-autocomplete-popup {
  list-style: none;
  padding: 0;
  max-height: 180px;
  overflow-x: hidden;
  overflow-y: auto;
  margin: 0;
  scroll-behavior: smooth;
}
.pm018-autocomplete-popup-item {
  padding: 6px 12px;
  border-bottom: 1px solid #eef2f9;
}
.pm018-autocomplete-popup-item:last-child {
  border-bottom: none;
}

.pm018-autocomplete-popup-item:hover {
  background-color: rgba(0, 0, 0, 0.03);
  cursor: pointer;
}
