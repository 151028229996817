.am010__container {
  display: flex;
  flex-direction: column;
}

.am010__container .am010__header {
  margin-top: 8px;
}

.am010__container .am010__header h1 {
  font-style: normal;
  font-weight: 800;
  font-size: 24px;
  line-height: 32px;
  color: #222222;
}

.am010__container .am010__header .am010__header-element {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  height: 278px;
  margin-top: 20px;
}

.am010__container .am010__header .am010__header-element .am010__header-unit {
  display: flex;
  flex-direction: column;
  width: 329px;
  height: 278px;
  background-color: #fff;
  border-radius: 3px;
  margin-right: 24px;
  padding: 20px 24px;
}

.am010__container
  .am010__header
  .am010__header-element
  .am010__header-unit
  .am010__unit-title {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 87px;
  margin-bottom: 50px;
}

.am010__container
  .am010__header
  .am010__header-element
  .am010__header-unit
  .am010__unit-parameter {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 82px;
  row-gap: 8px;
}

.am010__container .am010__header .am010__header-element .am010__header-timer {
  display: flex;
  width: 513px;
  height: 278px;
  background: #ffffff;
  border-left: 4px solid #605bff;
  border-radius: 3px;
  padding: 24px;
}

.am010__unit-title .am010__unit-number {
  display: block;
  width: 70%;
  float: left;
}

.am010__unit-title .am010__unit-number h4 {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #222222;
}

.am010__unit-title .am010__unit-number span {
  font-style: normal;
  font-weight: 800;
  font-size: 40px;
  line-height: 55px;
  color: #33cc99;
}

.am010__unit-title .am010__unit-number .am010__unit-inner {
  font-size: 20px;
  color: #666666;
  margin-left: 3px;
}

.am010__unit-title .am010__unit-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30%;
  float: left;
}

.am010__unit-parameter-unit .am010__unit-span {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: #222222;
  margin-right: 24px;
}

.am010__calendar-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 1168px;
  margin-top: 40px;
}

.am010__calendar-container h2 {
  font-style: normal;
  font-weight: 800;
  font-size: 20px;
  line-height: 28px;
  color: #222222;
}

.am010__calendar-container .am010__calendar {
  display: flex;
  flex-direction: column;
  width: 100%;
  /* height: 1108px; */
  margin-top: 22px;
  background: #ffffff;
  box-shadow: 4px 8px 24px 2px rgba(133, 133, 133, 0.08);
  border-radius: 3px;
  padding-left: 24px;
  padding-right: 24px;
  padding-bottom: 20px;
}

.am010__calendar-container .am010__calendar .am010__calendar-header {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100px;
}

.am010__calendar-container
  .am010__calendar
  .am010__calendar-header
  .am010__calendar-status {
  display: flex;
  flex-direction: row;
  width: 70%;
  margin-left: 24px;
  column-gap: 24px;
}

.am010__calendar-container
  .am010__calendar
  .am010__calendar-header
  .am010__calendar-filter {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  width: 30%;
}

.am010__calendar-container
  .am010__calendar
  .am010__calendar-header
  .am010__calendar-status
  .am010__calendar-item {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  column-gap: 8px;
}

.am010__calendar-circle1 {
  display: flex;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: #605bff;
}

.am010__calendar-circle2 {
  display: flex;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: #ff9861;
}

.am010__calendar-circle3 {
  display: flex;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: #33cc99;
}

.am010__calendar-circle4 {
  display: flex;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: #ff5555;
}

.am010__calendar .am010__calendar-content {
  display: flex;
  /* height: 988px; */
  background-color: red;
}

.am010__calendar-container
  .am010__calendar
  .am010__calendar-header
  .am010__calendar-status
  .am010__calendar-item
  .am010__calendar-status-name {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: #222222;
}

.am010__calendar-filter .am010__calendar-dropdown {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px 16px;
  gap: 8px;
  width: 126px;
  height: 40px;
  background: #605bff;
  box-shadow: 4px 8px 24px 2px rgba(133, 133, 133, 0.08);
  border-radius: 3px;
  cursor: pointer;
}

.am010__calendar-filter .am010__calendar-dropdown span {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  color: #ffffff;
  position: relative;
}

.am010__calendar-filter .am010__calendar-dropdown-2 {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 8px 16px;
  gap: 8px;
  width: 131px;
  height: 40px;
  background: #605bff;
  box-shadow: 4px 8px 24px 2px rgba(133, 133, 133, 0.08);
  border-radius: 6px;
  cursor: pointer;
}

.am010__calendar-filter .am010__calendar-dropdown-2 span {
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0em;
  color: #ffffff;
  position: relative;
}

.am010__calendar-filter .am010__calendar-month {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 181px;
  height: 40px;
  margin-left: 16px;
  column-gap: 8px;
}

.am010__calendar-filter .am010__calendar-month img {
  cursor: pointer;
}

.am010__calendar-filter .am010__calendar-month .am010__picker-month {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 8px 8px;
  gap: 8px;
  width: 121px;
  height: 40px;
  background: #ffffff;
  border: 1px solid #dae2ee;
  border-radius: 3px;
}

.am010__calendar-filter
  .am010__calendar-month
  .am010__picker-month
  .ant-picker-input
  input {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: #222222;
}

.date-cell-render-border {
  border-top: 1px solid #dae2ee;
  border-right: 1px solid #dae2ee;
}

.date-cell-render {
  height: 190px;
}

.pt-pb-8 {
  padding-top: 8px;
  padding-bottom: 8px;
}

.date-cell-render-container {
  padding: 0px 0px 0px 0px;
  height: 100%;
  width: 100%;
  cursor: default;
}
.date-cell-render-container .date {
  padding: 8px;
  text-align: left;
}

.date-cell-render-container .content {
  overflow: auto;
  max-height: 148px;
  padding: 0px 8px 0px 8px;
}

.date-cell-render-container .is-current {
  color: #605bff;
}
.date-cell-render :hover {
  background-color: #fafafa;
}

.date-cell-render-2 {
  height: 190px;
}

.am010__calendar-content .ant-picker-body {
  padding: 0px !important;
}

.am010__calendar-content {
  border-left: 1px solid #dae2ee;
  border-bottom: 1px solid #dae2ee;
}

.am010__calendar-content .ant-picker-calendar-header {
  display: none !important;
}

.am010__calendar-content .ant-picker-cell .ant-picker-cell-inner {
  text-align: left;
  padding: 16px 8px 8px;
  width: 217.71px;
  height: 190px;
  background: #ffffff;
  border: 1px solid #dae2ee;
}

.am010__calendar-content .ant-picker-cell .ant-picker-calendar-date {
  margin: 0px !important;
  padding-right: 15px !important;
}

.am010__calendar-content .ant-picker-content thead tr th {
  text-align: center;
  border-top: 1px solid #dae2ee;
  border-right: 1px solid #dae2ee;
  height: 38px !important;
  background: #f0f0f0 !important;
}

.am010__calendar-content .ant-picker-cell .ant-picker-calendar-date-content {
  height: 142px !important;
}

.am010__calendar-unit {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 4px 0px 4px 4px;
  row-gap: 4px;
  height: 44px;
  box-shadow: 8px 8px 8px rgba(153, 153, 153, 0.02);
  border-radius: 3px;
}

.am010__calendar-unit .am010__calendar-unit-title {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  color: #666666;
}

.am010__calendar-unit .am010__calendar-unit-content {
  display: flex;
  flex-direction: row;
  column-gap: 5px;
}

.am010__calendar-unit
  .am010__calendar-unit-content
  .am010__calendar-attendance {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  color: #222222;
}

.calendar__wrapper {
  display: flex;
  flex-direction: column;
  row-gap: 8px;
}

.am010__timer-container {
  display: flex;
  flex-direction: column;
  row-gap: 32px;
  width: 100%;
}

.am010__timer-container .am010__timer-box1 {
  display: flex;
  flex-direction: row;
  column-gap: 24px;
  width: 100%;
  height: 158px;
}

.am010__timer-container .am010__timer-box1 .am010__timer-select {
  display: flex;
  flex-direction: column;
  width: 283px;
  row-gap: 24px;
}

.am010__timer-container
  .am010__timer-box1
  .am010__timer-select
  .am010__timer-title
  h4 {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #222222;
  letter-spacing: 2px;
}

.am010__timer-container
  .am010__timer-box1
  .am010__timer-select
  .am010__timer-dropdown
  .ant-form-item {
  margin-top: 8px !important;
  margin-bottom: 8px !important;
}

.am010__timer-container
  .am010__timer-box1
  .am010__timer-select
  .am010__timer-dropdown
  label {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  text-align: right;
  color: #222222;
  margin-right: 24px;
  margin-top: 5px;
}

.am010__timer-container
  .am010__timer-box1
  .am010__timer-select
  .am010__timer-dropdown
  .am010__header-select {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  padding: 12px 12px 9px 0px;
  gap: 16px;
  width: 217px;
  height: 40px;
  background: #ffffff;
  border: 1px solid #dae2ee;
  box-shadow: 8px 8px 8px rgba(153, 153, 153, 0.02);
  border-radius: 3px;
}

.custom-option {
  white-space: normal !important;
  word-wrap: break-word !important; 
  display: block; 
  overflow-wrap: anywhere; 
}
.am010__timer-container
  .am010__timer-box1
  .am010__timer-select
  .am010__timer-dropdown
  .am010__header-select
  .ant-select-selector {
  border: none !important;
}

.am010__timer-container .am010__timer-box2 {
  display: flex;
  flex-direction: row;
  column-gap: 32px;
}

.am010__timer-container .am010__timer-box2 .am010__timer-counter {
  display: flex;
  justify-content: flex-start;
  align-self: flex-end;
  width: 161px;
  height: 48px;
  left: 25px;
  top: 206px;

  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 40px;
  color: #666666;
}

.am010__timer-action {
  display: flex;
  flex-direction: row;
  column-gap: 16px;
}

.am010__timer-action .am010__action-register1 {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px 32px;
  gap: 8px;
  width: 128px;
  height: 40px;
  background: #605bff;
  box-shadow: 4px 8px 24px 2px rgba(133, 133, 133, 0.08);
  border-radius: 3px;
  color: #fff;
  font-weight: 600;
  cursor: pointer;
}

.am010__timer-action .am010__action-register2 {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px 32px;
  gap: 8px;
  width: 128px;
  height: 40px;
  background: #ffffff;
  border: 1px solid #605bff;
  box-shadow: 4px 8px 24px 2px rgba(133, 133, 133, 0.08);
  border-radius: 3px;
  color: #605bff;
  font-weight: 600;
  cursor: pointer;
}

.am010__clock {
  position: relative;
  width: 158px;
  height: 158px;
}

.am010__clock img {
  margin-top: -14px;
  margin-left: -22px;
}

.hour_hand {
  position: absolute;
  width: 3px;
  height: 25px;
  background: #353d4e;
  border-radius: 5px;
  top: 34%;
  left: 49%;
  transform-origin: bottom;
}

.min_hand {
  position: absolute;
  width: 2px;
  height: 30px;
  background: #2a3c3c;
  top: 30.5%;
  left: 50%;
  transform-origin: bottom;
}

.sec_hand {
  position: absolute;
  width: 2px;
  height: 31px;
  background: linear-gradient(270deg, #b61861 0%, #cd498d 100%);
  top: 29.5%;
  left: 49.5%;
  transform-origin: bottom;
}

.am010__timer-container .am010__timer-box1 .am010__timer-counter-no {
  display: flex;
  justify-content: flex-start;
  width: 161px;
  height: 48px;

  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 40px;
  color: #666666;
}

.am010__timer-title-no h4 {
  margin-top: 32px;

  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #222222;
  letter-spacing: 2px;
}

.am010__timer-action-no {
  display: flex;
  flex-direction: row;
  column-gap: 16px;
}

.am010__timer-action-no .am010__action-register1 {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px 32px;
  gap: 8px;
  width: 224.5px;
  height: 40px;
  background: #605bff;
  box-shadow: 4px 8px 24px 2px rgba(133, 133, 133, 0.08);
  border-radius: 3px;
  cursor: pointer;
  color: #fff;
  font-weight: 600;
}

.am010__timer-action-no .am010__action-register2 {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px 32px;
  gap: 8px;
  width: 224.5px;
  height: 40px;
  background: #ffffff;
  border: 1px solid #605bff;
  box-shadow: 4px 8px 24px 2px rgba(133, 133, 133, 0.08);
  border-radius: 3px;
  cursor: pointer;
  color: #605bff;
  font-weight: 600;
}

.am010__dropdown-menu {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  width: 160px;
  height: 120px;
  background: #ffffff;
  border-radius: 6px;
}

.am010__dropdown-menu-2 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;

  background: #ffffff;
  border-radius: 6px;
}

.am010__dropdown-menu-2 .am010__dropdown-menu-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 9px 16px;
  gap: 8px;

  width: 131px;
  height: 40px;

  /* Border/Line */

  border-bottom: 1px solid #dae2ee;
}

.am010__dropdown-menu .am010__dropdown-menu-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 9px 16px;
  gap: 8px;

  width: 160px;
  height: 40px;

  /* Border/Line */

  border-bottom: 1px solid #dae2ee;
}

.am010__dropdown-menu .am010__dropdown-menu-item img {
  margin-right: 10px;
}

.am010_form .ant-form-item-explain-error {
  font-size: 11px !important;
}

.am010__calendar-content ::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.03);
  border-radius: 10px;
  background-color: #f5f5f5;
}

.am010__calendar-content ::-webkit-scrollbar {
  width: 5px;
  height: 2px;
  background-color: #f5f5f5;
}

.am010__calendar-content ::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.03);
  background-color: rgb(182, 181, 181);
}

.item_garbage_category {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  width: 100%;
  height: 680px;

  /* Text/White */

  background: #ffffff;
  border-radius: 3px;

  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 1;
}
