.pagination-container-db .ant-pagination-item {
  background-color: #fff;
}
.pagination-container-db .ant-pagination-prev img {
  width: 16px;
  height: 16px;
}
.pagination-container-db .ant-pagination-next img {
  width: 16px;
  height: 16px;
}

.pagination-container-db {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 8px;
  position: relative;
}

.pagination-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 23px;
  position: relative;
}
.pagination-component-list {
  /* position: fixed; */
  align-items: center;
  justify-content: space-between;
  bottom: 36px;
  /* width: 81%; */
}

.pagination-total {
  text-align: right;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 22px;
  text-align: right;
  /* color: #999999; */
}

.pagination-size {
  box-shadow: none;

  /* width: 130px; */
  background: #fafafb;
}

.ant-select-selector:hover,
.ant-select-selector:focus-within {
  border-color: #605bff !important;
  box-shadow: none !important;
}

.ant-pagination-item :hover {
  color: #605bff !important;
}
.pagination-container .ant-pagination-item {
  background-color: #fff;
}
.pagination-container .ant-pagination-prev img {
  width: 16px;
  height: 16px;
}
.pagination-container .ant-pagination-next img {
  width: 16px;
  height: 16px;
}
.pagination-size .ant-select-selector {
  background-color: #edf0f4 !important;
}

.page-style {
  width: 38px;
  height: 38px;
  border: 1px solid #e9edf2;
  border-radius: 3px;
  text-align: center;
}

.min-pre-next {
  width: 24px;
  height: 24px;
  border: 1px solid #e9edf2;
  border-radius: 3px;
  text-align: center;
}

.mini-page-style .ant-pagination-prev {
  min-width: 24px;
  height: 24px;
}

.mini-page-style .ant-pagination-next {
  min-width: 24px;
  height: 24px;
}

.mini-page-style .ant-pagination-item {
  min-width: 24px;
  height: 24px;
}

.mini-page-style .ant-pagination-item a {
  line-height: 2;
}

.om-paging .ant-pagination-item {
  width: 40px !important;
  height: 40px !important;
  margin-top: 6px !important;
}
.om-paging .ant-pagination-item a {
  padding-top: 4px;
  font-weight: 400 !important;
  font-size: 14px !important;

  /* identical to box height, or 157% */

  /* Text/Main */

  color: #222222 !important;
}

.om-paging .ant-pagination-item-active a {
  font-weight: 700 !important;
  font-size: 14px !important;
  color: #ffffff !important;
}

.double-page {
  width: 38px !important;
  height: 38px !important;
  margin-top: 7px !important;
  border: 1px solid #e9edf2 !important;
  background-color: #ffffff !important;
}

.size-text {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  /* identical to box height, or 157% */

  /* Text/Main */

  color: #222222;
}

.db001-pagination .ant-pagination-next {
  display: none !important;
}

.db001-pagination .ant-pagination-prev {
  display: none !important;
}

.db001-pagination .ant-pagination-item {
  min-width: 38px;
  height: 38px;
}

.db001-pagination .ant-pagination-item a {
  line-height: 2.7;
  font-size: 14px;
}
