.am027_modal .header{
    color:  #222222;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    margin-left: 2px;
}

.am027_modal .body{
    color:  #222222;
    font-weight: 400;
    font-size: 13px;
    line-height: 22px;
    margin-top: 8px;
    margin-left: 40px;
}