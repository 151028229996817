
.aop001-table {
    max-width: 100%;
    border: 1px solid #dae2ee;
    border-radius: 3px;
}

.aop001-table tr:nth-child(odd){
    background-color: #FAFAFA;
}

.aop001-table tr:nth-child(even){
    background-color: #FAFAFA;
}
.aop001-table .ant-table-thead .ant-table-cell{
    border-bottom: 1px solid var(--border-line, #DAE2EE) !important;
    border-right: none !important;
}

.aop001-table .hide-checkbox .ant-checkbox-wrapper.ant-checkbox-wrapper-disabled {
    display: none !important;
}
.wrapper-action-aop001{
    display: flex;
    gap: 14px
}
.wrapper-action-aop001 img {
    cursor: pointer;
}

.aop001-table .ant-table-tbody .ant-table-cell {
    height: 66px !important;
}
.aop001-table .ant-table-tbody > tr.ant-table-row-selected > td{
    background: #FAFAFA !important;
}

.button-delete-aop001 .ant-btn:focus,
.button-delete-aop001 .ant-btn:hover {
  border: 1px solid rgba(255, 9, 9, 0.5) !important;
}

.button-delete-aop001 {
  border-radius: 3px !important;
  background-color: #DAE2EE !important;
  border: 1px solid rgba(255, 9, 9, 0.5) !important;
  color: #ff0909 !important;
}