.aop--001__unit-one {
    display: flex;
    flex-direction: column;

    width: 100%;
}

.aop--001__unit-one-title {
    display: flex;
    flex-direction: row;

    column-gap: 9px;
    padding: 15px 16px 15px 50px;
    align-items: center;
    background: #dae2ee;
}

.aop--001__unit-one-tt {
    font-size: 14px;
    font-weight: 700;
    line-height: 22px;
    color: #222;
}

.aop--001__unit-one-ti {
    margin-top: -4px;
    cursor: pointer;
}

.aop--001__unit-one .ant-table-cell {
    text-align: center;
}

.aop--001__unit-one .ant-table-tbody .ant-table-cell {
    color: #222 !important;
}

.aop--001__unit-one .ant-table-thead .ant-table-cell {
    font-weight: 700;
    color: #666 !important;
}

.aop--001__unit-one .ant-table-content table thead tr th {
    border-right: 1px solid #dae2ee !important;
}

.aop--001__unit-one .ant-table-content table tbody tr td {
    border-right: 1px solid #dae2ee !important;
    border-bottom: 1px solid #dae2ee !important;
}

.aop--001__unit-one tr:nth-child(odd) {
    background-color: #fff !important;
}

.aop--001__unit-one tr:nth-child(even) {
    background-color: #fafafa !important;
}
