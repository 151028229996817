.cm028-table {
  width: 100%;
  border: 1px solid #dae2ee;
  border-radius: 2px;
}

.am028-text-14 {
  font-weight: 700;
  font-size: 14px;
}

.am028-br {
  border-right: 1px solid #dae2ee;
}

.am028-bt {
  border-bottom: 1px solid #dae2ee;
}

.am028-text-child {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  text-align: center;
  padding-top: 15px;
}

.cm0028-title-table {
  display: flex;
  min-height: 52px;
  background-color: #f0f0f0;
  display: flex;
  min-height: 52px;
}

.cm0028-title-no {
  text-align: center;
  width: 110px;
  padding-top: 15px;
  color: #666666;
}

.cm0028-title-span {
  width: 820px;
  padding-top: 15px;
  color: #666666;
  padding-left: 120px;
}

.cm0028-title {
  text-align: center;
  width: 182px;
  padding-top: 15px;
  color: #666666;
}

.cm0028-title-table-parent {
  display: flex;
  min-height: 52px;
  background-color: #dae2ee;
  display: flex;
  min-height: 52px;
  margin-top: 3px;
}

.cm0028-title-no-parent {
  text-align: center;
  width: 110px;
  padding-top: 15px;
  color: #222222;
}

.cm0028-title-span-parent {
  width: 820px;
  padding-top: 15px;
  color: #222222;
  padding-left: 120px;
}

.cm0028-title-parent {
  text-align: center;
  width: 182px;
  padding-top: 15px;
  color: #222222;
}

.cm0028-title-table-child {
  display: flex;
  min-height: 52px;
  background-color: #fafafa;
  display: flex;
  min-height: 52px;
}

.cm0028-title-no-child {
  text-align: center;
  width: 110px;
  padding-top: 15px;
  color: #222222;
}

.cm0028-title-span-child {
  width: 820px;
  padding-top: 15px;
  color: #222222;
  padding-left: 120px;
}

.cm0028-title-child {
  text-align: center;
  width: 182px;
  padding-top: 15px;
  color: #222222;
}

.cm0028-title-table-total {
  display: flex;
  min-height: 52px;
  background-color: #605bff;
  display: flex;
  min-height: 52px;
}

.cm0028-title-no-total {
  text-align: center;
  width: 110px;
  padding-top: 15px;
  color: #ffffff;
}

.cm0028-title-span-total {
  width: 820px;
  padding-top: 15px;
  color: #ffffff;
  padding-left: 120px;
}

.cm0028-title-total {
  text-align: center;
  width: 182px;
  padding-top: 15px;
  color: #ffffff;
}

/* new */

.cm0028 {
  background-color: #fff;
  padding: 32px;
}
.cm0028-table {
  max-width: 100%;
  overflow: auto;
}

.cm0028-icon {
  cursor: pointer;
  width: 22px;
}

.icon-view {
  cursor: pointer;
}

.icon-view:hover {
  opacity: 0.8;
}

.cm0028-icon {
  transform: rotate(0deg);
  transition: all 0.3s, visibility 0s;
}

.cm0028-icon-active {
  transform: rotate(180deg);
}

.cm0028-header-filter {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.cm0028-header-filter-choose {
  display: flex;
}

.cm0028-btn-download {
  background: #dae2ee !important;
  border: 1px solid #605bff;
  width: 106px;
  height: 40px;
  border-radius: 6px !important;
  color: #605bff;
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin-left: 24px;
}

.cm0028-btn-submit {
  margin-left: 24px;
}
.cm0028-range {
  width: 210px;
}
.cm0028-range input {
  color: #605bff !important;
  font-weight: 400;
  font-size: 14px;
}

.cm0028-range .ant-picker-separator svg {
  color: #605bff;
}

.cm0028-header-title {
  margin-bottom: 24px;
  margin-top: 12px;
}

.cm0028-header-label {
  font-weight: 400;
  font-size: 14px;
  color: #666666;
}

.cm0028-header-content {
  font-weight: 700;
  font-size: 14px;
  color: #605bff;
  margin-left: 12px;
}

.cm0028-modal {
  padding-left: 300px;
}

.cm0028-modal-footer {
  display: flex;
  justify-content: flex-end;
  margin-top: 47px;
  padding-top: 24px;
  border-top: 1px solid #dae2ee;
}

.cm0028-modal-btn-ok {
  width: 120px;
}

.cm0028-modal-btn-cancel {
  width: 118px;
  color: #666;
  margin-left: 16px;
}

.cm0028-modal-header {
  margin: 24px 0 32px 0;
  display: flex;
}

.cm0028-modal-header-item {
  width: 450px;
  padding-left: 34px;
}

.cm0028-modal-label {
  font-weight: 400;
  font-size: 14px;
  color: #666666;
}

.cm0028-modal-title {
  color: #222222;
  font-weight: 400;
  font-size: 14px;
  margin-left: 24px;
}
