.icon-narrow {
    margin-left: 12px;
    cursor: pointer;
}

.icon-narrow:hover {
    opacity: 0.8;
}

th.td-border-top {
    border-top: 3px solid #605bff !important;
}
.cm026-modal-table {
    border-radius: 3px;
    border: 1px solid #dae2ee;
    overflow: hidden;
}
.cm026-modal-table table {
    width: 100%;
}

.cm026-modal-table table tr th,
.cm026-modal-table table tr td {
    padding: 15px;
    border-bottom: 1px solid #dae2ee;
}

.cm026-modal-table table thead tr th {
    background: #f0f0f0;
    color: #666666;
    font-weight: 700;
    font-size: 14px;
}

.cm026-modal-table table tbody tr td {
    color: #222222;
    font-weight: 400;
    font-size: 14px;
}

.cm026-modal-table table tfoot tr td {
    border-bottom: none;
    background-color: #605bff;
    color: #ffffff;
    font-weight: 700;
    font-size: 14px;
}

.th-narrow {
    display: flex;
    align-items: center;
    justify-content: center;
}

.cm0026-modal .ant-modal-header {
    border-bottom: none;
}

.cm0026-hr {
    background-color: #dae2ee;
    width: 100%;
    height: 1px;
    margin-top: -24px;
    margin-bottom: 24px;
}

.cm0026-modal .ant-modal-close {
    top: 10px;
    right: 5px;
}
