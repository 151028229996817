.pm005-pdf .react-pdf__Page__canvas {
  margin: 0 auto;
  width: 401px !important;
  height: 242px !important;
}
.image-document-container {
  overflow: auto;
  height: 706px;
  width: 1524px;
  display: flex;
}
.pdf-document-container {
  overflow: auto;
  height: 706px;
  width: 1524px;
  display: flex;
}
.pm005-pdf .react-pdf__Page__annotations {
  display: none !important;
}
.pm005-pdf .react-pdf__Page__textContent {
  display: none !important;
}

.pdf-document-container .react-pdf__Page__annotations {
  display: none !important;
}
.pdf-document-container .react-pdf__Page__textContent {
  display: none !important;
}

.pdf-preview .react-pdf__Page__textContent {
  display: none !important;
}
.pdf-preview .react-pdf__Page__annotations {
  display: none !important;
}
.footer-container {
  background: #404040;
  box-shadow: inset -1px 0px 0px #5c5c5c;
  border-radius: 3px 0px 0px 3px;
}

.name-file {
  position: relative;
}

.file-name-long {
  width: 120px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.icon-clear-file {
  position: absolute;
  top: -2px;
  background-color: #fff;
  right: 0;
  cursor: pointer;
  display: none;
}

.icon-clear-file img {
  width: 20px;
}

.name-file:hover .icon-clear-file {
  display: block;
}

.disabled-zoom {
  filter: invert(81%) sepia(0%) saturate(116%) hue-rotate(138deg)
    brightness(80%) contrast(84%);
}
