.login-container {
  height: 100vh;
  width: 100vw;
  display: flex;
}
.half-container {
  width: 50%;
  height: 100%;
}

.login-logo {
  background-image: url("../../images/login-logo.png");
  background-size: contain;
  background-color: #605bff;
  background-repeat: no-repeat;
}

.login-form {
  padding-right: 182px;
  padding-left: 182px;
  padding-top: 160px;
}

.change-password-message {
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 22px;
  /* identical to box height, or 169% */

  /* Text/White */

  color: #ffffff;
  /* Text / Primary */

  background: #605bff;
  border-radius: 3px;
  width: 100%;
  height: 28px;
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  .login-form {
    padding-top: 80px;
  }
}
/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  .login-form {
    padding-right: 50px;
    padding-left: 50px;
    padding-top: 80px;
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  .login-form {
    padding-right: 182px;
    padding-left: 182px;
    padding-top: 80px;
  }
}

/* Extra large devices (large laptops and desktops, 1600px and up) */
@media only screen and (min-width: 1600px) {
  .login-form {
    padding-right: 182px;
    padding-left: 182px;
    padding-top: 160px;
  }
}

.login-title {
  font-weight: 800;
  font-size: 24px;
  /* identical to box height, or 133% */

  /* Text/Main */

  color: #222222;
}

.login-forgot-button,
.login-forgot-button:hover {
  /* Body */

  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 22px;
  /* identical to box height, or 183% */

  text-align: right;

  /* Text / Primary */

  color: #605bff !important;
}

/* Input */
/*border: 1px solid #605BFF;
*/
.input-style .ant-input:hover {
  border-color: #605bff !important;
}

.input-style .ant-input-focused,
.input-style .ant-input:focus {
  border-color: #605bff !important;
  border: 1px solid #605bff !important;
  box-shadow: none;
}

.ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input,
.ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input:hover {
  background: #fff;
  border-color: #ff4d4f !important;
  box-shadow: none;
}

.input-password-style .ant-input-password:hover {
  border-color: #605bff !important;
}

.input-password-style .ant-input-affix-wrapper-focused,
.input-password-style .ant-input-affix-wrapper:focus {
  border-color: #605bff !important;
  border: 1px solid #605bff !important;
  box-shadow: none;
}

.ant-input-affix-wrapper-status-error {
  border-color: #ff4d4f !important;
}

.ant-input-affix-wrapper-status-error:not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper,
.ant-input-affix-wrapper-status-error:not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper:hover {
  background: #fff;
  border-color: #ff4d4f !important;
  box-shadow: none;
}

.change-password-note {
  font-weight: 400;
  font-size: 12px;
  text-align: right;
  margin-bottom: 48px;
  /* Text/Sub Text */

  color: #666666;
}

.ant-form-item-required {
  width: 100%;
}

.timer-title {
  font-weight: 700;
  font-size: 14px;
  color: rgba(96, 91, 255, 0.5);
}

.footer-font {
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  /* identical to box height, or 157% */

  display: flex;
  align-items: center;

  /* Text/White */

  color: #ffffff;
}
