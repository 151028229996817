.ord007-header-style {
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  color: #222222;
  background-color: #f0f0f0;
  height: 54px;
  display: flex;
  align-items: center;
  padding-left: 24px;
  width: 100%;
  border: 1px solid #dae2ee;
  border-top-right-radius: 2px;
  border-top-left-radius: 2px;
}

.ord007-cell-container {
  padding: 5px;
  min-height: 52px;

  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
  color: #222222;
  border-bottom: 1px solid #dae2ee;
  border-right: 1px solid #dae2ee;
}

.ord007-border-left {
  border-left: 1px solid #dae2ee;
}

.ord007 .cr001 .ant-input-number-input {
  padding-right: 8px !important;
}

.half-width {
  width: 50%;
}

.ten-percent-width {
  width: 10%;
}

.forty-percent-width {
  width: 40%;
}

.header-plan-user div {
  font-size: 14px;
  font-weight: 700;
  line-height: 22px;
  letter-spacing: 0em;
  color: #666666;
}
