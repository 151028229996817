.registration-container {
  background: #f4f7fa;
  border-radius: 6px;
  padding-top: 32px;

  margin-bottom: 8px;
}

.registration-title {
  font-weight: 600;
  font-size: 16px;
  line-height: 16px;
  /* identical to box height, or 150% */

  /* Text/Main */
  margin-bottom: 24px;
  color: #222222;
}

.certi-label {
  font-weight: 400;
  font-size: 14px;
  color: #666666;
}

.certi-content {
  font-weight: 400;
  font-size: 14px;

  color: #222222;
}

.group-detail-modal
  .ant-select-single.ant-select-lg:not(.ant-select-customize-input):not(
    .ant-select-customize-input
  )
  .ant-select-selection-search-input {
  height: 40px !important;
}

.modal-fixed-1572 .ant-modal-body {
  min-width: 1572px !important;
  background-color: white !important;
}
.modal-fixed-1572 .ant-modal-footer {
  min-width: 1572px !important;
  background-color: white !important;
}

.modal-fixed-1572 .ant-modal-header {
  min-width: 1572px !important;
  background-color: white !important;
}
.avatar-username {
  font-weight: 700;
  font-size: 18px;
  margin-left: 16px;
  color: #222222;
}

.form-label-member {
  font-weight: 400;
  font-size: 14px;
  color: #222222;
  align-items: center;
  text-align: right;
}

.group-detail-modal .ant-select-selection-placeholder {
  font-size: 14px !important;
}

.group-detail-modal .ant-select-selection-item {
  font-weight: 400 !important;
  font-size: 14px !important;
  color: #222222;
}

.cert-header {
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  /* identical to box height, or 157% */

  /* Text/Main */

  color: #222222;
  display: flex;
  align-items: center;
}

.input-item-container {
  min-height: 72px;
  width: 100%;
  display: flex;
  align-items: top;
  border-bottom: 1px solid #e9edf2;
}

.plus-label {
  font-weight: 700;
  font-size: 14px;
  line-height: 22px;
  /* identical to box height, or 157% */
  margin-top: 16px;
  /* Primary Color/Logo Brand */

  color: #605bff;
}

.form-label-certificate {
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  margin-top: 25px;
  color: #222222;
  margin-right: 24px;
}

.collapse-no-padding .ant-collapse-content-box {
  padding: 0px;
  margin: 0px;
}

.ml-40 {
  margin-left: 40px;
}

.collapse-no-bottom-border .ant-collapse-item {
  border-bottom: none !important;
}
.header-modal-containter {
  border-bottom: 1px solid #e9edf2 !important;
  display: flex;
  align-items: top;
  justify-content: space-between;
}
.header-modal-container-2 {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.no-margin-modal .ant-modal-body {
  padding-right: 0px !important;
  padding-left: 0px !important;
}

.header-modal-title-om004 {
  font-weight: 700;
  font-size: 18px;
  line-height: 26px;
  /* identical to box height, or 144% */

  /* Text/Main */

  color: #222222;
}
.input-item-container .ant-form-item-explain-error {
  margin-top: 3px !important;
  max-width: 165px !important;
  font-size: 8px !important;
}
.form-item-cert {
  margin-top: 16px !important;
}

.error-message-300 .ant-form-item-explain-error {
  width: 300px !important;
}

.plus-line {
  height: 62px;
  width: 100%;
  display: flex;
  align-items: top;
  border-bottom: 1px solid #e9edf2;
}

.group-detail-modal .ant-modal-footer {
  padding-top: 23px !important;
  padding-bottom: 23px !important;
}

.om-cancel-button {
  font-weight: 400 !important;
  font-size: 14px !important;
  line-height: 22px !important;
  color: #666666 !important;
  margin-left: 16px !important;
  width: 118px !important;
  border: "1px solid #E9EDF2" !important;
}

.group-detail-modal .ant-input {
  font-weight: 400 !important;
  font-size: 14px !important;
  line-height: 22px !important;
  color: #222222 !important;
}

.form-label-height-40 .ant-form-item-required {
  height: 40px !important;
}

.no-padding-collapse .ant-collapse-content-box {
  padding: 0px 0px !important;
}
