.ord--001__content {
    display: flex;
    flex-direction: column;
    row-gap: 32px;

    width: 100%;
    min-height: 780px;
    background: #fff;
    border-radius: 0px 0px 3px 3px;
    padding: 24px;
}

.ord--001__component-header {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    column-gap: 38px;

    height: 104px;
    background: #f4f7fa;
    border-radius: 6px;
    padding: 24px 32px;
}

.ord--001__component-header--unit {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    column-gap: 24px;
}

.ord--001__component-header--unit__text {
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    color: #222;
}

.ord--001__component-header--unit__option {
    width: 175px;
    background: #fff;
    border-radius: 6px;
    border: 1px solid #dae2ee;
}

.ord--001__component-header--unit__option .ant-select {
    width: 100%;
    padding: 4px 0px 4px 0px;
}

.ord--001__component-header--unit__option .ant-select-selector {
    padding-right: 10px !important;
    border: none !important;
}

.ord--001__component-header--unit__option .ant-select-selector:hover {
    border: none !important;
}

.ord--001__component-header--button {
    display: flex;
    flex-direction: row;
    column-gap: 16px;
}

.ord--001__component-button__one {
    display: flex;
    height: 40px;
    padding: 8px 24px;
    justify-content: center;
    align-items: center;
    gap: 8px;

    border-radius: 3px;
    border: 1px solid #605bff;
    background: #dae2ee;
    box-shadow: 4px 8px 24px 2px rgba(133, 133, 133, 0.08);

    font-size: 14px;
    font-weight: 700;
    line-height: 22px;
    color: #605bff;
    cursor: pointer;
}

.ord--001__component-button__two {
    display: flex;
    height: 40px;
    padding: 12px 24px;
    justify-content: center;
    align-items: center;
    gap: 10px;

    border-radius: 3px;
    border: 1px solid #dae2ee;
    background: #fff;

    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    color: #666;
    cursor: pointer;
}

.ord--001__component-table .ant-table-thead .ant-table-cell {
    font-size: 14px;
    font-weight: 700;
    color: #666;
}

.ord--001__component-table .ant-table-cell {
    text-align: center;
}

.ord--001__component-table .ant-table-content::-webkit-scrollbar {
    height: 8px;
}

.ord--001__component-table .ant-table-thead .ant-table-cell:first-child,
.ord--001__component-table .ant-table-tbody .ant-table-cell:first-child{
    border-right: none !important;
}
.container-table-ord001{
    min-height: 620px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.ord--001__table--operator {
    display: flex;
    flex-direction: row;
    column-gap: 16px;

    justify-content: center;
    align-items: center;
}

.ord--001__table--operator img {
    cursor: pointer;
}

.ord--001__component-table .ant-table-content table thead tr th {
    border-right: 1px solid #dae2ee !important;
}

.ord--001__component-table .ant-table-content table tbody tr td {
    border-right: 1px solid #dae2ee !important;
    border-bottom: 1px solid #dae2ee !important;
}

.ord--001__component-header .ant-form-item {
    margin-bottom: 0px !important;
}