.pm012-wrapper {
  display: flex;
  min-height: 70vh;
}
.pm012-empty-data {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}
.pm012-nav {
  max-height: 70vh;
  overflow-y: auto;
  padding-right: 4px;
  max-width: 242px;
  min-width: 242px;
  margin-right: -4px;
  background-color: #fff;
}

.pm012-nav::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.03);
  border-radius: 10px;
  background-color: #ffffff;
}

.pm012-nav::-webkit-scrollbar {
  width: 6px;
  background-color: #ffffff;
}

.pm012-nav::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.03);
  background-color: rgb(184, 184, 184);
}
.pm012-nav-item {
  border-radius: 3px;
  box-shadow: 5px 5px 20px rgba(184, 170, 201, 0.02);
  background: #ffffff;
  padding: 0 16px;
}

.pm012-nav .ant-collapse-header {
  border-bottom: none;
}

.pm012-nav .ant-collapse-item:first-child .ant-collapse-header {
  border-top: 1px solid #e9edf2;
}

.pm012-nav .ant-collapse > .ant-collapse-item {
  border: 1px solid #e9edf2;
  margin-top: 16px;
  box-shadow: 5px 5px 20px rgba(184, 170, 201, 0.12);
  border-radius: 3px;
}

.pm012-nav .ant-collapse > .ant-collapse-item:first-child {
  border-top: none;
}

.pm012-nav .ant-collapse {
  background-color: #fff;
}

.pm012-nav .ant-collapse-content {
  border-top: none;
}

.pm012-nav-item-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.pm012-nav-item-header h3 {
  margin: 0;
  font-weight: 700;
  font-size: 18px;
  line-height: 26px;
  color: #222222;
}

.pm012-nav-item-header img:hover {
  cursor: pointer;
  opacity: 0.8;
}

.pm012-nav-item-content-company {
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #222222;
  margin: 6px 0;
  padding: 4px 12px;
  border-radius: 3px;
}

.item-text-long {
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.item-text-long-header {
  width: 86%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.pm012-nav-item-content-company:hover {
  cursor: pointer;
  background-color: #f7f3ff;
}

.pm012-nav-item-content-company-active {
  background-color: #e7e6ff;
}
.pm012-nav-item-content-company-active:hover {
  background-color: #e7e6ff;
}

.pm012-nav-item-content {
  list-style: none;
  padding-left: 0;
}

.pm012-nav .ant-collapse {
  border: none;
}

.pm012-nav .ant-collapse-item {
  background-color: #fff;
}

.pm012-nav .ant-collapse-content-box {
  padding: 0;
  background-color: #fff;
}

.pm012-btn-add-company {
  background-color: transparent;
  border: none;
  cursor: pointer;
}
.pm012-btn-add-company:hover {
  opacity: 0.8;
}

.pm012-content {
  padding-left: 24px;
  width: 100%;
}

.pm012-wrapper {
  width: 100%;
}

.pm012-input-search {
  height: 40px;
  background-color: #e9edf2;
  border-radius: 3px;
  border: 1px solid #e9edf2;
  box-shadow: 8px 8px 8px rgba(153, 153, 153, 0.02);
}

.pm012-input-search .ant-input {
  background-color: #e9edf2;
}

.pm012-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.pm012-header-right {
  display: flex;
  align-items: center;
  margin-left: 16px;
}

.pm012-btn-delete {
  min-width: 106px;
  background-color: #e9edf2 !important;
}

.pm012-btn-add {
  min-width: 176px;
  margin-right: 16px;
}

.pm012-btn-flex {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.pm012-btn-filter {
  border: none;
  background-color: #e9edf2;
  border-radius: 3px;
  width: 42px;
  height: 40px;
  cursor: pointer;
}

.pm012-table {
  margin-top: 24px;
}

.pm012-table .ant-table-column-sorters {
  justify-content: unset;
}

.pm012-table .ant-table-column-title {
  flex: unset;
}

.pm012-body {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  padding-bottom: 26px;
}

.pm012-text-purple {
  color: #605bff;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
}

.pm012-footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.pm012-footer-text {
  font-weight: 600;
  font-size: 16px;
  color: #605bff;
}

.pm012-header-left {
  width: 100%;
}

.pm012-header-left-other {
  display: flex;
  align-items: center;
  width: 85%;
}

.pm012-header-left-other .pm012-input-search {
  width: 100%;
}

.pm012-header-left-other .pm012-btn-filter {
  margin-left: 16px;
}

.pm012-content-padding-none {
  padding: 0;
}

.pm012-btn-filter {
  position: relative;
}

.pm012-filter-dropdown {
  position: absolute;
  background-color: #fff;
  list-style: none;
  z-index: 10;
  width: 215px;
  right: -5px;
  top: calc(100% + 5px);
  border-radius: 3px;
  box-shadow: 0px 9px 28px 8px rgba(0, 0, 0, 0.05),
    0px 3px 6px -4px rgba(0, 0, 0, 0.12);
  filter: drop-shadow(0px 6px 16px rgba(0, 0, 0, 0.08));
  padding: 0;
  padding: 4px 0;
  display: none;
  animation: identifier linear 0.1s;
  transform-origin: top right;
}

.pm012-filter-dropdown:after {
  position: absolute;
  content: "";
  width: 0px;
  height: 0px;
  border-top: 15px solid transparent;
  border-right: 15px solid transparent;
  border-bottom: 15px solid #fff;
  border-left: 15px solid transparent;
  bottom: calc(100% - 4px);
  right: 10px;
}
.pm012-filter-dropdown::before {
  position: absolute;
  content: "";
  width: 100%;
  height: 20px;
  bottom: calc(100% - 4px);
  right: 0px;
}

.pm012-filter-dropdown-item {
  border-bottom: 1px solid #e9edf2;
  padding: 10px 0;
  text-align: center;
  font-weight: 400;
  font-size: 14px;
}

.pm012-filter-dropdown-item:hover {
  background-color: #f6f6fe;
}

.pm012-filter-dropdown-item-active {
  background-color: #e8e8ff;
}
.pm012-filter-dropdown-item-active:hover {
  background-color: #e8e8ff;
}

.pm012-filter-dropdown-item:last-child {
  border: none;
}

.pm012-btn-filter:hover .pm012-filter-dropdown {
  display: block;
}

.pm012-table .ant-checkbox-disabled.ant-checkbox-checked .ant-checkbox-inner {
  background-color: #999999 !important;
}

.pm012-table .ant-checkbox-disabled .ant-checkbox-inner:hover {
  border: 1px solid #d9d9d9 !important;
}

.pm012-table
  .ant-checkbox-disabled.ant-checkbox-checked
  .ant-checkbox-inner::after {
  border-color: #fff;
}

.pm012-nav .ant-collapse-item:first-child {
  margin-top: 0;
}

.nav-collapse-icon {
  transition: all 0.3s, visibility 0s;
  transform: rotate(360deg);
}

.nav-collapse-icon-active {
  transform: rotate(270deg);
}

.pm012-nav-share-member {
  box-shadow: 4px 8px 24px 2px rgba(133, 133, 133, 0.08);
  border-radius: 6px;
  border: 1px solid #605bff;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #605bff;
  font-weight: 700;
  font-size: 14px;
  height: 40px;
  margin-bottom: 12px;
  margin-left: -28px;
  margin-right: 6px;
}

.pm012-nav .ant-collapse > .ant-collapse-item > .ant-collapse-header {
  align-items: flex-end;
}

.pm012-nav .ant-collapse-header-text {
  width: 90%;
}
.pm012-nav .ant-collapse-expand-icon {
  margin-bottom: 4px;
  cursor: pointer;
}

.animation-shake {
  animation: shake 0.5s;
  animation-iteration-count: infinite;
}
.action-double-check {
  display: flex;
  align-items: center;
  transition: all linear 0.1s;
}

.action-double-check-active {
  box-shadow: rgba(0, 0, 0, 0.56) 0px 22px 70px 4px;
  transform: scale(1.4);
  background-color: #f0f0f0;
  border-radius: 4px;
}

@keyframes identifier {
  from {
    transform: scale(0);
    opacity: 0.6;
  }
  to {
    transform: scale(1);
    opacity: 1;
  }
}

@keyframes shake {
  0% {
    transform: translate(1px, 1px) rotate(0deg);
  }
  10% {
    transform: translate(-1px, -2px) rotate(-1deg);
  }
  20% {
    transform: translate(-3px, 0px) rotate(1deg);
  }
  30% {
    transform: translate(3px, 2px) rotate(0deg);
  }
  40% {
    transform: translate(1px, -1px) rotate(1deg);
  }
  50% {
    transform: translate(-1px, 2px) rotate(-1deg);
  }
  60% {
    transform: translate(-3px, 1px) rotate(0deg);
  }
  70% {
    transform: translate(3px, 1px) rotate(-1deg);
  }
  80% {
    transform: translate(-1px, -1px) rotate(1deg);
  }
  90% {
    transform: translate(1px, 2px) rotate(0deg);
  }
  100% {
    transform: translate(1px, -2px) rotate(-1deg);
  }
}
