.btn-redirect-cm001 {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 15px 0px;
  padding-left: 16px;
  gap: 8px;
  width: 120px;
  height: 40px;
  background: #605bff;
  border: 1px solid #605bff;
  border-radius: 6px;
  margin-top: 16px;
  cursor: pointer;
}

.btn-redirect-cm001 span {
  width: 56px;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  color: #ffffff;
}

.content-container {
  width: 100%;
  background-color: #ffffff;
  padding: 24px;
}

.avatar-text {
  font-weight: 600;
  font-size: 15.4286px;
  /* identical to box height, or 150% */

  /* Text/White */
  background: #545365;
  color: #ffffff;
}

.sagamihara {
  font-weight: 600;
  font-size: 16px;
  /* identical to box height, or 150% */

  /* Text/Main */

  color: #222222;
}

.data-center {
  font-weight: 400;
  font-size: 13px;
  /* identical to box height, or 169% */

  /* Text/Sub Text */

  color: #666666;
}

.basic-content {
  /* Primary Color/Background */
  width: "100%";
  background: #f8f8f8;
  border-radius: 6px;
}

.basic-content-title {
  font-weight: 600;
  font-size: 16px;
  color: #222222;
  padding: 24px;
}

.pm005-map {
  overflow: hidden;
  float: right;
  margin-right: 16px;
  display: flex;
  justify-content: end;
  align-items: center;
  height: 100%;
}

.info-title {
  font-weight: 400;
  font-size: 14px;
  color: #666666;
}

.mb-8 {
  margin-bottom: 8px;
}

.mb-title {
  color: #666666;
  font-weight: 400;
  font-size: 14px;
  min-width: 16%;
}

.mb-text {
  font-weight: 400;
  font-size: 14px;
  color: #222222;
}

.info-content {
  font-weight: 400;
  font-size: 14px;
  color: #222222;
}

.pm005-info-content-item {
  display: flex;
  align-items: flex-start;
}

.build-register {
  font-weight: 600;
  font-size: 16px;
  color: #222222;
  margin-top: 32px;
  margin-bottom: 24px;
}

.build-register-container {
  width: 100%;
  background: #fff;
  border-radius: 6px;
}

.building-tag {
  font-weight: 500;
  font-size: 12px;
  color: #0fa44a;
  width: 64px;
  height: 22px;

  background: #d1f2de;
  border-radius: 6px;
  padding-left: 8px;
  padding-right: 8px;
  text-align: center;
  margin-left: 16px;
}

.tag-contaner {
  display: flex;
  padding-top: 17px;
  padding-bottom: 17px;
  padding-left: 24px;
  align-items: center;
}
.tag-white-container {
  width: fit-content;
  font-weight: 400;
  font-size: 14px;
  color: #222222;
  padding: 4px 16px;
  height: 30px;
  background: #ffffff;
  border: 1px solid #e9edf2;
  border-radius: 6px;
  margin-right: 16px;
}

.tag-contaner-white {
  padding-left: 24px;
  padding-right: 24px;
  display: flex;
}

.folder-container {
  padding-top: 8px;
  padding-left: 0px;
  /* animation: collapseKeyFrame linear 0.2s; */
  transform-origin: top center;
  transform: scale(1, 0);
  opacity: 0;
  transition: all linear 0.2s 0.01s;
  height: 0;
  border: 1px solid #e9edf2;
  border-top: none;
}

.folder-container-active {
  opacity: 1;
  transform: scale(1, 1);
  height: 100%;
}

.tag-contaner-folder {
  padding: 4px 16px;

  display: flex;
}

.folder-collapse {
  width: 100%;
  background: #f8f8f8;
  /* Border/Line */
  border-color: #e9edf2;
  border-radius: 6px 6px;
}

.folder-collapse .ant-collapse-header {
  height: 56px !important;
  display: flex !important;
  align-items: center !important;
}

.add-construction {
  font-weight: 700;
  font-size: 14px;
  color: #605bff;
  margin-bottom: -3px;
}

.folder-collapse .ant-collapse-header {
  flex-direction: row-reverse !important;
}

.folder-collapse .ant-collapse-item-active svg {
  transform: rotate(270deg) !important;
}

.folder-collapse svg {
  transform: rotate(90deg) !important;
}

.folder-collapse-child .ant-collapse-item svg {
  transform: rotate(90deg) !important;
}

.folder-collapse-child .ant-collapse-item-active svg {
  transform: rotate(270deg) !important;
}

.folder-collapse .ant-collapse-item-active .ant-empty-image svg {
  -webkit-transform: none !important;
  transform: none !important;
}

.ant-empty-image svg {
  -webkit-transform: none !important;
  transform: none !important;
}

.folder-collapse-child svg {
  transform: rotate(90deg) !important;
}

.avatar-plus {
  background: linear-gradient(90deg, #0fa44a 0%, #21e2b4 100%);
}

.pm005-btn-view {
  cursor: pointer;
  display: flex;
  align-items: center;
}

.pm005-btn-view:hover {
  background-color: rgba(0, 0, 0, 0.06);
}
.pm005-status-approve {
  padding: 2px 4px;
  border-radius: 3px;
  text-align: center;
  margin-left: 8px;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
}

.pm005-btn-edit-construction {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 15px;
  z-index: 9999;
}

.tag-wrapper {
  display: flex;
  align-items: center;
  color: rgba(0, 0, 0, 0.85) !important;
}

.tag-btn-edit {
  border: none;
  margin-left: 12px;
  cursor: pointer;
  background-color: inherit;
}

.tag-btn-edit-icon {
  width: 20px;
  opacity: 0.8;
  margin-bottom: 2px;
}

.tag-btn-edit-icon:hover {
  opacity: 1;
}

.pm005-item-collapse-name {
  display: block;
}

.tag-form-item {
  margin-bottom: 0;
}

.tag-btn-save {
  margin-left: 20px;
}

.tag-btn-save-wrapper {
  display: flex;
  align-items: center;
}

.btn-rote {
  transform: rotate(92deg);
}

.pm005-note-save-text {
  font-size: 14px;
  font-weight: 700;
  color: #605bff;
  cursor: pointer;
}

.pm005-note-cancel-text {
  font-weight: 400;
  font-size: 14px;
  color: #666;
  margin-left: 24px;
  cursor: pointer;
}

.pm005-note-text {
  font-weight: 400;
  font-size: 14px;
  color: #222222;
  display: block;
  line-break: loose;
}

.pm005-input-note {
  margin: 0;
}

.pm005-note {
  margin: 0 0 4px 10px;
  position: relative;
}

.pm005-note-wrapper {
  position: absolute;
  min-width: 250px;
  max-width: 350px;
  background: #ffffff;
  border-radius: 6px;
  box-shadow: 0px 5px 30px 2px rgba(0, 0, 0, 0.1);
  filter: drop-shadow(2px 4px 20px rgba(0, 0, 0, 0.1));
  padding: 8px 16px;
  z-index: 9999;
  top: calc(100% + 14px);
  left: calc(100% - 34px);
  cursor: default;
}

.pm005-note-edit {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.pm005-note-edit span {
  color: #605bff;
  font-weight: 700;
  font-size: 14px;
}

.pm005-note-btn {
  display: flex;
  align-items: center;
}

.pm005-note-delete span {
  color: #ff0909;
  font-weight: 400;
  font-size: 14px;
}
.pm005-note-icon-delete {
  width: 14px;
  margin-right: 8px;
}

.pm005-note-delete {
  display: flex;
  align-items: center;
  margin-left: 34px;
  cursor: pointer;
}

.pm005-note-icon-edit {
  width: 18px;
  margin-right: 8px;
}

.pm005-note-divider {
  margin: 8px 0;
}

.mp005-tooltip {
  width: 22px;
  height: 22px;
  cursor: pointer;
}

.pm005-note-wrapper:after {
  position: absolute;
  content: "";
  width: 0px;
  height: 0px;
  border-top: 15px solid transparent;
  border-right: 15px solid transparent;
  border-bottom: 15px solid #fff;
  border-left: 15px solid transparent;
  bottom: calc(100% - 4px);
  left: 10px;
}

.pm005-note-wrapper:before {
  content: "";
  position: absolute;
  width: 100%;
  background-color: transparent;
  height: 46px;
  left: 0;
  bottom: calc(100% - 4px);
}

.pm005-view-img img {
  max-width: 300px;
  max-height: 30vh;
}

.pm005-modal-img.ant-modal {
  top: 10px;
}

.pm005-modal-img img {
  width: 100%;
}

.pm005-modal-img-wrapper {
  position: relative;
  min-height: 82vh;
  text-align: center;
  line-height: 70vh;
}

.pm005-modal-img-zoom {
  position: absolute;
  top: calc(100% - 80px);
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  align-items: center;
  background-color: #404040;
  width: 480px;
  height: 54px;
  border-radius: 3px;
  overflow: hidden;
}
.pm005-modal-img-zoom-left {
  width: 50%;
  border-right: 1px solid #5c5c5c;
  height: 54px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 700;
  font-size: 14px;
  color: #ffffff;
}

.pm005-modal-img-zoom-right {
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 50%;
}
.pm005-modal-img-zoom-right button {
  background-color: inherit;
  border: none;
  cursor: pointer;
}

.pm005-modal-img-zoom-right button img {
  margin-top: 0;
}

.pm005-member-cooperation {
  height: 40px;
  border-radius: 6px !important;
  border-color: #605bff;
  color: #605bff;
}

.button-edit-project {
  border-radius: 6px !important;
}

.pm005-modal-member-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 72vh;
}

.pm025-modal-member-footer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  border-top: 1px solid #e9edf2;
  padding-top: 24px;
}

.pm025-modal-member-footer button {
  margin-left: 16px;
}

.pm005-modal-member {
  top: 50px;
  margin-left: 25%;
}

.pm005-modal-member-body {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 64vh;
}

.pm005-modal-member-title {
  font-weight: 600;
  font-size: 16px;
  color: #605bff;
}

.pm005-modal-member-pagination {
  margin: 0 -24px;
}
.avatar-list {
  display: flex;
  align-items: center;
}
.avatar-item {
  width: 54px;
  height: 54px;
  margin-left: -10px;
  position: relative;
}

.avatar-item img {
  border-radius: 50%;
  border: 2px solid #ffffff;
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.overlap-img {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  color: rgba(255, 255, 255, 0.8);
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6));
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  font-weight: 700;
  font-size: 18px;
  border: 2px solid #ffffff;
}

.avatar-btn {
  background: linear-gradient(90deg, #0fa44a 0%, #21e2b4 100%);
  border: 2px solid #0ed09d;
  width: 54px;
  height: 54px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  margin-left: -10px;
  z-index: 2;
  cursor: pointer;
}

.pm005-avatar {
  display: flex;
  justify-content: flex-end;
}

.pm005-collapse-border .ant-collapse-content {
  border-top: 1px solid #e9edf2;
}

.pm005-collapse-border .ant-collapse > .ant-collapse-item {
  border-bottom: none;
}

.pm005-collapse-border .folder-collapse {
  border: 1px solid #e9edf2;
}

.pm005-document-view {
  background: #f8f8f8;
  border-radius: 0px 0px 6px 6px;
}

.folder-collapse .ant-collapse-item-active .note-transform svg {
  transform: rotate(0deg) !important;
}
.pm005-modal-img .ant-modal-title {
  width: 95%;
}
