.sw01-wrapper {
    height: 100%;
    display: flex;
    flex-direction: column;
}
.sw01-header {
    font-weight: 800;
    font-size: 24px;
    color: #222222;
    margin: 8px 0 20px 0;
}

.sw01-body {
    background: #ffffff;
    width: 100%;
    box-shadow: 8px 8px 8px rgba(153, 153, 153, 0.02);
    border-radius: 3px;
    padding: 24px;
    flex: 1;
}

.sw01-body .ant-form-item {
    margin: 0;
}

.sw01-item {
    display: flex;
    align-items: center;
    height: 54px;
    border-bottom: 1px solid #dae2ee;
    border-left: 1px solid #dae2ee;
    border-right: 1px solid #dae2ee;
    padding: 0 24px;
}

.sw01-item-header {
    background-color: #f0f0f0;
}

.sw01-item-header-child {
    background-color: #fafafa;
}

.sw01-item:first-child {
    border-top: 1px solid #dae2ee;
    border-radius: 3px 3px 0px 0px;
}

.sw01-item:last-child {
    border-radius: 0px 0px 3px 3px;
}

.sw01-item-left {
    width: 45%;
    font-weight: 400;
    font-size: 14px;
    color: #222222;
}
.sw01-item-right {
    width: 30%;
}

.sw01-item-title {
    font-weight: 700;
    font-size: 14px;
    color: #222222;
}

.sw01-select {
    height: 40px;
}

.sw01-select .ant-select-selector {
    height: 40px !important;
    border: 1px solid #dae2ee !important;
    border-radius: 3px;
}

.sw01-option {
    border-bottom: 1px solid #dae2ee;
    padding: 8px 16px;
}

.sw01-option .ant-select-item-option-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.sw01-select .ant-select-selection-item span {
    line-height: 40px;
}

.ant-select-selector .ant-select-selection-item img {
    display: none;
}

.sw01-select-add {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 8px 16px;
}

.sw01-select-add:hover {
    cursor: pointer;
    background-color: #f5f5f5;
}

.sw01-select .ant-select-selection-item {
    line-height: 40px !important;
}
.ant-select-item-option-active.sw01-option:not(.ant-select-item-option-disabled) {
    background-color: #fff;
}
.ant-select-item-option-active.sw01-option:not(.ant-select-item-option-disabled):hover {
    background-color: #f5f5f5;
}

.ant-select-item-option-selected.sw01-option:not(.ant-select-item-option-disabled) {
    background-color: #f3f0ff;
}
