.am005-collapse .ant-collapse-header {
    background-color: #f5f5f5;
}

.am005-collapse {
    margin-top: 16px;
}

.ant-collapse.am005-collapse > .ant-collapse-item,
.ant-collapse.am005-collapse .ant-collapse-content {
    border-color: #e9edf2;
}

.am005-collapse:first-child {
    margin-top: 0;
}

.am005-time {
    margin: 25px 25px 0 0;
    color: #222222;
    font-weight: 700;
    font-size: 14px;
}

.am005-construction-panel-header,
.am005-my-company {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.am005-construction-panel-header {
    height: 32px;
    line-height: 32px;
}

.am005-construction-panel-header-right {
    display: flex;
    align-items: center;
    margin-right: 64px;
    width: 600px;
    margin-right: -41px;
}

.am005-header-item {
    display: flex;
    align-items: center;
    width: 50%;
}

.am005-separator {
    height: 22px;
    width: 1px;
    background-color: rgba(96, 91, 255, 0.5);
    margin: 0 64px;
}

.am005-text-bold-14 {
    color: #222222;
    font-weight: 700;
    font-size: 14px;
}

.am005-text-bold-16 {
    color: #222222;
    font-weight: 600;
    font-size: 16px;
}

.am005-my-company-right {
    margin-right: 96px;
}

.am005-my-company {
    padding: 16px;
}

.am005-collapse .ant-collapse-content-box {
    padding: 0;
}

.flex-box {
    display: flex;
    align-items: center;
}

.am005-cooperation {
    padding: 16px;
    background: #dae5f5;
    justify-content: space-between;
    padding-right: 110px;
    margin-bottom: 2px;
}
.am005-cooperation:last-child {
    margin-bottom: 0;
}

.am005-cooperation-right {
    min-width: 600px;
    margin-right: -104px;
}

.am005-subConstruction-left {
    min-width: 600px;
    margin-right: -104px;
}

.am005-subConstruction-left__item {
    display: flex;
    align-items: center;
    width: 100%;
}

.am005-level {
    border-radius: 6px;
    background: rgba(65, 143, 235, 0.12);
    height: 22px;
    padding: 0 8px;
    font-weight: 700;
    font-size: 12px;
    color: #418feb;
    line-height: 22px;
    margin-left: 16px;
    white-space: nowrap;
}

.am005-cooperation-left-text {
    color: #666666;
    margin-left: 16px;
    font-weight: 400;
    font-size: 14px;
}

.folder-collapse.am005-collapse .ant-collapse-item-active svg {
    transform: rotate(0) !important;
}

.folder-collapse.am005-collapse .ant-collapse-item svg {
    transform: rotate(0) !important;
}

.am005-subConstruction {
    padding: 16px;
    justify-content: space-between;
    padding-right: 110px;
    margin-bottom: 2px;
    background-color: #fafafa;
}

.am005-subConstruction-text {
    font-weight: 400;
    font-size: 14px;
    color: #222222;
    margin-left: 20px;
    max-width: 400px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    display: block;
}

.ml-8 {
    margin-left: 8px;
}

.bg-level {
    background: #f2f6fc;
}

.project-item-avt .ant-avatar {
    background-color: #e7e6ff;
}

.project-item-avt .ant-avatar-string {
    color: #666666;
}

.chosen .ant-avatar {
    background: linear-gradient(270deg, #2ec5c1 0%, #119cd3 100%);
}

.chosen .ant-avatar-string {
    color: #ffffff;
}

.am005-text-long {
    max-width: 400px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.am005-cursor {
    cursor: pointer;
}

.btn-export {
    border-radius: 6px !important;
    width: 90px;
}

.btn-collapse {
    border-radius: 0 3px 3px 0;
}

.am005-project {
    height: 40vh;
    overflow-y: auto;
}

.am005-project-min-scroll::-webkit-scrollbar {
    width: 6px;
    height: 0px;
}

.am005-project-min {
    min-height: 464px;
    border-bottom: 1px solid rgb(218, 226, 238)
}

.searchbar-container .ant-input-affix-wrapper {
    border: 1px solid #dae2ee;
}

.date-picker-border {
    border: 1px solid #dae2ee;
}

.am005-total-wrapper {
    display: flex;
    align-items: center;
    gap: 0 40px 
}