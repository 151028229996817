.search-bar-om001 {
  background: #e7eaee !important;
  border-radius: 3px !important;
}

.search-bar-om001 .ant-input {
  background: #e7eaee !important;
}

.vertical-center {
  width: 100%;
  margin: 0;
  position: absolute;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.item-to-right {
  margin-left: auto;
  margin-right: 0;
}
.button-FFFFFF .ant-btn:focus,
.button-FFFFFF .ant-btn:hover {
  border: 1px solid rgba(96, 91, 255, 0.5) !important;
}

.button-FFFFFF {
  border-radius: 3px !important;
  background-color: #ffffff !important;
  border: 1px solid rgba(96, 91, 255, 0.5) !important;
  color: #605bff !important;
}

.button-E9EDF2 .ant-btn:focus,
.button-E9EDF2 .ant-btn:hover {
  border: 1px solid rgba(96, 91, 255, 0.5) !important;
}

.button-E9EDF2 {
  border-radius: 3px !important;
  background-color: #e9edf2 !important;
  border: 1px solid rgba(96, 91, 255, 0.5) !important;
  color: #605bff !important;
}

.button-E9EDF2-red-border .ant-btn:focus,
.button-E9EDF2-red-border .ant-btn:hover {
  border: 1px solid rgba(255, 9, 9, 0.5) !important;
}

.button-E9EDF2-red-border {
  border-radius: 3px !important;
  background-color: #e9edf2 !important;
  border: 1px solid rgba(255, 9, 9, 0.5) !important;
  color: #ff0909 !important;
}

.button-fcfcfc .ant-btn:focus,
.button-fcfcfc .ant-btn:hover {
  border: 1px solid #fcfcfc !important;
}

.button-fcfcfc {
  border-radius: 3px !important;
  background-color: #fcfcfc !important;
  border: 1px solid #fcfcfc !important;
}

.button-E9EDF2-no-border .ant-btn:focus,
.button-E9EDF2-no-border .ant-btn:hover {
  border: none !important;
}

.button-E9EDF2-no-border {
  border-radius: 3px !important;
  background-color: #e9edf2 !important;
  border: none !important;
}

.group-list-title {
  font-weight: 700;
  font-size: 18px;
  color: #222222;
  padding-left: 16px;
  padding-top: 24px;
  padding-bottom: 16px;
  border-bottom: 1px solid #dae2ee;
  background-color: #fcfcfc;
}

.group-list-title-text {
  font-weight: 700;
  font-size: 18px;
  color: #222222;
}
.group-item {
  font-weight: 400;
  font-size: 14px;
  color: #666666;
  padding-right: 16px;
  padding-left: 16px;
  padding-top: 21px;
  padding-bottom: 21px;

  background: #fcfcfc;
  border-bottom: 1px solid #dae2ee;
  cursor: pointer;
}

.center-vertical {
  margin: auto;
  margin-left: 0px !important;
}

.group-list-add-container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row-reverse;
  padding-left: 16px !important;
  padding-right: 16px !important;
  position: absolute;
  bottom: 24px;
}

.group-list-add {
  height: 46px !important ;
  width: 100% !important;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row-reverse;
}

.group-list-container {
  height: 64vh;
  overflow: scroll;
  overflow-x: hidden;
  overflow-y: auto;
}

/* For 1024 Resolution */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
  .group-list-container {
    height: 59vh;
  }
}

@media only screen and (min-width: 1370px) and (max-width: 1605px) {
  .group-list-container {
    height: 63vh;
  }
}

.member-table {
  position: relative;
  padding-top: 32px;
  padding-right: 24px;
  padding-left: 24px;
}

.choosen-group {
  background: #605bff !important;
  box-shadow: inset 2px 0px 12px #3833d6 !important;
  color: #ffffff !important;
  font-weight: 400;
  font-size: 14px;
  border-radius: 3px !important;
}

.choosen-group-btn .ant-btn {
  background-color: transparent !important;
  border-color: transparent !important;
}
.om002-pagination .pagination-total {
  color: #222222 !important;
}

.group-list {
  border-right: 1px solid #dae2ee;
  position: relative;
  box-shadow: -2px 0px 12px rgba(83, 83, 83, 0.05) !important;
  background: #fcfcfc !important;
}

.group-member-container {
  box-shadow: 8px 8px 8px rgba(153, 153, 153, 0.02) !important;
  margin-top: 32px !important;
  background-color: #ffffff !important;
  height: 84.5vh !important;
}

.group-title {
  font-weight: 700;
  font-size: 14px;
  line-height: 22px;
}

.ellipsis-active {
  color: #700 !important;
}

.text-eliipsis {
  text-overflow: ellipsis;
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
}

.dropdown-item {
  height: 42px;
  margin-right: 15px;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: #222222;
  text-align: center;
}

.dropdown-item-chosen {
  height: 42px;
  margin-right: 15px;
  font-weight: 700;
  font-size: 14px;
  line-height: 22px;
  color: #222222;
  text-align: center;
}

.om002-header-container
  .ant-dropdown-menu-item
  .ant-dropdown-menu-item-only-child {
  margin: 0px !important;
  padding: 0px !important;
  border: 1px solid #e9edf2 !important;
}

.om002-header-container .ant-dropdown-menu-item-selected {
  background-color: #e8e8ff !important;
}

.pr-16 {
  padding-right: 16px;
}
