.header-modal-title-cm0062 {
    font-weight: 700;
    font-size: 18px;
    line-height: 26px;
    color: #222222;
}

.cm0062-content .content-description{
    display: flex;
    margin-bottom: 40px;
}

.cm0062-content .content-description .content-btn{
    width: 20%;
    display: flex;
    justify-content: end;
}
.cm0062-content .content-description .content-description-text{
    display: flex;
    width: 80%;
}

.cm0062-content .content-description .content-description-text .description-col{
    display: flex;
    margin-right: 64px;
    align-items: center;
}

.cm0062-content .content-description .content-description-text .description-col label{
    margin-right: 24px;
    color:#666666;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
}

.cm0062-content .content-description .content-description-text .description-col p{
    margin-right: 24px;
    color:#222222;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    margin: 0;
}

.cm0062-content .content-table .table-bot .ant-table-container .ant-table-content .ant-table-thead .ant-table-cell{
    background-color: #F0F0F0 !important;
}

.cm0062-content .content-table .table-bot{
    margin-bottom: 80px;
}

.cm0062-content .content-table .table-bot .ant-table-container .ant-table-content .ant-table-tbody .last-row .ant-table-cell{
    background-color: #605BFF;
    height: 52px;
    border: none;
    color:#FFFFFF !important;
}

.cm0062-content .content-table .table-bot .ant-table-container .ant-table-content .ant-table-summary .children-table-summary .ant-table-cell{
    background-color: #605BFF !important;
    height: 52px;
    border: none;
    color:#FFFFFF !important;
}