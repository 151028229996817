.modal-label {
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;

  color: #666666;
}

.button-style {
  cursor: pointer;
}
