.ant-modal-header {
  border-bottom: 1px solid #dae2ee;
}

.ord002_1 .ant-form-item {
  margin: 0;
}

.ord-form-item {
  display: flex;
  width: 100%;
  margin-bottom: 16px;
}

.ord-form-label {
  color: #222;
  text-align: right;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 40px;
  min-width: 132px;
  padding-right: 24px;
}

.label-required {
  color: #ff0909;
}

.ord-form-input {
  flex: 1;
}

.ord-form-item.item-child {
  padding-left: 132px;
}

.ord-form-item.item-child .ord-form-label {
  min-width: 104px;
}

.ord-form-item-left,
.ord-form-item-right,
.ord-add-row-left,
.ord-add-row-right {
  display: flex;
  width: 50%;
  justify-content: flex-end;
}

.ord-form-item-left {
  padding-right: 12px;
}

.ord-form-item-right {
  padding-left: 12px;
}

.ord-form-label.two-row {
  line-height: 22px;
}

.label-other {
  display: block;
}

.ord-date-picker {
  width: 100%;
}

.ord-form-input .ant-input,
.ord-add-input .ant-input {
  border: 1px solid #dae2ee;
  border-radius: 3px;
  color: #222;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  height: 40px;
}

.ord-form-input .ant-select-selector,
.ord-form-input .ant-picker,
.ord-add-input .ant-select-selector,
.ord-add-input .ant-picker {
  border: 1px solid #dae2ee !important;
  border-radius: 3px !important;
}

.ord-form-item.col-3 {
  justify-content: space-between;
  padding-left: 132px;
  color: #222;
}

.ord-form-item-3-center {
  display: flex;
}

.ord-form-item-3-center .ant-input {
  width: 200px;
}
.ord-form-item-3-left {
  display: flex;
  align-items: center;
}
.ord-form-item-3-left .ord-form-label {
  display: block;
  min-width: 104px;
}

.ord-form-item-3-center .ord-form-label {
  min-width: 60px;
}

.ord-form-add {
  padding: 24px;
  background-color: #f4f7fa;
  border-radius: 3px;
}

.ord-add-row {
  display: flex;
  margin-bottom: 16px;
}

.ord-add-row.row-child {
  padding-left: 108px;
}

.ord-btn-add {
  display: flex;
  align-items: center;
  margin-left: 108px;
  margin-bottom: 16px;
  cursor: pointer;
  width: fit-content;
}

.ord-btn-add:hover {
  opacity: 0.8;
}

.ord-btn-add span {
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  color: #605bff;
  margin-left: 16px;
}

.ord-add-label {
  color: #222;
  text-align: right;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 40px;
  min-width: 104px;
  padding-right: 24px;
}

.ord-add-label.label-minus {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-left: -12px;
  min-width: 120px;
}

.ord-add-label.label-minus img {
  cursor: pointer;
}

.ord-add-input {
  width: 100%;
}

.ord-add-row-left {
  padding-right: 12px;
}

.ord-add-row-right {
  padding-left: 12px;
}

.ord-add-row.col-3 {
  justify-content: space-between;
  padding-left: 104px;
}

.ord-footer {
  display: flex;
  padding: 16px;
  border-radius: 3px;
  background-color: #f4f7fa;
  margin-top: 16px;
  padding-left: 132px;
  margin-bottom: 72px;
}

.ord-footer-title {
  color: #222;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  display: block;
  min-width: 104px;
  text-align: right;
  padding-right: 28px;
}

.ord-footer-content {
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex: 1;
}

.ord-footer-label {
  color: #666;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  margin-right: 24px;
}

.ord-footer-text {
  color: #222;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
}

.ord-btn {
  margin-top: 24px;
  margin-left: -33px;
  margin-right: -33px;
  border-top: 1px solid #dae2ee;
  padding-top: 24px;
  padding-bottom: 1px;
  display: flex;
  justify-content: flex-end;
}

.ord-btn-save {
  height: 40px;
  width: 76px;
  margin-right: 16px;
  letter-spacing: -1px;
}

.ord-btn-cancel {
  width: 118px;
  color: #666;
}

.ord002_1 .ord-btn-cancel {
  margin-right: 5px;
}

.ord002_1 .ant-modal-title {
  color: #222;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
}

.ord-form-input.icon-suffix .ant-input {
  height: auto;
}

.ord-form-input.icon-suffix .ant-input-affix-wrapper {
  width: 200px;
  border: 1px solid #dae2ee;
  border-radius: 3px;
}

.ord-form-input.icon-suffix .ant-input-suffix {
  color: #666;
  font-weight: 400;
  font-size: 14px;
}
.ord002_1 .ant-picker-input {
  padding: 2px 0;
}

.ord002_1 .ant-picker-input input {
  font-size: 14px;
  color: #222;
}

.ord-form-input .ant-picker.ant-picker-status-error,
.ord-add-input .ant-picker.ant-picker-status-error {
  border: 1px solid #ff4d4f !important;
}

.ord002_1 .ant-select-selection-item {
  font-size: 14px;
  font-weight: 400;
  color: #222;
}

.ord-form-input.input-number .ant-input-number-group-addon {
  background-color: #fff;
  color: #666;
  border: 1px solid #dae2ee;
  border-left: none;
  border-radius: 0 3px 3px 0;
}

.ord-form-input.input-number .ant-input-number {
  border: 1px solid #dae2ee;
  border-radius: 3px 0 0 3px;
  border-right: none;
  font-size: 14px;
  color: #222;
}

.ord-form-input.input-number .ant-input-number-focused {
  box-shadow: none;
  border: 1px solid #605bff;
  border-right: none;
}

.ord-form-input.input-number
  .ant-input-number-focused
  ~ .ant-input-number-group-addon {
  border: 1px solid #605bff;
  border-left: none;
}

.ord-form-input.input-number .ant-input-number-handler-wrap {
  display: none;
}
