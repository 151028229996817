.cm007-status-font {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: #666666;
}

.cm007-status-value-font {
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 22px;
}

.cm007-datepicker-font input {
  color: #605bff !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 14px !important;
  line-height: 22px !important;
}

.cm007-datepicker-font svg {
  color: #605bff !important;
}

.CM0017_3-info-title {
  /* 資料名 */
  height: 22px;

  /* PC/Text/Primary */

  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  /* identical to box height, or 157% */

  /* Text/Sub Text */
  color: #666666;
}

.CM0017_3-info-content {
  /* 工事代金支払明細書_協力会社1_2022年12月12日 */
  height: 22px;

  /* PC/Text/Primary */

  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  /* identical to box height, or 157% */

  /* Text/Main */
  color: #222222;
}
