.cm0061-project-container {
  height: 86vh;
  padding-top: 24px;
}

.cm0061-project-container .cm0061-project {
  height: 580px;
  overflow-y: scroll;
}

.cm0061-project-container .cm0061-project-min-scroll {
  height: 100%;
  overflow-y: scroll;
}

/* cm0061 */
.cm0061-wrapper {
  width: 100%;
}
.cm0061-wrapper .cm0061-content {
  width: 100%;
  background: #ffffff;
  box-shadow: 8px 8px 8px rgba(153, 153, 153, 0.02);
  border-radius: 6px;
  padding: 24px;
  overflow: hidden;
}

.cm0061-wrapper .cm0061-content .cm0061-title {
  display: flex;
  flex-direction: row;
  column-gap: 24px;
  width: 100%;
  height: 110px;
  background: #f8f8f8;
  border-radius: 6px;
  padding: 24px;
}

.cm0061-wrapper .cm0061-content .cm0061-title .column-title {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.cm0061-wrapper .cm0061-content .cm0061-title .column-title p {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: #666666;
}

.cm0061-wrapper .cm0061-content .cm0061-title .column-value {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.cm0061-wrapper .cm0061-content .cm0061-title .column-value p {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 22px;
  color: #222222;
}

.cm0061-content-tab {
  display: flex;
  flex-direction: row;
}

.cm0061-content-tab .tab .ant-tabs-nav {
  width: 120px;
  margin-top: 20px;
}

.cm0061-content-tab .ant-tabs-nav-list {
  width: 120px;
}

.cm0061-content-tab .ant-tabs-tab {
  width: 50%;
  margin: 0px;
  padding-left: 8px;
  padding-right: 8px;
}

.cm0061-content-tab .btn-export {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  width: 100%;
  margin-top: 25px;
}

.cm0061-content-tab .btn-export .btn-ct {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 9px 16px;
  gap: 8px;
  width: 90px;
  height: 40px;
  background: #605bff;
  border-radius: 6px;
  cursor: pointer;
}

.cm0061-content-tab .btn-export .btn-ct span {
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 22px;
  text-align: right;
  color: #ffffff;
}

.cm0061-element {
  width: 100%;
  /* background: #ddd; */
  border-width: 1px 0px 0px 1px;
  border-style: solid;
  border-color: #dae2ee;
  border-radius: 3px;
}

.cm0061-element .element-revenue .header {
  display: flex;
  flex-direction: row;
  align-items: center;
  align-content: center;
  height: 52px;
  background: #f0f0f0;
  /* box-shadow: inset 0px -1px 0px #dae2ee; */
  border-right: 1px solid #dae2ee;

  /*adjust text*/

  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #222222;

  padding-left: 50px;
}

.cm0061-element .element-revenue .content {
  display: flex;
  flex-direction: column;
}

.cm0061-element .element-revenue .content .header-title {
  display: flex;
  flex-direction: row;
}

.cm0061-element .element-revenue .content .content-items {
  display: flex;
  flex-direction: row;
}

.cm0061-element .element-revenue .content .header-title .item {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20%;
  height: 52px;
  border-width: 1px 1px 1px 0px;
  border-style: solid;
  border-color: #dae2ee;
  background: #fafafa;

  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: #222222;
}

.cm0061-element .element-revenue .content .content-items .item {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20%;
  height: 52px;
  border-width: 0px 1px 1px 0px;
  border-style: solid;
  border-color: #dae2ee;
  background: #fff;

  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: #222222;
}

.cm0061-element .element-budget .header {
  display: flex;
  flex-direction: row;
  align-items: center;
  align-content: center;
  height: 52px;
  background: #f0f0f0;
  border-width: 1px 1px 1px 0px;
  border-style: solid;
  border-color: #dae2ee;

  /*adjust text*/

  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #222222;

  padding-left: 50px;
  margin-top: 4px;
}

.cm0061-element .element-budget .content .header-title {
  display: flex;
  flex-direction: row;
}

.cm0061-element .element-budget .content .content-items {
  display: flex;
  flex-direction: row;
}

.cm0061-element .element-budget .content .header-title .item {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20%;
  height: 52px;
  border-width: 0px 1px 1px 0px;
  border-style: solid;
  border-color: #dae2ee;
  background: #fafafa;

  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: #222222;
}

.cm0061-element .element-budget .content .content-items .item {
  display: flex;
  align-items: center;
  width: 20%;
  height: 52px;
  border-width: 0px 1px 1px 0px;
  border-style: solid;
  border-color: #dae2ee;
  background: #fff;

  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: #222222;
}

.item-center {
  justify-content: center;
}

.item-left {
  justify-content: flex-start;
  padding-left: 50px;
}

/* CM002 */

.cm0061-element .element-revenue .cm002-content {
  display: flex;
  flex-direction: column;
}

.cm0061-element .element-revenue .cm002-content .header-title {
  width: 100%;
  display: flex;
  flex-direction: row;
}

.cm0061-element
  .element-revenue
  .cm002-content
  .header-title
  .item:first-child {
  width: 10% !important;
}

.cm0061-element .element-revenue .cm002-content .header-title .item {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  height: 52px;
  border-width: 1px 1px 1px 0px;
  border-style: solid;
  border-color: #dae2ee;
  background: #fafafa;

  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: #222222;
}

.cm0061-element .element-revenue .cm002-content .content-items {
  display: flex;
  flex-direction: row;
}

.cm0061-element
  .element-revenue
  .cm002-content
  .content-items
  .item:first-child {
  width: 10% !important;
}

.cm0061-element .element-revenue .cm002-content .content-items .item {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  height: 52px;
  border-width: 0px 1px 1px 0px;
  border-style: solid;
  border-color: #dae2ee;
  background: #fff;

  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: #222222;
}

.cm002-no {
  width: 10%;
}

.cm0061-project .company-item-container-collapse {
  display: flex;
  align-items: center;
  justify-content: center;
}

.cm0061-project .company-item-container-collapse .project-item-avt {
  margin-right: 0px !important;
  display: flex;
  align-items: center;
  justify-content: center;
}

.cm0061-project-container .company-pagination-container {
  box-shadow: unset;
}
