.error-modal .ant-modal-body {
    text-align: center;
}

.error-modal .ant-modal-confirm-title {
    font-weight: 700;
    font-size: 18px;
    color: #222222;
    margin: 13px 0 32px 0;
}

.error-modal .ant-modal-confirm-content {
    font-weight: 400;
    font-size: 14px;
    color: #666666;
}

.error-modal .ant-modal-confirm-btns {
    display: flex;
    justify-content: center;
    margin: 32px 0 7px 0;
}

.error-modal .ant-modal-confirm-btns button {
    background-color: #fff;
    height: 40px;
    border: 1px solid #dae2ee;
    color: #666666;
    font-weight: 400;
    font-size: 14px;
    padding: 9px 24px;
}

.error-modal .ant-modal-close-x {
    display: none;
}

.title-modal-error-ccus{
    display: flex;
    gap: 15px;
    align-items: center;
    justify-content: center;
}
.text-modal-error-ccus{
    font-size: 24px;
    font-weight: 700;
    line-height: 22px;
}