.detail-label {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: #666666;
}

.detail-info {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: #222222;
}

.p01-form .ant-form-item-label > label {
  height: 40px !important;
}
.detail-label-certificate {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;

  color: #666666;
}

.cert-view {
  min-height: 54px;
  width: 100%;
  display: flex;

  align-items: center;
  border-bottom: 1px solid #e9edf2;
}

.no-rotate .folder-collapse .ant-collapse-item-active svg {
  transform: rotate(0deg) !important;
}

.profile-attribute {
  display: flex;
  justify-content: space-between;
}
@media only screen and (max-width: 1800px) {
  .profile-attribute {
    display: inline;
  }
}
