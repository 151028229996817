.label-input-modal {
  font-size: 14px;
  align-content: center;
  font-weight: 400;
  float: right;
  line-height: 35px;
}
.sterisk {
  color: #ff0909;
}

.btn-upload {
  border: none;
  width: 0;
  height: 0;
  background-color: #e9edf2;
}
.btn-upload:hover {
  background-color: #e9edf2;
}

.wrapper-change-file {
  background-color: #e9edf2;
  width: 208px;
  height: 44px;
  padding: 5px 10px;
}
.label-input-modal {
  font-size: 14px;
  align-content: center;
  font-weight: 400;
  line-height: 35px;
}

.file-name-pdf {
  padding-left: 20px;
}
.btn-upload-custom {
  font-size: 14px;
  font-weight: 400;
  color: #605bff;
  width: 330px;
  height: 40px;
  margin: 0 auto;
  border: #605bff dotted 1px;
  line-height: 35px;
  margin: 0 auto;
}
.title-modal {
  font-weight: 700;
  font-size: 18px;
}

.pm005-upload-file .ant-upload-drag {
  display: none;
}

.pm005-upload-file .ant-card-body {
  padding: 0;
}
.pm005-upload-file .ant-form-item {
  margin-bottom: 10px;
}

.modal-ml {
  margin-left: 300px !important;
}

.select-item-text .ant-select-selection-item div {
  font-size: 14px !important;
  font-weight: 400 !important;
}
