.construction-name {
  font-weight: 600;
  font-size: 16px;
  color: #222222;
  margin-top: 24px;
  margin-left: 24px;
  margin-bottom: 0px;
}

.date-label {
  font-weight: 700;
  font-size: 14px;
  padding-right: 24px;

  color: #222222;
}
.header-no-padding .ant-table-cell {
  padding: 0px !important;
  padding: 0px !important;
}

.header-today {
  border-top: 3px solid #605bff;
  color: #605bff !important;
}

.header-not-today {
  border-top: 3px solid #f0f0f0;
}

.table-cell-inside {
  width: 100%;

  font-weight: 400;
  font-size: 14px;

  color: #222222;
}

.level-badge {
  min-width: 60px;
  min-height: 22px;
  border-radius: 6px;
  font-weight: 700;
  font-size: 12px;
  text-align: center;
}

.money-label {
  font-weight: 400;
  font-size: 14px;
  color: #222222;
}

.money-label-number {
  font-weight: 700;
  font-size: 14px;
  color: #222222;
}

.ant-table-cell-fix-right-last div {
  display: flex !important;
  padding-right: 19px !important;
}

.row-company {
  background: #dae5f5 !important;
}
.row-no-data {
  background: #ffffff !important;
}
.row-member {
  background: #f2f6fc !important;
}
.row-member-header {
  background: #f5f5f5 !important;
  pointer-events: none !important;
}

.no-click {
  pointer-events: none !important;
}

.attendance-table
  .ant-table.ant-table-bordered
  > .ant-table-container
  > .ant-table-content
  > table
  > thead
  > tr
  > th,
.attendance-table
  .ant-table.ant-table-bordered
  > .ant-table-container
  > .ant-table-header
  > table
  > thead
  > tr
  > th,
.attendance-table
  .ant-table.ant-table-bordered
  > .ant-table-container
  > .ant-table-body
  > table
  > thead
  > tr
  > th,
.attendance-table
  .ant-table.ant-table-bordered
  > .ant-table-container
  > .ant-table-summary
  > table
  > thead
  > tr
  > th,
.attendance-table
  .ant-table.ant-table-bordered
  > .ant-table-container
  > .ant-table-content
  > table
  > tbody
  > tr
  > td,
.attendance-table
  .ant-table.ant-table-bordered
  > .ant-table-container
  > .ant-table-header
  > table
  > tbody
  > tr
  > td,
.attendance-table
  .ant-table.ant-table-bordered
  > .ant-table-container
  > .ant-table-body
  > table
  > tbody
  > tr
  > td,
.attendance-table
  .ant-table.ant-table-bordered
  > .ant-table-container
  > .ant-table-summary
  > table
  > tbody
  > tr
  > td,
.attendance-table
  .ant-table.ant-table-bordered
  > .ant-table-container
  > .ant-table-content
  > table
  > tfoot
  > tr
  > th,
.attendance-table
  .ant-table.ant-table-bordered
  > .ant-table-container
  > .ant-table-header
  > table
  > tfoot
  > tr
  > th,
.attendance-table
  .ant-table.ant-table-bordered
  > .ant-table-container
  > .ant-table-body
  > table
  > tfoot
  > tr
  > th,
.attendance-table
  .ant-table.ant-table-bordered
  > .ant-table-container
  > .ant-table-summary
  > table
  > tfoot
  > tr
  > th,
.attendance-table
  .ant-table.ant-table-bordered
  > .ant-table-container
  > .ant-table-content
  > table
  > tfoot
  > tr
  > td,
.attendance-table
  .ant-table.ant-table-bordered
  > .ant-table-container
  > .ant-table-header
  > table
  > tfoot
  > tr
  > td,
.attendance-table
  .ant-table.ant-table-bordered
  > .ant-table-container
  > .ant-table-body
  > table
  > tfoot
  > tr
  > td,
.attendance-table
  .ant-table.ant-table-bordered
  > .ant-table-container
  > .ant-table-summary
  > table
  > tfoot
  > tr
  > td {
  border-right: 1px solid transparent !important;
  border-left: 1px solid transparent !important;
  border-bottom: 1px solid #dae2ee;
}

.attendance-table
  .ant-table.ant-table-bordered
  > .ant-table-container
  > .ant-table-content
  > table
  > tbody
  > tr
  > td.border-left-table {
  border-right: 1px solid #dae2ee !important;
}
.attendance-table .ant-table-thead {
  display: none !important;
}

.attendance-table-row-white .ant-table-tbody > tr {
  background: #ffffff !important;
}
.attendance-table-row-white .ant-table-tbody > tr:nth-child(even) {
  background: #ffffff !important;
}

.row-member-header {
  background: #f0f0f0 !important;
}

.border-top-table
  .ant-table.ant-table-bordered
  > .ant-table-container
  > .ant-table-content
  > table {
  border-top: none;
}

.calendar-header th.ant-table-cell {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}
.construction-name-font {
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  color: #666666;
}

.table-is-empty .ant-table-cell {
  background-color: #fff !important ;
}

.responsive-width-34 {
  width: 34%;
}
@media (max-width: 1650px) {
  .responsive-width-34 {
    width: 28%;
  }
}

@media (max-width: 1450px) {
  .responsive-width-34 {
    width: 25%;
  }
}
