.sm06_modal .sm06-header{
    color:  #222222;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    margin-left: 2px;
}

.sm06_modal .body{
    color:  #222222;
    font-weight: 400;
    font-size: 13px;
    line-height: 22px;
    margin-top: 8px;
    margin-left: 40px;
}

.sm06_modal .ant-modal-footer{
    border-top: none;
}