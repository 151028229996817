.header-modal-title-cm0012 {
  font-weight: 700;
  font-size: 18px;
  line-height: 26px;
  color: #222222;
}

.cm0012-content .content-description {
  display: flex;
  margin-bottom: 40px;
  padding: 0;
}

.cm0012-content .content-description .content-btn {
  width: 20%;
  display: flex;
  justify-content: end;
}
.cm0012-content .content-description .content-description-text {
  width: 80%;
  display: flex;
  flex-direction: column;
}

.cm0012-content .content-description .content-description-text-update {
  width: 100%;
  display: flex;
  flex-direction: unset;
}

.cm0012-content
  .content-description
  .content-description-text
  .description-col {
  display: flex;
  margin-right: 64px;
  align-items: center;
  margin-bottom: 16px;
}

.cm0012-content
  .content-description
  .content-description-text
  .description-col:last-child {
  margin-bottom: 0px;
}

.cm0012-content
  .content-description
  .content-description-text-update
  .description-col {
  margin-bottom: 0px;
}

.cm0012-content .content-description .content-description-button {
  width: 20%;
  display: flex;
  flex-direction: column;
  justify-content: end;
  align-items: end;
}

.cm0012-content .content-description .content-description-button Button {
  width: 92px;
  height: 40px;
  font-weight: 700;
  font-size: 14px;
  line-height: 22px;
  color: #605bff;
  background-color: #dae2ee;
  border: 1px solid #605bff;
}

.cm0012-content
  .content-description
  .content-description-text
  .description-col
  label {
  margin-right: 24px;
  color: #666666;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  min-width: 70px;
  display: flex;
  justify-content: end;
}

.cm0012-content
  .content-description
  .content-description-text-update
  .description-col
  label {
  min-width: unset;
}

.cm0012-content
  .content-description
  .content-description-text
  .description-col
  p {
  margin-right: 24px;
  color: #222222;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  margin: 0;
}

.cm0012-content .content-table {
  width: 979px;
}

.cm0012-content .content-table .form-cm0012 {
  min-height: 80px;
  max-height: 260px;
  overflow-y: scroll;
  scroll-behavior: smooth;
}

.cm0012-content .content-table .table-row {
  display: flex;
}

.cm0012-content .content-table .table-row:first-child {
  border-top: none;
}

.cm0012-content .content-table .table-row {
  width: 100%;
  height: 52px;
  display: flex;
  align-items: center;
  border: 1px solid #dae2ee;
  border-right: none;
  border-top: none;
}

.cm0012-content .content-table .table-row-violate-rule {
  height: 70px;
}

.cm0012-content .content-table .table-header {
  background-color: #f0f0f0 !important;
  border-right: 1px solid #dae2ee;
  padding-right: 6px;
  font-weight: 700;
}

.cm0012-content .content-table .table-row .cell {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #222222;
}

.cm0012-content .content-table .table-header .cell {
  color: #666666;
  border-top: 1px solid #dae2ee;
  border-right: 1px solid #dae2ee;
}

.cm0012-content .content-table .table-header .cell:last-child {
  color: #666666;
}

.cm0012-content .content-table .table-row .cell {
  border-right: 1px solid #dae2ee;
}

.cm0012-content .content-table .table-row .cell:last-child {
  border-right: none;
}

.cm0012-content .content-table .table-row .cell .ant-form-item {
  margin: 0;
}

.cm0012-content .content-table .table-row .cell-order {
  width: 80px;
}

.cm0012-content .content-table .table-row .cell-item {
  width: 299.67px;
}

.cm0012-content .content-table .table-row .cell-taxCategoryComsumption {
  width: 299.67px;
}
.cm0012-content .content-table .table-row .cell-offsetAmount {
  width: 299.67px;
}

.cm0012-content .content-table .add-item {
  width: 100%;
  height: 56px;
  display: flex;
  align-items: center;
  background-color: #ffffff;
  color: #605bff;
  border: 1px solid #dae2ee;
  border-top: none;
}

.cm0012-content .content-table .table-row-blue {
  background-color: #605bff;
  font-weight: 700;
  font-size: 14px;
  line-height: 22px;
  margin-bottom: 16px;
}

.cm0012-content .content-table .table-row-blue .cell {
  border: none;
  color: #ffffff;
}

.cm0012-content .content-table .table-row .cell .ant-input {
  width: 267.67px;
  height: 38px;
  color: #222222;
  font-weight: 400;
  font-size: 14;
  line-height: 22;
}

.cm0012-content
  .content-table
  .table-row
  .cell-offsetAmount
  .ant-input-number-wrapper {
  width: 237.67px;
}

.cm0012-content
  .content-table
  .table-row
  .cell-offsetAmount
  .ant-input-number-wrapper
  .ant-input-number {
  height: 38px;
  display: flex;
  align-items: center;
}

.cm0012-content .content-table .table-row .cell .ant-select-selector {
  width: 267.67px;
  height: 38px;
  color: #222222;
  font-weight: 400;
  font-size: 14;
  line-height: 22;
  display: flex;
  align-items: center;
}

.cm0012-content
  .content-table
  .form-cm0012
  .ant-select-selection-search
  .ant-select-selection-search-input {
  display: none;
}

.cm0012-content .content-table .form-cm0012 .empty-data {
  width: 100%;
  height: 260px;
  padding: 16px 0px;
  display: flex;
  justify-content: center;
}

.cm0012-content .content-table .form-cm0012 .empty-data img {
  height: 100%;
  object-fit: cover;
}

.cm0012-content ::-webkit-scrollbar-track {
  background-color: #f0f0f0;
  border-radius: unset;
  border-right: 1px solid #dae2ee;
}

.cm0012-content .ant-input-number-input-wrap input {
  text-align: right;
}

.modal-cm012 .ant-btn:focus {
  display: none !important;
}
