.ic4-modal-btn {
  display: flex;
  justify-content: flex-end;
  height: 40px;
}
.ic4-modal-form-item {
  display: flex;
  width: 100%;
  margin-bottom: 32px;
}

.ic4-modal-form-item .ant-form-item {
  width: 100%;
  margin: 0px !important;
  padding: 0px !important;
}

.ic4-modal-form-item-label {
  width: 150px;

  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
  color: rgba(102, 102, 102, 1);
  display: flex;
  align-items: center;
  margin-right: 24px;
}
.ic4-modal-form-item-input {
  flex: 1;
}
.text-content {
  color: var(--text-main, #222);

  /* PC/Text/Primary */

  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px; /* 157.143% */
}
