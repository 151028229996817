.container-tab-ccus05{
    width: 100%;
    background: #fff;
    padding: 0px 24.5px 0px 0px;
    margin-top: 17px;
}
.content-table-ccus05 {
    padding: 24px 0px 16px 24px;
    background-color: #ffffff;
}
.btn-approve-ccus05{
    width: 120px;
    height: 40px;
    border-radius: 8px !important;
    letter-spacing: -1px;
}
.btn-container {
    width: 100%;
    display: flex;
    padding-bottom: 24px;
    justify-content: flex-end;
}
.filter-ccus05-container{
    height: 100%;
    border-right: 1px solid #dae2ee;
    position: relative;
    background: #fff !important;
    padding-top: 24px;
}

.modal-approve-ccus .ant-modal-content {
    border-radius: 20px;
  }
  .modal-approve-ccus .ant-modal-confirm-body {
    text-align: center;
    margin-bottom: 30px;
    margin-top: 20px;
  }
  
  .modal-approve-ccus .ant-modal-confirm-btns {
    margin-bottom: 40px;
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: center;
    gap:104px
  }
  
  .modal-approve-ccus .ant-btn {
    width: 92px !important;
    height: 40px !important;
    background: #fff;
    color: #9897ad;
    box-shadow: 0px 0px 20px rgba(22, 70, 157, 0.1);
    border-radius: 5px;
    border: 1px solid #9897ad;
    outline: none;
  }
  
  .modal-approve-ccus .ant-btn-dangerous {
    width: 92px !important;
    height: 40px !important;
    background: #eb4d4d;
    color: #fff;
    box-shadow: 0px 0px 20px rgba(22, 70, 157, 0.1);
    border-radius: 5px;
    border: none;
    outline: none;
  }
 .modal-approve-ccus .ant-btn-default{
    min-width: 58px !important;
    height: 40px !important;
    background: #D7D7DC !important;
    color: #222222;
    box-shadow: 0px 0px 20px rgba(22, 70, 157, 0.1);
    border-radius: 5px;
    border: none;
    outline: none;
    padding:4px 0;
 }
  .modal-approve-ccus-purple-oke .ant-btn-dangerous {
    min-width: 58px !important;
    height: 40px !important;
    background: #605bff !important;
    color: #fff;
    box-shadow: 0px 0px 20px rgba(22, 70, 157, 0.1);
    border-radius: 5px;
    border: none;
    outline: none;
  }
  
  .modal-approve-ccus .ant-modal-confirm-title {
    font-weight: 600;
    font-size: 24px;
    color: #343434;
    margin-bottom: 24px !important;
  }
  
  .modal-approve-ccus .ant-modal-confirm-content {
    font-weight: 600;
    font-size: 14px;
    color: #666666;
    margin-bottom: 48px !important;
  }
  .right-content-ccus05{
    height: 100%;
    display: flex;
    flex-direction: column;
  }