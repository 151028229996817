.header-modal-title-cm017_2 {
  font-weight: 700;
  font-size: 18px;
  line-height: 26px;
  color: #222222;
}

.cm017_2-content .content-description {
  display: flex;
  margin-bottom: 24px;
  padding: 0;
  justify-content: end;
}

.cm017_2-content .content-description-update {
  margin-bottom: unset;
}

.cm017_2-content .content-description .content-btn {
  width: 20%;
  display: flex;
  justify-content: end;
}

.cm017_2-content .content-description .content-description-button {
  display: flex;
  flex-direction: column;
  justify-content: end;
}

.cm017_2-content .content-description .content-description-button Button {
  width: 92px;
  height: 40px;
  font-weight: 700;
  font-size: 14px;
  line-height: 22px;
  color: #605bff;
  background-color: #dae2ee;
  border: 1px solid #605bff;
}

.cm017_2-content .content-table {
  width: 979px;
}

.cm017_2-content .content-table .form-cm017_2 {
  max-height: 480px;
  overflow-y: scroll;
  scroll-behavior: smooth;
}

.cm017_2-content .content-table .table-row {
  display: flex;
}

.cm017_2-content .content-table .table-row:first-child {
  border-top: none;
}

.cm017_2-content .content-table .table-row {
  width: 100%;
  height: 52px;
  display: flex;
  align-items: center;
  border: 1px solid #dae2ee;
  border-top: none;
  border-bottom: none;
  border-right: none;
}

.cm017_2-content .content-table .table-row-violate-rule {
  height: 70px;
}

.cm017_2-content .content-table .table-header {
  background-color: #f0f0f0;
  border-right: 1px solid #dae2ee;
  padding-right: 12px;
  font-weight: 700;
}

.cm017_2-content .content-table .table-row .cell {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #222222;
  border-bottom: 1px solid #dae2ee;
  border-top: none;
}

.cm017_2-content .content-table .table-row .cell {
  border-right: 1px solid #dae2ee;
  border-top: none;
}

.cm017_2-content .content-table .table-row .cell:last-child {
  border-right: none;
}

.cm017_2-content .content-table .table-header .cell {
  color: #666666;
  border-top: 1px solid #dae2ee;
  border-bottom: 1px solid #dae2ee;
}

.cm017_2-content .content-table .table-row .cell .ant-form-item {
  margin: 0;
}

.cm017_2-content .content-table .table-row .cell-order {
  width: 80px;
}

.cm017_2-content .content-table .table-row .cell-item {
  width: 226.17px;
}

.cm017_2-content .content-table .table-row .cell-taxCategoryComsumption {
  width: 226.17px;
}
.cm017_2-content .content-table .table-row .cell-offsetAmount {
  width: 226.17px;
}

.cm017_2-content .content-table .add-item {
  width: 100%;
  height: 56px;
  display: flex;
  align-items: center;
  background-color: #ffffff;
  color: #605bff;
  border: 1px solid #dae2ee;
  border-top: none;
}

.cm017_2-content .content-table .table-row-blue {
  background-color: #605bff;
  font-weight: 700;
  font-size: 14px;
  line-height: 22px;
  margin-bottom: 16px;
}

.cm017_2-content .content-table .table-row-blue .cell {
  border: none;
  color: #ffffff;
}

.cm017_2-content .content-table .table-row .cell .ant-input {
  width: 167.25px;
  height: 38px;
  color: #222222;
  font-weight: 400;
  font-size: 14;
  line-height: 22;
}

.cm017_2-content
  .content-table
  .table-row
  .cell-offsetAmount
  .ant-input-number-wrapper {
  width: 167.25px;
}

.cm017_2-content
  .content-table
  .table-row
  .cell-offsetAmount
  .ant-input-number-wrapper
  .ant-input-number {
  height: 38px;
  display: flex;
  align-items: center;
}

.cm017_2-content .content-table .table-row .cell .ant-select-selector {
  width: 167.25px;
  height: 38px;
  color: #222222;
  font-weight: 400;
  font-size: 14;
  line-height: 22;
  display: flex;
  align-items: center;
}

.cm017_2-content
  .content-table
  .form-cm017_2
  .ant-select-selection-search
  .ant-select-selection-search-input {
  display: none;
}

.cm017_2-content .content-table .form-cm017_2 .empty-data {
  width: 100%;
  height: 260px;
  padding: 16px 0px;
  display: flex;
  justify-content: center;
}

.cm017_2-content .content-table .form-cm017_2 .empty-data img {
  height: 100%;
  object-fit: cover;
}

.cm017_2-content ::-webkit-scrollbar-track {
  background-color: #f0f0f0;
  border-radius: unset;
  border-right: 1px solid #dae2ee;
}

.cm017_2-content .ant-input-number-input-wrap input {
  text-align: right;
}
