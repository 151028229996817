.header-modal-title-cm0014 {
  font-weight: 700;
  font-size: 18px;
  line-height: 26px;
  color: #222222;
}

.cm0014-content .content-description {
  display: flex;
  padding-left: 8px;
}

.cm0014-content .content-description .description-col:first-child {
  margin-right: 200px;
}

.cm0014-content .description-row {
  display: flex;
}

.cm0014-content .description-row label {
  min-width: 84px;
  display: flex;
  justify-content: end;
  color: #666666;
}

.cm0014-content .description-row p {
  margin-left: 24px;
  color: #222222;
}

.cm0014-content .content-table .table-top {
  margin-top: 4px;
}

.cm0014-content .content-table .table-top .ant-table-title {
  height: 54px;
  background-color: #f0f0f0;
  border: 1px solid #dae2ee !important;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #222222;
  padding-left: 50px;
}

.cm0014-content
  .content-table
  .table-top
  .ant-table-container
  .ant-table-content
  .ant-table-thead
  .ant-table-cell {
  background-color: #fafafa !important;
  height: 52px !important;
  border-right: 1px solid #dae2ee;
  border-bottom: 1px solid #dae2ee;
}

.cm0014-content
  .content-table
  .table-top
  .ant-table-container
  .ant-table-content
  .ant-table-thead
  .ant-table-cell:first-child {
  border-left: 1px solid #dae2ee;
}

.cm0014-content
  .content-table
  .table-top
  .ant-table-container
  .ant-table-content
  .ant-table-tbody
  .ant-table-row
  .ant-table-cell {
  border-right: 1px solid #dae2ee;
  border-bottom: 1px solid #dae2ee;
  background-color: #ffffff;
  color: #222222;
}

.cm0014-content
  .content-table
  .table-top
  .ant-table-container
  .ant-table-content
  .ant-table-tbody
  .ant-table-row
  .ant-table-cell
  span {
  color: #222222;
}

.cm0014-content
  .content-table
  .table-top
  .ant-table-container
  .ant-table-content
  .ant-table-tbody
  .ant-table-row
  .ant-table-cell:first-child {
  border-left: 1px solid #dae2ee;
}

.cm0014-content .content-table .table-bot .ant-table-title {
  background-color: #dae2ee;
  border: 1px solid #dae2ee !important;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #222222;
  padding-left: 50px;
}

.cm0014-content
  .content-table
  .table-bot
  .ant-table-container
  .ant-table-content
  .ant-table-thead
  .ant-table-cell {
  background-color: #f5f5f5 !important;
  border-right: 1px solid #dae2ee;
  border-right: 1px solid #dae2ee;
  border-bottom: 1px solid #dae2ee;
}

.cm0014-content
  .content-table
  .table-bot
  .ant-table-container
  .ant-table-content
  .ant-table-thead
  .ant-table-cell:first-child {
  border-left: 1px solid #dae2ee;
}

.cm0014-content
  .content-table
  .table-bot
  .ant-table-container
  .ant-table-content
  .ant-table-tbody
  .ant-table-row
  .ant-table-cell {
  border-right: 1px solid #dae2ee;
  border-bottom: 1px solid #dae2ee;
  background-color: #ffffff;
}

.cm0014-content
  .content-table
  .table-bot
  .ant-table-container
  .ant-table-content
  .ant-table-tbody
  .ant-table-row
  .ant-table-cell
  span {
  color: #222222;
}

.cm0014-content
  .content-table
  .table-bot
  .ant-table-container
  .ant-table-content
  .ant-table-tbody
  .ant-table-row
  .ant-table-cell:first-child {
  border-left: 1px solid #dae2ee;
}

.cm0014-content .content-table .table-bot .ant-table-footer {
  background-color: #605bff;
  height: 52px;
  color: #ffffff;
}

.cm0014-content .content-table .table-bot {
  margin-bottom: 80px;
}

.cm0014-content
  .content-table
  .table-bot
  .ant-table-summary
  .children-table-summary
  .ant-table-cell {
  border: none;
}

.cm0014-content .ant-divider {
  border-top: 1px solid #dae2ee;
}

.cm0014-footer {
  display: flex;
  justify-content: end;
}

.cm0014-footer .submit-btn {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 9px 32px;
  gap: 8px;
  width: 120px;
  height: 40px;
  background: #605bff;
  border-radius: 3px;
  color: #ffffff;

  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 22px;
  cursor: pointer;
}
